import React from 'react'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledDiv = styled(Box)`
  ${({ theme }) => `
    background-color: #fff;
  `}
`

export default function PanelHead(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const { title, subtitle, children } = props

	return (
		<StyledDiv sx={{ p: 2 }}>
			<Grid container>
				<Grid xs={12} sm={5}>
					<Typography noWrap={true} variant="h4">
						{title}
					</Typography>
					{
						subtitle && (
							<Typography component={'div'} variant="caption">
								{subtitle}
							</Typography>
						)
					}
				</Grid>
				<Grid xs={12} sm={7} sx={{ textAlign: 'right', pt: isMobile ? 1 : 0 }}>
					{children}
				</Grid>
			</Grid>
		</StyledDiv>
	)
}
