import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {useDispatch} from 'react-redux'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {addApplication} from '../../../lib/request'
import {createApplication} from '../../../store/reducers/application'


const StyledDialog = styled(Dialog)`
  ${({theme}) => `
    
  `}
`

export default function AddNewApplicationDialog(props) {

	const {onClose, brand} = props

	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [label, setLabel] = useState('')
	const [labelErr, setLabelErr] = useState('')

	useEffect(() => {
		setLabelErr('')

	}, [label])

	function submit (e) {
		e.preventDefault()
		e.stopPropagation()

		if (_.isEmpty(_.trim(label))) {
			return setLabelErr('Label is required')
		}

		dispatch(setLoading(true))
		addApplication({label, brandId: brand.id})
			.then((res) => {
				dispatch(createApplication(res.data))
				dispatch(setSnackbarMsg('New application created for this brand'))
				onClose()
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				} else {
					console.error(err.toString())
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDialog open fullWidth fullScreen={isMobile} onClose={onClose}>
			<DialogTitle>Add new application</DialogTitle>
			<DialogContent>
				<Box component={'form'} onSubmit={submit}>
					<TextField
						fullWidth
						required
						margin={'normal'}
						label={'Label'}
						value={label}
						onChange={(e) => setLabel(e.target.value)}
						InputLabelProps={{shrink: true}}
						error={!_.isEmpty(_.trim(labelErr))}
						helperText={!_.isEmpty(_.trim(labelErr)) ? labelErr : ''}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant={isMobile? 'outlined' : 'text'}
					color={'inherit'}
					fullWidth={isMobile}
					onClick={onClose}>
					Cancel
				</Button>
				<Button fullWidth={isMobile} variant="outlined" onClick={submit}>Save</Button>
			</DialogActions>
		</StyledDialog>
	)
}
