import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {TablePagination} from '@mui/material'

import {setCoupons} from '../../store/reducers/coupon'
import {getCoupons} from '../../lib/request'

import PanelHead from '../../components/panel-head'
import SearchBox from './search'
import {setLoading} from '../../store/reducers/misc'

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }

  .swal2-popup {
    font-family: "Roboto", sans-serif !important;
  }
`

export default function Coupon() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const coupons = useSelector((state) => state.coupon.coupons)
	const {brandInFocus} = useSelector(state => state.profile)

	const [page, setPage] = useState(0)
	const [total, setTotal] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [search, setSearch] = useState({name: '', code: '', showAll: false})
	const [init, setInit] = useState(null)

	useEffect(() => {
		fetchCoupons()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (init === true) {
			fetchCoupons()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowsPerPage, page])

	useEffect(() => {
		handleChangePage(null, 0)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandInFocus])

	function fetchCoupons() {
		const q = {
			name: search.name,
			showAll: search.showAll,
			brandId: brandInFocus,
			limit: _.clone(rowsPerPage),
			offset: rowsPerPage * page
		}

		dispatch(setLoading(true))
		getCoupons(q)
			.then((res) => {
				dispatch(setCoupons(res.data.rows))
				setTotal(res.data.count)
			})
			.catch(err => console.log(err.response.data.message))
			.finally(() => {
				dispatch(setLoading(false))
				setInit(true)
			})
	}

	function handleChangePage(e, newPage) {
		setPage(newPage)
	}

	function handleChangeRowsPerPage(e) {
		setRowsPerPage(parseInt(e.target.value, 10))
		setPage(0)
	}

	function nextPage(id) {
		navigate(`/coupon/${id}`)
	}

	function beginSearch(e) {
		e.preventDefault()
		e.stopPropagation()
		if (_.isEmpty(_.trim(search.name)) && !_.isEmpty(_.trim(search.code))) {
			navigate(`/coupon/trace/${search.code}`)
		} else {
			fetchCoupons()
		}

	}

	return (
		<StyleDiv>
			<PanelHead title={'Coupons'}/>
			<SearchBox beginSearch={beginSearch} search={search} setSearch={setSearch}/>
			<Box>
				<TableContainer>
					<Table>
						<TableHead className="tblHead">
							<TableRow>
								<TableCell align="left">#</TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="left">Subscription plan</TableCell>
								<TableCell align="center">Value</TableCell>
								<TableCell align="center">Max</TableCell>
								<TableCell align="right">Total coupon</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								coupons.map((coupon, index) => {
									return (
										<TableRow hover onClick={() => nextPage(coupon.id)} sx={{cursor: 'pointer'}} key={index}>
											<TableCell>
												{index + 1 + (rowsPerPage * page)}
											</TableCell>
											<TableCell>
												{coupon.name}
											</TableCell>
											<TableCell>
												{coupon.subscriptionPlanName ? coupon.subscriptionPlanName : '-'}
											</TableCell>
											<TableCell align={'center'}>
												{coupon.isValue ? `RM${coupon.value.toFixed(2)}` : `${coupon.value}%`}
											</TableCell>
											<TableCell align={'center'}>
												{coupon.max}
											</TableCell>
											<TableCell align="right">
												{coupon.type === "subscription"
													? coupon.totalClaim
													: coupon.totalClaimTicket} / {coupon.totalCode}
											</TableCell>
										</TableRow>
									)
								})
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<Box sx={{p: 2}}>
				<TablePagination
					rowsPerPageOptions={[5, 10, 50, 100]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}/>

			</Box>
		</StyleDiv>
	)

}
