import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import Cookies from 'js-cookie'
import copy from 'copy-to-clipboard';

import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Unstable_Grid2'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import PanelHead from '../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import {createCoupon} from '../../store/reducers/coupon'
import {addCoupon, getSubscriptions, getEticketEvents} from '../../lib/request'
import {updateSubscriptionDropdown} from '../../store/reducers/subscription'
import {updateEventDropdown} from '../../store/reducers/event'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Create() {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const subscriptionDropdown = useSelector((state) => state.subscription.dropdown)
	const eventsPayload = useSelector((state) => state.event?.dropdown || [])
	const profile = useSelector(state => state.profile)
	const me = useSelector((state) => state.profile.me)

	const [brands, setBrands] = useState([])
	const [form, setForm] = useState({
		id: null,
		name: '',
		descriptionPublic: '',
		description: '',
		isGeneric: false,
		max: 10,
		isValue: false,
		value: '',
		duration: '',
		isActive: true,
		from: null,
		to: null,
		prefix: '',
		postfix: '',
		length: 4,
		self: '',
		via: '',
		planId: '',
		brandsId: '',
		nameErr: '',
		valueErr: '',
		maxErr: '',
		lengthErr: '',
		selfErr: '',
		brandsIdErr: '',
		type: 'subscription',
		typeErr: '',
		eticketEventId: '',
	})
	const [payload, setGenUrl] = useState('')

	useEffect(() => {
		if (_.isEmpty(subscriptionDropdown)) {
			getSubscriptions({unlimited: true, withSubscriber :false})
				.then((res) => {
					dispatch(updateSubscriptionDropdown(res.data.rows))
				})
				.catch(err => {
					console.log(err.response.data.message)
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscriptionDropdown])

	useEffect(() => {
		if (_.isEmpty(eventsPayload) && profile.brandInFocus) {
			getEticketEvents({brandId: profile.brandInFocus})
				.then((res) => {
					console.log(res.data)
					dispatch(updateEventDropdown(res.data))
				})
				.catch(err => console.log(err.response.data.message))
		}
	}, [eventsPayload])

	useEffect(() => {
		setBrands(me['Brands'])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])
	
	useEffect(() => {
		const payload = `
{
	"name": "${form.name}",
	"descriptionPublic": "${form.descriptionPublic}",
	"description": "${form.description}",
	"isGeneric": ${form.isGeneric},
	"max": ${form.max},
	"isValue": ${form.isValue},
	"value": ${form.value},
	"duration": "${form.duration}",
	"isActive": ${form.isActive},
	"from": "${form.from}",
	"to": "${form.to}",
	"via": "${form.via}",
	"prefix": "${form.prefix}",
	"postfix": "${form.postfix}",
	"length": ${form.length},
	"self": "${form.description}",
	"brandId": ${form.self},
	"subscriptionPlanId": ${form.planId},
	"type": "${form.type}",
	"eticketEventId": ${form.eticketEventId}
	"isHiddenDashboard": true
}`
		setGenUrl(_.trim(payload))
		
	}, [form])

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		if (name === 'max') {
			if (value >= 1000) {
				formClone['length'] = 8
			} else {
				formClone['length'] = 4
			}
		}

		formClone.nameErr = ''
		formClone.valueErr = ''
		formClone.maxErr = ''
		formClone.lengthErr = ''
		formClone.selfErr = ''
		formClone.brandsIdErr = ''
		formClone.typeErr = ''
		setForm(formClone)
	}

	function onBlur(e) {
		const formClone = _.cloneDeep(form)
		const {name, value} = e.target

		if (name === 'max') {
			if (value < 0) {
				formClone.maxErr = 'Minimum value is 1'
			}
		}

		if (name === 'length') {
			if (value < 4) {
				formClone.lengthErr = 'Length cannot be less than 4'
			}
			if (form.max >= 1000 && form.isGeneric && value < 9) {
				formClone.lengthErr = 'Length cannot be less than 8'
			}
		}

		if (name === 'self') {
			formClone.isGeneric = true
			formClone[name] = value
		}

		setForm(formClone)
	}

	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v
		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()
		const formClone = _.cloneDeep(form)

		if (_.isEmpty(_.trim(form.planId))) {
			formClone.subscriptionPlanId = null
		} else {
			formClone.subscriptionPlanId = form.planId
		}

		let error = false

		if (_.isEmpty(_.trim(form.name))) {
			formClone.nameErr = 'Name is required'
			error = true
		}
		if (_.isEmpty(_.trim(form.value))) {
			formClone.valueErr = 'Value is required'
			error = true
		}
		if (_.isEmpty(_.trim(form.brandsId))) {
			formClone.brandsIdErr = 'Brand is required'
			error = true
		}
		if (_.isEmpty(_.trim(form.type))) {
			formClone.typeErr = 'Coupon type is required'
			error = true
		}
		if (_.isEmpty(_.trim(form.max))) {
			formClone.maxErr = 'Max is required'
			error = true
		}
		if (!_.isEmpty(_.trim(form.max)) && form.max < 0) {
			formClone.maxErr = 'Minimum value is 1'
			error = true
		}
		if (!_.isEmpty(_.trim(form.self)) && !form.isGeneric) {
			formClone.selfErr = 'Self must be empty if generic is false'
			error = true
		}
		if (_.isEmpty(_.trim(form.length)) && _.isEmpty(form.self)) {
			formClone.lengthErr = 'Length is required'
			error = true
		}
		if (!_.isEmpty(_.trim(form.length)) && _.isEmpty(form.self)) {
			if (form.length < 4) {
				formClone.lengthErr = 'Length cannot be less than 4'
				error = true
			} else if (form.max >= 1000 && !form.isGeneric && form.length < 8) {
				formClone.lengthErr = 'Length cannot be less than 8'
				error = true
			}
		}
		if (!form.isValue && (form.value < 0 || form.value > 100)) {
			formClone.valueErr = 'Percentage discount is between 0% to 100%'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		formClone.brandId = form.brandsId
		setForm(formClone)
		dispatch(setLoading(true))

		addCoupon(formClone)
			.then((res) => {
				dispatch(createCoupon(res.data))
				dispatch(setSnackbarMsg('Coupon created'))
				navigate('/coupon')
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function copyThis (whatToCopy, message = '') {
		copy(whatToCopy || '')
		if (!_.isEmpty(_.trim(message))) {
			dispatch(setSnackbarMsg(`${message} copy to clipboard`))
		}
	}

	return (
		<StyledDiv>
			<PanelHead title={`Create new coupon`}>
				<Link to={'/coupon'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
				<Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
					Save
				</Button>
			</PanelHead>
			<Box sx={{p: 2}}>
				<Box component="form" noValidate onSubmit={submit}>
					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<Card>
								<CardHeader title={'Coupon attribute'}/>
								<CardContent>
									<TextField
										required
										fullWidth
										type="text"
										margin="normal"
										label="Name"
										name="name"
										InputLabelProps={{shrink: true}}
										error={!_.isEmpty(form.nameErr)}
										helperText={form.nameErr}
										value={form.name}
										onChange={changeForm}/>
									<TextField
										multiline
										rows={3}
										label="description"
										name="description"
										fullWidth
										margin="normal"
										value={form.description}
										InputLabelProps={{shrink: true}}
										onChange={changeForm}/>
									<TextField
										multiline
										rows={3}
										label="description public"
										name="descriptionPublic"
										fullWidth
										margin="normal"
										value={form.descriptionPublic}
										InputLabelProps={{shrink: true}}
										onChange={changeForm}/>
									<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
										<FormControlLabel
											control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
											label="is active"/>
									</FormGroup>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											label="From"
											value={form.from}
											inputFormat="DD/MM/YYYY"
											onChange={(value) => changeForm({target: {value, name: 'from'}})}
											renderInput={(params) => (
												<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
											)}
										/>
										<DatePicker
											label="To"
											value={form.to}
											minDate={form.from}
											inputFormat="DD/MM/YYYY"
											onChange={(value) => changeForm({target: {value, name: 'to'}})}
											renderInput={(params) => (
												<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
											)}
										/>
									</LocalizationProvider>

									<TextField
										select
										fullWidth
										required
										margin={'normal'}
										label={'Brand'}
										error={!_.isEmpty(form.brandsIdErr)}
										InputLabelProps={{shrink: true}}
										name="brandsId"
										value={form.brandsId}
										onChange={changeForm}>
										{
											brands.map((brand) => (
												<MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
											))
										}
									</TextField>
									<TextField
										select
										fullWidth
										required
										margin={'normal'}
										label={'Coupon Type'}
										error={!_.isEmpty(form.typeErr)}
										InputLabelProps={{shrink: true}}
										name="type"
										value={form.type}
										onChange={changeForm}>
										<MenuItem key="1" value={'subscription'}>Subscription</MenuItem>
										<MenuItem key="2" value={'ticket'}>Ticket</MenuItem>
									</TextField>
									{
										form.type !== 'subscription' ?
											<TextField
												select
												fullWidth
												margin={'normal'}
												label={'Event Name'}
												InputLabelProps={{shrink: true}}
												name="eticketEventId"
												value={form.eticketEventId}
												onChange={changeForm}>
												<MenuItem value="">Please select</MenuItem>
												{
													eventsPayload.map((event) => (
														<MenuItem key={event.id} value={event.id}>{event.title}</MenuItem>
													))
												}
											</TextField> 
										: 
											<TextField
												select
												fullWidth
												margin={'normal'}
												label={'Subscription plan'}
												InputLabelProps={{shrink: true}}
												name="planId"
												value={form.planId}
												onChange={changeForm}>
												<MenuItem value="">Please select</MenuItem>
												{
													subscriptionDropdown.map((subscription) => (
														<MenuItem key={subscription.id} value={subscription.id}>{subscription.name}</MenuItem>
													))
												}
											</TextField>
									}
								</CardContent>
							</Card>
						</Grid>
						<Grid xs={12} md={6}>
							<Card>
								<CardHeader title={'Coupon value attribute'}/>
								<CardContent>

									<Alert severity={'info'}>
										Each coupon will have a discount of {form.isValue ? 'RM' : ''}{form.value}{form.isValue ? '' : '%'}
									</Alert>

									<Grid container spacing={2}>
										<Grid xs={4}>
											<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
												<FormControlLabel
													control={<Switch checked={form.isValue} name={'isValue'} onChange={changeSwitch}/>}
													label="is hard value"/>
											</FormGroup>
										</Grid>
										<Grid xs={8}>
											<TextField
												required
												fullWidth
												type="number"
												margin="normal"
												label="value"
												name="value"
												error={!_.isEmpty(form.valueErr)}
												helperText={form.valueErr}
												value={form.value}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
												InputProps={{
													startAdornment: form.isValue ? <InputAdornment position="start">RM</InputAdornment> : <></>,
													endAdornment: form.isValue ? <></> : <InputAdornment position="end">%</InputAdornment>,
												}}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
							<Card sx={{mt: 3}}>
								<CardHeader title={'Code generation setting'}/>
								<CardContent>

									<Alert severity={'info'}>
										<>
											{
												form.isGeneric ? (
													`System will generate 1 code coupon that can be used ${form.max} times`
												) : (
													`System will generate ${form.max} unique code coupons`
												)
											}
										</>
									</Alert>

									<Grid container spacing={2}>
										<Grid xs={6} md={6}>
											<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
												<FormControlLabel
													control={<Switch
														checked={form.isGeneric}
														name={'isGeneric'}
														disabled={!_.isEmpty(_.trim(form.self))}
														onChange={changeSwitch}/>}
													label="is generic"/>
											</FormGroup>
										</Grid>
										<Grid xs={6} md={6}>
											<TextField
												required
												fullWidth
												InputProps={{min: '10'}}
												type="number"
												margin="normal"
												label="max"
												name="max"
												InputLabelProps={{shrink: true}}
												value={form.max}
												error={!_.isEmpty(form.maxErr)}
												helperText={!_.isEmpty(form.maxErr) ? form.maxErr : ''}
												onBlur={onBlur}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="self"
												name="self"
												InputLabelProps={{shrink: true}}
												value={form.self}
												error={!_.isEmpty(form.selfErr)}
												helperText={!_.isEmpty(form.selfErr) ? form.selfErr : ''}
												onBlur={onBlur}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												required={_.isEmpty(form.self)}
												disabled={!_.isEmpty(form.self)}
												InputProps={{min: '10'}}
												type="number"
												margin="normal"
												label="length"
												name="length"
												InputLabelProps={{shrink: true}}
												value={form.length}
												error={!_.isEmpty(form.lengthErr)}
												helperText={!_.isEmpty(form.lengthErr) ? form.lengthErr : ''}
												onBlur={onBlur}
												onChange={changeForm}/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="prefix"
												name="prefix"
												value={form.prefix}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
												disabled={!_.isEmpty(_.trim(form.self))}
											/>
										</Grid>
										<Grid xs={12} md={6}>
											<TextField
												fullWidth
												type="text"
												margin="normal"
												label="postfix"
												name="postfix"
												value={form.postfix}
												onChange={changeForm}
												InputLabelProps={{shrink: true}}
												disabled={!_.isEmpty(_.trim(form.self))}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Card sx={{mt: 3}}>
						<CardHeader title={'Generated API access'} subheader={'Please proceed with cautions!'} />
						<CardContent>
							<TextField
								fullWidth
								margin={'normal'}
								label={'Your authentication key *Key only valid for 1 day'}
								defaultValue={Cookies.get('_t')}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={() => copyThis(Cookies.get('_t'), 'Authentication key')}><ContentCopyOutlinedIcon /></IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								fullWidth
								margin={'normal'}
								label={'URL to call'}
								defaultValue={`${/dashboard\.revid\.my/.test(window.location.origin)? 'https://sso-api-pub.revid.my' : 'https://staging-sso-api-pub.revid.my'}/admin/coupons`}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={() => copyThis(Cookies.get('_t'), 'API URL')}><ContentCopyOutlinedIcon /></IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								fullWidth
								multiline
								rows={21}
								margin={'normal'}
								label={'Generated payload'}
								value={payload}
								InputLabelProps={{shrink: true}}
								onChange={() => {}}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={() => copyThis(payload, 'Generated payload')}><ContentCopyOutlinedIcon /></IconButton>
										</InputAdornment>
									),
								}}
							/>
						</CardContent>
					</Card>
				</Box>
			</Box>


		</StyledDiv>
	)
}
