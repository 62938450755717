import React, {useEffect, useState} from 'react'
import {Routes, Route, useHref} from 'react-router-dom'
import {useSelector} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import Homepage from './module/homepage'
import Login from './module/login'
import Account from "./module/account";
import Topbar from './components/topbar'
import Sidebar from './components/sidebar'
import Loading from './components/loading'
import Snackbars from './components/snackbars'
import Base from './module/base'
import Logout from './module/logout'
import NotFound from './module/not-found'

import acl from './lib/acl'

const drawerWidth = 220

const StyleDiv = styled(Box)`
  ${({theme, logged}) => {
    const {toolbar} = theme.mixins
    let {minHeight} = toolbar
    let landscape = toolbar['@media (min-width:0px)']['@media (orientation: landscape)'].minHeight
    let min600 = toolbar['@media (min-width:600px)'].minHeight

    const mobileQuery = theme.breakpoints.down('md')

    return `
        height: 100vh;   

        a {
            text-decoration:none;
						color: inherit;
        }
        
        .main{
            flex: 1;
            display: flex;
            z-index: 8;
            position: relative;
            max-height: 100vh;
        }
        
        .content {
			    	overflow-x: hidden;
        		-webkit-transition: width 0.3s 0s ease, margin-left 0.3s 0s ease;
						-moz-transition: width 0.3s 0s ease, margin-left 0.3s 0s ease;
						-o-transition: width 0.3s 0s ease, margin-left 0.3s 0s ease;
						transition: width 0.3s 0s ease, margin-left 0.3s 0s ease;
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
            align-items: ${logged === 'Y' ? 'stretch' : 'center'};
            min-height: calc(100vh - ${minHeight + parseInt(theme.spacing(2).replace('px', ''))}px);
            padding-left: ${logged === 'Y' ? drawerWidth : 0}px;
            padding-top: ${logged === 'Y' ? minHeight + parseInt(theme.spacing(2).replace('px', '')) : 0}px;
            
            > div {
            	flex: 1 1 auto;
            	max-width: 100%;
            }
            
            @media (min-width:0px) {			
							@media (orientation: landscape) {
								min-height: calc(100vh - ${landscape}px);
							}
						}
            
            @media (min-width:600px) {
            	min-height: calc(100vh - ${logged === 'Y' ? min600 : 0}px);
            	padding-top: ${logged === 'Y' ? min600 : 0}px;
            }
            
            ${mobileQuery} {
            	padding-left: 0px;
            	width: 100vw;
            }
					} 
        }
    `
  }}
`

function App() {
	const href = useHref()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {me} = useSelector((state) => state.profile)

	const [open, setOpen] = useState(isMobile ? false : true)

	useEffect(() => {
		if (_.isEmpty(me)) {
			setOpen(false)
		}
	}, [me])

	useEffect(() => {
		setOpen(false)
	}, [href])

	return (
		<StyleDiv logged={_.isEmpty(me)? 'N' : 'Y'}>
			<Base/>
			<Loading/>
			<Snackbars/>
			<Box className={'main'}>
				{
					!_.isEmpty(me) && (
						<Topbar width={drawerWidth} open={open} setOpen={setOpen}/>
					)
				}
				{

					!_.isEmpty(me) && (
						<Sidebar width={drawerWidth} open={open} setOpen={setOpen}/>
					)
				}

				{/* Content*/}
				<Box className={'content'}>
					<Routes>
						<Route path="/" element={<Homepage/>}/>
						<Route path="/login" element={<Login/>}/>
						<Route path="/me" element={<Account/>}/>
						{
							!_.isEmpty(me) && (
								<>
									{
										acl.urls.map((au, i) => {
											return (
												<React.Fragment key={i}>
													{me.module.indexOf(au.domain) !== -1 ? au.routes : ''}
												</React.Fragment>
											)
										})
									}
								</>
							)
						}
						<Route path="/logout" element={<Logout/>}/>
						<Route path="*" element={<NotFound/>}/>
					</Routes>
				</Box>
			</Box>

		</StyleDiv>
	)
}

export default App
