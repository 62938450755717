import React, {useEffect, useState} from "react";
import {styled, useTheme} from '@mui/material/styles'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {setSubscriptions, storePage, storeRowsPerPage} from "../../store/reducers/subscription";
import {setLoading} from "../../store/reducers/misc";
import _ from "lodash";
import {getSubscriptions} from "../../lib/request";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
`

export default function Tab1() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const {brandInFocus} = useSelector(state => state.profile)
    const {subscriptions, page, rowsPerPage} = useSelector((state) => state.subscription)

    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(null)
    const [inLoad, setInLoad] = useState(true)

    useEffect(() => {
        fetchSubscriptions()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (init === true) {
            fetchSubscriptions()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page])

    useEffect(() => {
        dispatch(storePage(0))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandInFocus])

    function fetchSubscriptions() {
        dispatch(setLoading(true))

        const q = {
            limit: _.clone(rowsPerPage),
            offset: rowsPerPage * page,
            brandId: brandInFocus,
            withSubscriber :false
        }

        let hasSearch = false

        if (!_.isEmpty(brandInFocus)) {
            hasSearch = true
        }

        if (hasSearch) {
            q.limit = 1
            q.offset = 0
        }

        getSubscriptions(q)
            .then((res) => {
                if (hasSearch) {
                    dispatch(storePage(0))
                } else {
                    dispatch(storePage(page))
                }
                dispatch(setSubscriptions(res.data.rows))
                setTotal(res.data.count)
                setInLoad(false)
            })
            .catch(err => {
                console.log(err.response.data.message)
            })
            .finally(() => {
                setInit(true)
                dispatch(setLoading(false))
            })


    }

    function handleChangePage(e, newPage) {
        dispatch(storePage(newPage))
    }

    function handleChangeRowsPerPage(e) {
        dispatch(storeRowsPerPage(parseInt(e.target.value, 10)))
        dispatch(storePage(0))
    }

    function nextPage(id) {
        navigate(`/subscription/${id}`)
    }

    return(
        <StyleDiv>
            <Box sx={{p: 2}}>
                {
                    inLoad ? (
                        <Alert severity={'info'}>Please wait. Fetching Subscription plans </Alert>
                    ) : ( _.isEmpty(subscriptions) ? (
                        <Alert severity={'info'}>No subscription plans available </Alert>
                    ) : (
                            <Box>
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={!total || total <= 0 ? 0 : page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}/>
                                <TableContainer>
                                    <Table>
                                        <TableHead className="tblHead">
                                            <TableRow>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Price</TableCell>
                                                <TableCell align="left">No. of Subscribers</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Durations</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                subscriptions.map((subscription, index) => {

                                                    let backgroundColor = ''
                                                    if (!subscription.isInternal && subscription.isActive) {
                                                        backgroundColor = theme.palette.error.light
                                                    } else if (subscription.isInternal && subscription.isActive) {
                                                        backgroundColor = theme.palette.info.light
                                                    }

                                                    let subscriptionInt = subscription.isAnnually ? '12 months' : `${subscription.intervals} months`
                                                    if (subscription.days > 0) {
                                                        subscriptionInt = `${subscription.days} days`
                                                    }

                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={() => nextPage(subscription.id)}
                                                            sx={{cursor: 'pointer', backgroundColor}}
                                                            key={index}>
                                                            <TableCell>{subscription.name}</TableCell>
                                                            <TableCell>RM{subscription.price ? subscription.price.toFixed(2) : 0}</TableCell>
                                                            <TableCell>{_.size(subscription.Subscribers)}</TableCell>
                                                            <TableCell>{subscription.isActive ? 'Active' : 'Inactive'}</TableCell>
                                                            <TableCell>{subscriptionInt}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    sx={{mt: 1}}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={!total || total <= 0 ? 0 : page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}/>
                            </Box>
                        )
                    )
                }
            </Box>

        </StyleDiv>
    )




}
