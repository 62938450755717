import {Link, useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import {useDispatch, useSelector} from 'react-redux'

import Box from '@mui/material/Box'
import {Button, TextField} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {styled} from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import {DataGrid} from '@mui/x-data-grid'

import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import PanelHead from '../../components/panel-head'
import DeleteConfirmationDialog from './delete-confirmation'
import {updateSubscriptionDropdown} from '../../store/reducers/subscription'
import {removeCoupon, updateCoupon} from '../../store/reducers/coupon'
import {deleteCoupon, editCoupon, getEticketEvents, getSubscriptions, viewCoupon} from '../../lib/request'
import { updateEventDropdown } from '../../store/reducers/event'


const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function CouponDetail() {
	const dispatch = useDispatch()
	const {id} = useParams()
	const navigate = useNavigate()
	const subscriptionDropdown = useSelector((state) => state.subscription.dropdown)
	const eventsPayload = useSelector((state) => state.event.dropdown)
	const {me, brandInFocus} = useSelector(state => state.profile)

	const [detail, setDetail] = useState({})
	const [toBeDelete, setToBeDelete] = useState(false)
	const [form, setForm] = useState({
		id: null,
		name: '',
		description: '',
		isGeneric: false,
		max: 10,
		isValue: false,
		value: '',
		duration: '',
		isActive: false,
		from: null,
		to: null,
		nameErr: '',
		maxErr: '',
		planId: '',
		eticketEventId: ''
	})

	const [usedCount, setUsedCount] = useState(0)
	const [totalCodes, setTotalCodes] = useState(0)
	const [usedRow, setUsedRow] = useState({})

	useEffect(() => {
		if (_.isEmpty(subscriptionDropdown)) {
			getSubscriptions({unlimited: true})
				.then((res) => {
					dispatch(updateSubscriptionDropdown(res.data.rows))
				})
				.catch(err => {
					console.log(err.response.data.message)
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscriptionDropdown])

	useEffect(() => {
		if (_.isEmpty(eventsPayload)) {
			getEticketEvents({ brandId: brandInFocus })
				.then((res) => {
					dispatch(updateEventDropdown(res.data))
				})
				.catch(err => {
					console.log(err.response.data.message)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventsPayload])

	useEffect(() => {
		if (!isNaN(id)) {
			dispatch(setLoading(true))
			viewCoupon(id)
				.then((res) => {
				
					setDetail(res.data)

					const formClone = _.cloneDeep(res.data)
					if (_.isNull(res.data.Subscriptionplans)) {
						formClone.subscriptionPlanId = ''
					} else {
						formClone.subscriptionPlanId = res.data.Subscriptionplans.id
					}

					formClone.brandId = res.data.Brands
					formClone.nameErr = ''
					formClone.maxErr = ''
					formClone.planId = formClone.subscriptionPlanId

					setForm(formClone)
					countUsed(res.data)
					userUsed(res.data)

					if (!_.isEmpty(res.data.Codes)) {
						setTotalCodes(res.data.Codes.length)
					}
				})
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.message))
					}
				})
				.finally(() => {
					dispatch(setLoading(false))
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function countUsed(data = {}) {
		let count = 0
		if (data.isGeneric) {
			count = data.Codes[0].Usedby.length
				? data.Codes[0].Usedby.length
				: data.Codes[0].UsedbyTicket.length
				? data.Codes[0].UsedbyTicket.length
				: 0;

		} else {
			data.Codes.forEach((c) => {
				let usedCode = _.pick(c, ['Usedby', 'UsedbyTicket']);
				if ((usedCode.Usedby && usedCode.Usedby.length > 0) || 
					(usedCode.UsedbyTicket && usedCode.UsedbyTicket.length > 0)) {
					count++;
				}
			})
		}
		setUsedCount(count)
	}

	const mapUsedbyData = (usedby, code) => ({
		id: usedby.id || '-',
		code: code || '-',
		profile: usedby.Profile?.Account?.email || '-',
		orderId: usedby.Payment?.orderId || '-',
		transactionId: usedby.Payment?.transactionId || '-',
		date: usedby.createdAt ? dayjs(usedby.createdAt).format('DD MMMM YYYY') : '-'
	})
	function userUsed(data = {}) {
		let uRow = {}
		if (data.isGeneric) {
			uRow = data.Codes.flatMap(c => [
				...c.Usedby.map(usedby => mapUsedbyData(usedby, c.code)),
				...c.UsedbyTicket.map(usedbyTicket => mapUsedbyData(usedbyTicket, c.code))
			]);
		}
		setUsedRow(uRow)
	}

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		formClone.nameErr = ''
		formClone.maxErr = ''
		setForm(formClone)
	}

	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v
		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()
		const formClone = _.cloneDeep(form)

		if (_.isEmpty(_.trim(form.planId))) {
			formClone.subscriptionPlanId = null
		} else {
			formClone.subscriptionPlanId = form.planId
		}

		if (_.isEmpty(_.trim(form.eticketEventId))) {
			formClone.eticketEventId = null
		} else {
			formClone.eticketEventId = form.eticketEventId
		}

		let error = false

		if (_.isEmpty(_.trim(form.name))) {
			formClone.nameErr = 'Name is required'
			error = true
		}

		if (detail.isGeneric && _.isEmpty(_.trim(form.max))) {
			formClone.maxErr = 'Max is required'
			error = true
		}

		if (detail.isGeneric && !_.isEmpty(_.trim(form.max)) && form.max < 0) {
			formClone.maxErr = 'Minimum value is 1'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		setForm(formClone)
		dispatch(setLoading(true))
		editCoupon(form.id, formClone)
			.then(res => {
				dispatch(updateCoupon(res.data))
				dispatch(setSnackbarMsg('Coupon updated'))
			})
			.catch(err => console.error(err.toString()))
			.finally(() => dispatch(setLoading(false)))

	}

	function confirmDelete() {
		dispatch(setLoading(true))
		deleteCoupon(form)
			.then(() => dispatch(removeCoupon(form)))
			.catch(err => console.error(err.toString()))
			.finally(() => {
				dispatch(setLoading(false))
				setToBeDelete(false)
				dispatch(setSnackbarMsg('Coupon deleted'))
				navigate('/coupon')
			})
	}

	const collumnsDef = [{
		field: 'code',
		headerName: 'Code',
		width: 250
	}, {
		field: 'profile',
		headerName: 'Claim by',
		width: 400,
		valueGetter: params => {
			if (_.isEmpty(params.row.Usedby) && _.isEmpty(params.row.UsedbyTicket)) {
				return '-'
			}
			return params.row.Usedby.length ? 
					params.row.Usedby[0].Profile.Account.email : 
				params.row.UsedbyTicket.length ? 
					params.row.UsedbyTicket[0].Profile.Account.email : '-'
		}
	}, {
		field: 'payment',
		headerName: 'Order ID detail',
		width: 150,
		valueGetter: params => {
			if (_.isEmpty(params.row.Usedby) && _.isEmpty(params.row.UsedbyTicket)) {
				return '-'
			}

			return params.row.Usedby.length ? 
					params.row.Usedby[0].Payment.orderId : 
				params.row.UsedbyTicket.length ? 
					params.row.UsedbyTicket[0].Payment.orderId : '-'
		}
	}, {
		field: 'paymenttxn',
		headerName: 'Order Txn detail',
		width: 150,
		valueGetter: params => {
			if (_.isEmpty(params.row.Usedby) && _.isEmpty(params.row.UsedbyTicket)) {
				return '-'
			}

			return params.row.Usedby.length ? 
					params.row.Usedby[0].Payment.transactionId : 
				params.row.UsedbyTicket.length ? 
					params.row.UsedbyTicket[0].Payment.transactionId : '-'
		}
	}, {
		field: 'date',
		headerName: 'Claim date',
		width: 350,
		valueGetter: params => {
			if (_.isEmpty(params.row.Usedby) && _.isEmpty(params.row.UsedbyTicket)) {
				return '-'
			}

			const claimed_date = params.row.Usedby.length ?
					params.row.Usedby[0].createdAt :
				params.row.UsedbyTicket.length ?
					params.row.UsedbyTicket[0].createdAt : '';

			return claimed_date ? dayjs(claimed_date).format('DD MMMM YYYY') : '-'
		}
	}]

	const collumnsDefG = [{
        field: 'code',
        headerName: 'Code',
        width: 250
    }, {
        field: 'profile',
        headerName: 'Claim by',
        width: 400
    }, {
        field: 'orderId',
        headerName: 'Order ID detail',
        width: 150
    }, {
        field: 'transactionId',
        headerName: 'Order Txn detail',
        width: 150
    }, {
        field: 'date',
        headerName: 'Claim date',
        width: 350
    }];
	
	return (
		<StyledDiv>
			<PanelHead title={`Coupon detail`}>
				<Link to={'/coupon'}>
					<Button variant="text" color={'inherit'} sx={{mr: 2}}>Back</Button>
				</Link>
				{
					!_.isEmpty(me) && (me.permission.includes('D') || me.username === 'superadmin') && (
						<Button
							color={'error'}
							variant="outlined" sx={{mr: 2}} onClick={() => setToBeDelete(true)} disabled={form.id === 404}>
							Delete
						</Button>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('U') || me.username === 'superadmin') && (
						<Button variant="outlined" onClick={submit} disabled={form.id === 404}>
							Save
						</Button>
					)
				}
			</PanelHead>
			<Box sx={{pl: 2, pr: 2}}>
				{
					detail.isGeneric && (
						<Typography variant="h6"> {usedCount} coupon(s) used</Typography>
					)
				}
				{
					!detail.isGeneric && (
						<Typography variant="h6">{usedCount}/{totalCodes} coupons used</Typography>
					)
				}

				<Box component="form" noValidate onSubmit={submit}>
					<TextField
						fullWidth
						required
						margin={'normal'}
						label="Name"
						variant="outlined"
						value={form.name}
						name={'name'}
						error={!_.isEmpty(form.nameErr)}
						InputLabelProps={{shrink: true}}
						onChange={changeForm}
						helperText={!_.isEmpty(form.nameErr) ? form.nameErr : ''}
					/>
					<TextField
						id="outlined-multiline-flexible"
						label="description"
						name="description"
						multiline
						rows={3}
						fullWidth
						margin="normal"
						value={form.description}
						InputLabelProps={{shrink: true}}
						onChange={changeForm}/>

					<Grid container spacing={2}>
						<Grid xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label="From"
									value={form.from}
									inputFormat="DD/MM/YYYY"
									onChange={(value) => changeForm({target: {value, name: 'from'}})}
									renderInput={(params) => (
										<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid xs={12} sm={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label="To"
									value={form.to}
									minDate={form.from}
									inputFormat="DD/MM/YYYY"
									onChange={(value) => changeForm({target: {value, name: 'to'}})}
									renderInput={(params) => (
										<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
									)}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid xs={12} sm={6}>
							<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
								<FormControlLabel
									control={<Switch checked={form.isValue} name={'isValue'} onChange={changeSwitch}/>}
									label="is value"/>
							</FormGroup>
						</Grid>
						<Grid xs={12} sm={6}>
							<TextField
								required
								fullWidth
								type="number"
								margin="normal"
								label="value"
								name="value"
								InputLabelProps={{shrink: true}}
								InputProps={{
									startAdornment: <InputAdornment position="start">{form.isValue ? 'RM' : '%'}</InputAdornment>
								}}
								onChange={changeForm}
								value={form.value}
							/>
						</Grid>
						<Grid xs={12} sm={6}>
							<TextField
								fullWidth
								disabled={!detail.isGeneric}
								type="number"
								margin="normal"
								label="max"
								name="max"
								InputLabelProps={{shrink: true}}
								value={form.max}
								error={!_.isEmpty(form.maxErr)}
								helperText={!_.isEmpty(form.maxErr) ? form.maxErr : ''}
								onChange={changeForm}/>
						</Grid>
						<Grid xs={12} sm={6}>
							<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
								<FormControlLabel
									control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
									label="is active"/>
							</FormGroup>
						</Grid>
						<Grid xs={12} sm={6}>
							<TextField
								disabled
								fullWidth
								margin="normal"
								label="Brand"
								value={_.isEmpty(detail.Brands) ? ' ' : detail.Brands.name}
								InputLabelProps={{shrink: true}}/>
						</Grid>
						<Grid xs={12} sm={6}>
							<TextField
								disabled
								fullWidth
								margin="normal"
								label="Coupon Type"
								value={form.type}
								InputLabelProps={{shrink: true}}/>
						</Grid>
						{form?.type === 'subscription' ? 
							<Grid xs={12} sm={6}>
								<TextField
									select
									fullWidth
									margin={'normal'}
									label={'Subscription plan'}
									InputLabelProps={{shrink: true}}
									name="planId"
									value={form.planId}
									onChange={changeForm}>
									<MenuItem value="">Please select</MenuItem>
									{
										subscriptionDropdown.map((subscription) => (
											<MenuItem key={subscription.id} value={subscription.id}>{subscription.name}</MenuItem>
										))
									}
								</TextField>
							</Grid>
						: 
							<Grid xs={12} sm={6}>
								<TextField
									select
									fullWidth
									margin={'normal'}
									label={'Event Name'}
									InputLabelProps={{shrink: true}}
									name="eticketEventId"
									value={form.eticketEventId}
									onChange={changeForm}>
									<MenuItem value="">Please select</MenuItem>
									{
										eventsPayload.map((event) => (
											<MenuItem key={event.id} value={event.id}>{event.title}</MenuItem>
										))
									}
								</TextField>
							</Grid>
						}
						
						
					</Grid>
				</Box>
				{
					!_.isEmpty(detail.Codes) && (
						<>
							<Typography variant="h5" sx={{mt: 3}}>Codes {detail.isGeneric ? `- ${detail.Codes[0].code}` : ''}</Typography>
							{
								!detail.isGeneric && (
									<DataGrid
										autoHeight
										rows={detail.Codes}
										columns={collumnsDef}
										initialState={{pagination: {paginationModel: {pageSize: 1}}}}
										pageSizeOptions={[5]}
										disableRowSelectionOnClick
									/>
								)
							}
							{
								
								detail.isGeneric && (
									<DataGrid
										autoHeight
										rows={usedRow}
										columns={collumnsDefG}
										initialState={{pagination: {paginationModel: {pageSize: 1}}}}
										pageSizeOptions={[5]}
										disableRowSelectionOnClick
									/>
								)
							}
						</>
					)
				}
			</Box>
			{
				toBeDelete && (
					<DeleteConfirmationDialog
						onCancel={() => setToBeDelete(false)}
						onConfirm={confirmDelete}
					/>
				)
			}
		</StyledDiv>
	)
}
