import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {setLoading} from "../../store/reducers/misc";
import {getPaymentStatusByMonth} from "../../lib/request";
import _ from "lodash";
import {Pie} from 'react-chartjs-2'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CardContent from "@mui/material/CardContent";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    ArcElement
} from "chart.js";


export default function PaymentStatusStats() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useDispatch()
    const brandInFocus = useSelector(state => state.profile.brandInFocus)

    const [dataset, setDataset] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState({brand: '', year: '', month: ''})
    const [hasResult, setHasResult] = useState(false)

    const currentYear = new Date().getFullYear()

    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
        years.push(year);
    }

    const defaultMonth = 1
    const months = [
        { key: 1, value: 'January' },
        { key: 2, value: 'February' },
        { key: 3, value: 'March' },
        { key: 4, value: 'April' },
        { key: 5, value: 'May' },
        { key: 6, value: 'June' },
        { key: 7, value: 'July' },
        { key: 8, value: 'August' },
        { key: 9, value: 'September' },
        { key: 10, value: 'October' },
        { key: 11, value: 'November' },
        { key: 12, value: 'December' },
    ];

    const options = {
        responsive: true,
        plugins: {
            legend: false
        }
    }

    const labels = ['Captured', 'Fail', 'Pending', 'Abandoned']

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement)

    useEffect(() => {
        setSelected({brand: brandInFocus, year: currentYear, month: defaultMonth})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandInFocus])

    useEffect(() => {

        const {brand, year, month} = selected

        if (brand && year) {
            setIsLoading(true)
            dispatch(setLoading(true))

            const q = { brand, year, month }

            let result = []

            getPaymentStatusByMonth(q)
                .then((res) => {
                    if (!_.isEmpty(res.data)) {
                        setHasResult(true)
                         result = labels.map(label => {
                            const matchingData = res.data.find(item => item.status === label);
                            return matchingData ? parseInt(matchingData.count, 10) : null;
                        });
                    }

                    const chartData = {
                        labels,
                        datasets: [
                            {
                                label: 'Payment Status',
                                data: result,
                                backgroundColor: [
                                    theme.palette.success.light,
                                    theme.palette.error.light,
                                    theme.palette.warning.light,
                                    'rgb(54, 162, 235)',
                                ],
                            }
                        ],
                        maintainAspectRatio: false,
                        responsive: false
                    }

                    setDataset(chartData)
                })
                .catch(err => {
                    console.log(err.response.data.message)
                })
                .finally(() => {
                    setIsLoading(false)
                    dispatch(setLoading(false))
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, dispatch])

    function handleChange(event) {
        const {name, value} = event.target
        setSelected(prevOptions => ({
            ...prevOptions,
            [name]: value
        }))
    }


    return (
        <Box sx={{mx: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{mb: 3}}>
                    <Card>
                        <Box sx={{px: 2, pt: 3}}>
                            <Typography component="h1" variant="h6" sx={{mb: 2}}>
                                Payment Status
                            </Typography>
                            <Grid container spacing={1} justifyContent="end">
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        select
                                        fullWidth
                                        size={'small'}
                                        label={'Year'}
                                        name={'year'}
                                        value={selected.year}
                                        onChange={handleChange}
                                        sx={{mt: isMobile ? 1 : 0}}
                                        InputLabelProps={{shrink: true}}>
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>{year}</MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        fullWidth
                                        size={'small'}
                                        label={'Month'}
                                        name={'month'}
                                        value={selected.month}
                                        onChange={handleChange}
                                        sx={{mt: 1}}
                                        InputLabelProps={{shrink: true}}>
                                        {months.map((month) => (
                                            <MenuItem key={month.key} value={month.key}>
                                                {month.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Box>
                        <CardContent>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                hasResult ? (
                                        <Pie
                                            data={dataset}
                                            options={options}
                                            width={'100%'}
                                            style={{ maxHeight: '250px', minHeight: '100px' }}
                                        />
                                ) : (
                                    <p>No data for this month and year</p>
                                )
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
