import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {useDispatch} from 'react-redux'

import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import {login, getProfile, getNetworks, getAllBrands} from '../../lib/request'
import {initialize} from '../../store/reducers/profile'
import {setNetworks} from '../../store/reducers/network'
import {setBrands} from '../../store/reducers/brand'

const StyleDiv = styled(Box)`
${({theme, logged}) => {
  const mobileQuery = theme.breakpoints.down('md')
	
	return `
		margin-bottom: calc(100vh / 2 - 100px);	
	
		img {
			max-width: 300px;
		}
		
		${mobileQuery} {
			padding: ${theme.spacing(3)};
			margin-bottom: 0px;
			
			
			
			img {
				max-width: 150px;
			}
		}
	`
}}`

export default function Login() {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [form, setForm] = useState({
		username: '',
		usernameErr: null,
		password: '',
		passwordErr: null
	})
	const [errMsg, setErrMsg] = useState('')

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		// Clear error state
		formClone.usernameErr = null
		formClone.passwordErr = null

		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)
		let error = false

		setErrMsg('')

		if (_.isEmpty(_.trim(form.username))) {
			formClone.usernameErr = 'Username is required'
			error = true
		} else if (_.isEmpty(_.trim(form.password))) {
			formClone.passwordErr = 'Password is required'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		// Call API
		login(form.username, form.password)
			.then(() => getProfile())
			.then(response => {
				dispatch(initialize(response.data))
				return getNetworks()
			})
			.then(response => {
				dispatch(setNetworks(response.data))
				return getAllBrands()
			})
			.then(response => dispatch(setBrands(response.data)))
			.then(() => navigate('/'))
			.catch(err => {
				if (err.response) {
					setErrMsg(err.response.data.message)
				} else {
					console.log('Others error')
				}
			})

	}

	return (
		<StyleDiv>
			<Grid container>
				<Grid xs={12}>
					<Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
						<img src={'https://storage.googleapis.com/static-oauth.revmedia.my/logo%404x.png'} alt={'rev.id'}/>
					</Box>
				</Grid>
				<Grid xs={12} sx={{mt: 3}}>
					<Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
						<Box sx={{width: '100%', maxWidth: 400}}>
							{
								!_.isEmpty(errMsg) && (
									<Alert severity="error" sx={{mb: 5}} onClose={() => setErrMsg('')}>
										<AlertTitle>{errMsg}</AlertTitle>
									</Alert>
								)
							}

							<Box component={'form'} onSubmit={submit}>
								<TextField
									fullWidth
									autoFocus
									margin="normal"
									label="Username"
									name="username"
									type="text"
									value={form.username}
									onChange={changeForm}
									error={!_.isNull(form.usernameErr)}
									InputLabelProps={{shrink: true}}
									helperText={form.usernameErr}/>
								<TextField
									fullWidth
									margin="normal"
									name="password"
									label="Password"
									type="password"
									autoComplete="current-password"
									value={form.password}
									onChange={changeForm}
									error={!_.isNull(form.passwordErr)}
									InputLabelProps={{shrink: true}}
									helperText={form.passwordErr}/>
								<Button
									fullWidth
									type="submit"
									variant="outlined"
									sx={{mt: 3, mb: 2}}>
									Sign In
								</Button>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</StyleDiv>
	)
}
