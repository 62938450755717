import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        applications: [],
        appDetail: {}
    },
    reducers: {
        setApplications: (state, action) => {
            state.applications = _.isArray(action.payload) ? _.orderBy(action.payload,['id'], ['asc']) : []
        },
        createApplication: (state, action) => {
            state.applications.push(action.payload)
        },
        removeApplication: (state, action) => {
            state.applications = state.applications.filter(application => application.id !== action.payload.id)
        },
        applicationDetail: (state, action) => {
            state.appDetail = action.payload
        }
    }
})

export const {setApplications, createApplication, removeApplication, applicationDetail} = applicationSlice.actions

export default applicationSlice.reducer
