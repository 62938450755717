import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Unstable_Grid2'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'


import PanelHead from '../../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {createCoupon} from '../../../store/reducers/coupon'
import {getSubscriptions, getPaymentMethods, addCampaigns} from '../../../lib/request'
import {updateSubscriptionDropdown} from '../../../store/reducers/subscription'
import {updatePaymentMethodDropdown } from '../../../store/reducers/paymentMethod'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Create() {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const subscriptionDropdown = useSelector((state) => state.subscription.dropdown)
	const paymentMethodDropdown = useSelector((state) => state.paymentMethod.dropdown)
	const me = useSelector((state) => state.profile.me)
    

	const [form, setForm] = useState({
		name: '',
		slug: '',
		price: '',
		isActive: false,
		startDate: null,
		endDate: null,
		planId: "",
		paymentMethodId: '',
		nameErr: '',
        slugErr:'',
		priceErr: ''
	})
	

	useEffect(() => {
		if (_.isEmpty(subscriptionDropdown)) {
			getSubscriptions({unlimited: true, withSubscriber :false, isActive :true})
				.then((res) => {
					dispatch(updateSubscriptionDropdown(res.data.rows))
				})
				.catch(err => {
					console.log(err.response.data.message)
				})

           
		}
    
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscriptionDropdown])

	useEffect(() => {
		if (_.isEmpty(paymentMethodDropdown)) {
          
			getPaymentMethods({unlimited: true})
				.then((res) => {
					dispatch(updatePaymentMethodDropdown(res.data.rows))
				})
				.catch(err => console.log(err.response.data.message))
		}
	}, [dispatch,paymentMethodDropdown])
	
	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		formClone.nameErr = ''
		formClone.slugErr = ''
		formClone.priceErr=''
		setForm(formClone)
	}



	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v
		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()
		const formClone = _.cloneDeep(form)

		if (_.isEmpty(_.trim(form.planId))) {
			formClone.subscriptionPlanId = null
		} else {
			formClone.subscriptionPlanId = form.planId
		}

		let error = false

        if (_.isEmpty(_.trim(form.name))) {
            formClone.nameErr = 'Name is required';
            error = true;
          }
          if (_.isEmpty(_.trim(form.slug))) {
            formClone.slugErr = 'Slug is required';
            error = true;
          }
          if (_.isEmpty(_.trim(form.price))) {
            formClone.priceErr = 'Price is required';
            error = true;
          }


		if (error) {
			return setForm(formClone)
		}

		formClone.brandId = form.brandsId
		setForm(formClone)
		dispatch(setLoading(true))

		addCampaigns(formClone)
			.then((res) => {
				dispatch(createCoupon(res.data))
				dispatch(setSnackbarMsg('Campaign created'))
				navigate('/subscription')
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDiv>
			<PanelHead title={`Create new campaign`}>
				<Link to={'/subscription'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
                {
                    !_.isEmpty(me) && (me.permission.includes("C")) && (
                    <Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
                        Save
                    </Button>
                    )
                }
			</PanelHead>
			<Box sx={{p: 2}}>
				<Box component="form" noValidate onSubmit={submit}>
					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<Card>
								<CardHeader title={'Campaign Attributes'}/>
								<CardContent>
									<TextField
										required
										fullWidth
										type="text"
										margin="normal"
										label="Name"
										name="name"
										InputLabelProps={{shrink: true}}
										error={!_.isEmpty(form.nameErr)}
										helperText={form.nameErr}
										value={form.name}
										onChange={changeForm}/>
								    <TextField
										required
										fullWidth
										type="text"
										margin="normal"
										label="Slug"
										name="slug"
										InputLabelProps={{shrink: true}}
										error={!_.isEmpty(form.slugErr)}
										helperText={form.slugErr}
										value={form.slug}
										onChange={changeForm}/>
                                     <TextField
                                                required
                                                fullWidth
                                                type="text"
                                                margin="normal"
                                                label="Price"
                                                name="price"
                                                InputLabelProps={{ shrink: true }}
                                                error={!_.isEmpty(form.priceErr)}
                                                helperText={form.priceErr}
                                                value={form.price}
                                                onChange={changeForm} />
									<FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
										<FormControlLabel
											control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
											label="is active"/>
									</FormGroup>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											label="Start Date"
											value={form.startDate}
											inputFormat="DD/MM/YYYY"
											onChange={(value) => changeForm({target: {value, name: 'startDate'}})}
											renderInput={(params) => (
												<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
											)}
										/>
										<DatePicker
											label="End Date"
											value={form.endDate}
											minDate={form.startDate}
											inputFormat="DD/MM/YYYY"
											onChange={(value) => changeForm({target: {value, name: 'endDate'}})}
											renderInput={(params) => (
												<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
											)}
										/>
									</LocalizationProvider>

                                    <TextField
												select
												fullWidth
												margin={'normal'}
												label={'Paymet Method'}
												InputLabelProps={{shrink: true}}
												name="paymentMethodId"
												value={form.paymentMethodId}
												onChange={changeForm}>
												<MenuItem value="">Please select</MenuItem>
												{
													paymentMethodDropdown.map((pm) => (
														<MenuItem key={pm.id} value={pm.id}>{pm.channel_name.toUpperCase()}</MenuItem>
													))
												}
											</TextField> 
								
											<TextField
												select
												fullWidth
												margin={'normal'}
												label={'Subscription plan'}
												InputLabelProps={{shrink: true}}
												name="planId"
												value={form.planId}
												onChange={changeForm}>
												<MenuItem value="">Please select</MenuItem>
												{
													subscriptionDropdown.map((subscription) => (
														<MenuItem key={subscription.id} value={subscription.id}>{subscription.name}</MenuItem>
													))
												}
											</TextField>
									
								</CardContent>
							</Card>
						</Grid>
					
					</Grid>
				
				</Box>
			</Box>


		</StyledDiv>
	)
}
