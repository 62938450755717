import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'

export const brandSlice = createSlice({
	name: 'brand',
	initialState: {
		brands: [],
	},
	reducers: {
		setBrands: (state, action) => {
			state.brands = _.isArray(action.payload) ? _.orderBy(action.payload, ['id'], ['asc']) : []
		},
		createBrand: (state, action) => {
			state.brands.push(action.payload)
		},
		updateBrand: (state, action) => {
			const index = _.findIndex(state.brands, {id: action.payload.id})
			if (index !== -1) {
				state.brands[index] = action.payload
			}
		},
		removeBrand: (state, action) => {
			state.brands = state.brands.filter(brand => brand.id !== action.payload.id)
		}
	}
})

export const {setBrands, createBrand, updateBrand, removeBrand} = brandSlice.actions

export default brandSlice.reducer
