import React, {useState} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Dialog, DialogContent, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

import {addBrand} from '../../lib/request'
import {createBrand} from '../../store/reducers/brand'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'

export default function AddBrandDialog(props) {

	const {close} = props

	const dispatch = useDispatch()
	const networks = useSelector((state) => state.network.networks)

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [errMsg, setErrMsg] = useState('')
	const [form, setForm] = useState({
		brand: '',
		nameErr: null,
		networks: [],
		networkErr: null
	})

	function handleSubmit(e) {
		e.preventDefault()

		const formClone = _.cloneDeep(form)

		let error = false
		if (_.isEmpty(_.trim(form.brand))) {
			formClone.nameErr = 'Name is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.networks))) {
			formClone.networkErr = 'Network is required'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		dispatch(setLoading(true))
		addBrand(formClone)
			.then((res) => {
				dispatch(createBrand(res.data))
				dispatch(setSnackbarMsg('Brand created'))
				close()
			})
			.catch(err => {
				setErrMsg(err.response.data.message)
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function changeBrandName(e) {
		const formClone = _.cloneDeep(form)
		formClone.brand =  e.target.value
		formClone.nameErr = null
		setForm(formClone)
	}

	function changeNetworks (e) {
		const value = parseInt(e.target.value)
		const formClone = _.cloneDeep(form)
		const index = formClone.networks.indexOf(value)
		if (index === -1) {
			formClone.networks.push(value)
		} else {
			formClone.networks.splice(index, 1)
		}

		formClone.networks = formClone.networks.filter((v, i, s) => s.indexOf(v) === i)

		if(formClone.networks.length > 0) {
			 formClone.networkErr = null
		 } else {
			formClone.networkErr = 'Network is required'
		}

		setForm(formClone)
	}

	return (
		<Dialog open fullScreen={isMobile} onClose={close} maxWidth={'md'} fullWidth>
			<DialogTitle>Add new brand</DialogTitle>
			<DialogContent>
				<Box>
					{!_.isEmpty(errMsg) && (
						<Alert severity="error">
							<AlertTitle>{errMsg}</AlertTitle>
						</Alert>
					)}
					{
						!_.isNull(form.nameErr) && (
							<Alert severity={'warning'} sx={{mb: 3}}>{form.nameErr || ''}</Alert>
						)
					}
					{
						!_.isNull(form.networkErr) && (
							<Alert severity={'warning'} sx={{mb: 3}}>{form.networkErr || ''}</Alert>
						)
					}
				</Box>

				<Box component="form" noValidate onSubmit={handleSubmit}>
					<TextField
						required
						fullWidth
						type="text"
						margin="normal"
						label="name"
						name="name"
						InputLabelProps={{shrink: true}}
						error={!_.isEmpty(form.nameErr)}
						helperText={form.nameErr}
						value={form.brand}
						onChange={changeBrandName}/>

					<FormGroup component="fieldset" sx={{mt: 2}}>
						<FormLabel component="legend">Assign to network(s)*</FormLabel>
						{
							networks.map(n => {
								return (
									<FormControlLabel
										key={n.id}
										value={n.id}
										control={<Checkbox checked={form.networks.indexOf(n.id) !== -1}/>}
										onChange={changeNetworks}
										label={n.name}
									/>
								)
							})
						}
					</FormGroup>
				</Box>
			</DialogContent>
			<DialogActions sx={{m: 2}}>
				<Button
					variant={isMobile? 'outlined' : 'text'}
					color={'inherit'}
					onClick={close}
					fullWidth={isMobile}>
					Cancel
				</Button>
				<Button
					variant="outlined"
					onClick={handleSubmit}
					fullWidth={isMobile}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}
