import React from 'react'

import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'

const StyledDialog = styled(Dialog)`
  ${({theme}) => `
    
  `}
`

export default function DeleteConfirmationDialog(props) {

	const {onCancel, onConfirm} = props

	return (
		<StyledDialog open onClose={onCancel}>
			<DialogTitle>Are you confirm to delete this apps credentials</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure to delete this apps credential information?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant={'outlined'} onClick={onCancel}>Cancel</Button>
				<Button color={'error'} variant={'outlined'} onClick={onConfirm}>Confirm delete</Button>
			</DialogActions>
		</StyledDialog>
	)
}
