import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import PanelHead from '../../../components/panel-head'
import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import { addEticketOrganizers, getEticketOrganizerbyID, editEticketOrganizer } from '../../../lib/request'

const StyledDiv = styled(Box)`
  .headerCard{
	    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tblHead th {
    font-weight: bolder;
  }
`

export default function CreateEvent() {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { id } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form, setForm] = useState({
        name: '',
        description: '',
        image: '',
        nameErr: '',
        imageErr: ''
    })

    useEffect(() => {
        if (!isNaN(id)) {
            dispatch(setLoading(true))
            getEticketOrganizerbyID(id)
                .then((res) => {
                    const formClone = _.cloneDeep(res.data)
                    setForm(formClone)

                })
                .catch(err => {
                    if (err.response) {
                        dispatch(setSnackbarMsg(err.response.data.message))
                    }
                })
                .finally(() => {
                    dispatch(setLoading(false))
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    function changeForm(e) {
        const { name, value } = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = value

        formClone.nameErr = ''
        formClone.imageErr = ''

        setForm(formClone)
    }


    function submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.name))) {
            formClone.nameErr = 'Name is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        addEticketOrganizers(formClone)
            .then((res) => {
                dispatch(setSnackbarMsg('Organizer created'))
                navigate('/eticket')
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }

    function updateSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.name))) {
            formClone.nameErr = 'Name is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))
        editEticketOrganizer(id, formClone)
            .then((res) => {
                dispatch(setSnackbarMsg('Organizer updated'))
                navigate('/eticket')
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }


    return (
        <StyledDiv>
            <PanelHead title={!isNaN(id) ? `Update Organizer Details` : `Create New Organizer`}>
                <Link to={'/eticket'}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'} sx={{ mr: isMobile ? 0 : 2 }}>
                        Back
                    </Button>
                </Link>
                {
                    !isNaN(id) ? (
                        <>
                            <Button fullWidth={isMobile} variant="outlined" onClick={updateSubmit} sx={{ mt: isMobile ? 1 : 0 }}>
                                Update
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{ mt: isMobile ? 1 : 0 }}>
                                Save
                            </Button>
                        </>
                    )
                }

            </PanelHead>
            <Box sx={{ p: 2 }}>
                <Box component="form" noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12}>
                            <Card>
                                <CardContent>
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        margin="normal"
                                        label="Name"
                                        name="name"
                                        InputLabelProps={{ shrink: true }}
                                        error={!_.isEmpty(form.nameErr)}
                                        helperText={form.nameErr}
                                        value={form.name}
                                        onChange={changeForm} />
                                    <TextField
                                        multiline
                                        rows={3}
                                        label="Description"
                                        name="description"
                                        fullWidth
                                        margin="normal"
                                        value={form.description}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={changeForm} />
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        margin="normal"
                                        label="Logo"
                                        name="image"
                                        InputLabelProps={{ shrink: true }}
                                        error={!_.isEmpty(form.imageErr)}
                                        helperText={form.imageErr}
                                        value={form.image}
                                        onChange={changeForm} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </StyledDiv >
    )
}
