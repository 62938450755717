import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {  styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import DialogShowPaymentDetail from './dialog-show-payment-detail';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .status--00': {
    backgroundColor: theme.palette.success.light
  },
  '& .status--11': {
    backgroundColor: theme.palette.error.light
  },
  '& .status--22': {
    backgroundColor: theme.palette.warning.light
  },
  '& .status--99': {
    backgroundColor: theme.palette.secondary.light
  },
}));

export default function Tab4(props) {
  const { EticketPackages: packages } = props.event;

  const [payments, setPayments] = useState([]);
  const [viewPayment, setViewPayment] = useState({});
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const pymont = [];
    packages.forEach(p => {
      p.Eticketqr.forEach(peQr => {
        const paymentDetail = peQr.Eticket.EticketPayments;
        const index = _.findIndex(pymont, p => p.payment.orderId === paymentDetail.orderId);
        if (index === -1) {
          pymont.push({ payment: paymentDetail, tickets: [] });
        }
      });
    });
    pymont.forEach((pyp, i) => {
      packages.forEach(pck => {
        pck.Eticketqr.forEach(peQr => {
          const paymentDetail = peQr.Eticket.EticketPayments;
          if (pyp.payment.orderId === paymentDetail.orderId) {
            peQr.package = pck;
            pymont[i].tickets.push(peQr);
          }
        });
      });
    });
    const sortedPayments = _.orderBy(pymont, ['payment.id'], ['desc']);
    setPayments(sortedPayments);
  }, [packages]);

  const columns = [
    { field: 'id', headerName: '#', minWidth: 30, renderCell: (params) => params.api.getRowIndex(params.row.id) + 1 },
    { field: 'orderId', headerName: 'Order Id', minWidth: 350 },
    { field: 'amount', headerName: 'Amount', minWidth: 100, renderCell: (params) => `RM ${parseFloat(params.value).toFixed(2)}` },
    { field: 'transactionId', headerName: 'Transaction Id', minWidth: 150 },
    { field: 'channel', headerName: 'Channel', minWidth: 150 },
    { field: 'payDate', headerName: 'Pay Date', minWidth: 200, renderCell: (params) => (
        dayjs(params.value, 'YYYY-MM-DD HH:mm:ss').isValid() ? dayjs(params.value).format('DD-MM-YYYY hh:mm A') : ''
      )
    }
  ];

  const rows = payments.map((py, i) => ({
    id: i,
    orderId: py.payment.orderId,
    amount: py.payment.amount,
    transactionId: py.payment.transactionId,
    channel: py.payment.EticketPaymentsMeta ? py.payment.EticketPaymentsMeta.channel : '',
    payDate: py.payment.payDate,
    status: py.payment.status
  }));

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {
        _.isEmpty(payments) ? (
          <Alert severity={'info'} sx={{ m: 2 }}>No available payment yet</Alert>
        ) : (
          <StyledDataGrid
            getRowHeight={() => 'auto'}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 3,
              },
              [`.MuiDataGrid-toolbarContainer`]: {
                py: 1,
                px: 3,
                borderBottom: '1px solid #e0e0e0'
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              },
            }}
            density={'comfortable'}
            rows={rows}
            autoHeight
            disableDensitySelector
            columns={columns}
            rowsPerPageOptions={[5, 10, 25]}
			pageSize={pageSize}
       		onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowClick={(params) => setViewPayment(payments[params.id])}
            getRowClassName={(params) => `status--${params.row.status}`}
          />
        )
      }
      {
        !_.isEmpty(viewPayment) && <DialogShowPaymentDetail payment={viewPayment} onClose={() => setViewPayment({})} />
      }
    </Box>
  );
}
