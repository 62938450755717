import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {setLoading, setSnackbarMsg} from "../../../store/reducers/misc";
import {editPaymentGroup} from "../../../lib/request";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Button, TextField} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {styled, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link, useParams} from "react-router-dom";
import {viewPaymentGroup} from "../../../lib/request";
import PanelHead from "../../../components/panel-head";
import Alert from "@mui/material/Alert";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function PaymentGroupDetail() {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {me} = useSelector(state => state.profile)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [inLoad, setInLoad] = useState(true)

    const [form, setForm] = useState({
        id: null,
        name: '',
        label: '',
        image: '',
        order: '',
        isEnabled: false,
        isPaymentMethod: false,
        nameErr: '',
        labelErr: '',
    })

    useEffect(() => {
        if (!_.isUndefined(id)) {
            dispatch(setLoading(true))
            viewPaymentGroup(id)
                .then((res) => {
                    setInLoad(false)
                    const formClone = _.cloneDeep(res.data)
                    formClone.image = res.data.image ? res.data.image: ''
                    formClone.order = res.data.order ? res.data.order: ''
                    formClone.nameErr = ''
                    formClone.labelErr = ''
                    setForm(formClone)
                })
                .catch(err => {
                    if (err.response) {
                        dispatch(setSnackbarMsg(err.response.data.message))
                    }
                })
                .finally(() => dispatch(setLoading(false)))
        }
    },[id, dispatch])

    function changeForm (e) {
        const {name, value} = e.target
        const formClone = _.clone(form)
        formClone[name] = value

        formClone.nameErr = ''
        formClone.labelErr = ''
        setForm(formClone)
    }

    function changeSwitch(e, v) {
        const {name} = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = v
        setForm(formClone)
    }

    function submit(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.name))) {
            formClone.nameErr = 'Name is required'
            error = true
        }

        if (_.isEmpty(_.trim(form.label))) {
            formClone.labelErr = 'Display name is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        editPaymentGroup(form.id, form)
            .then(() => {
                dispatch(setSnackbarMsg('Payment group updated'))
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }


    return(
        <StyledDiv>
            <PanelHead title={`Payment group detail`}>
                <Link to={'/payment-methods?tab=1'}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
                        Back
                    </Button>
                </Link>
                {
                    !_.isEmpty(me) && (me.permission.includes('U') || me.username === 'superadmin') && (
                        <Button fullWidth={isMobile} variant="outlined" sx={{mt: isMobile ? 1 : 0}} onClick={submit} disabled={form.id === 404}>
                            Save
                        </Button>
                    )
                }
            </PanelHead>
            {
                inLoad ? (
                    <Alert severity={'info'}>Please wait. Fetching Payment group detail </Alert>
                ) : (
                        <Box sx={{p: 2}}>
                            <Box component="form" noValidate onSubmit={submit}>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={6} item>
                                        <TextField
                                            fullWidth
                                            margin={'normal'}
                                            label="Name"
                                            variant="outlined"
                                            value={form.name}
                                            name={'name'}
                                            InputLabelProps={{shrink: true}}
                                            onChange={changeForm}
                                            error={!_.isEmpty(form.nameErr)}
                                            helperText={!_.isEmpty(form.nameErr) ? form.nameErr : ''}/>
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <TextField
                                            fullWidth
                                            margin={'normal'}
                                            label="Display name"
                                            variant="outlined"
                                            value={form.label}
                                            name={'label'}
                                            InputLabelProps={{shrink: true}}
                                            onChange={changeForm}
                                            error={!_.isEmpty(form.labelErr)}
                                            helperText={!_.isEmpty(form.labelErr) ? form.labelErr : ''}/>
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <TextField
                                            fullWidth
                                            margin={'normal'}
                                            label="Order"
                                            variant="outlined"
                                            value={form.order}
                                            name={'order'}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={changeForm}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <TextField
                                            fullWidth
                                            margin={'normal'}
                                            label="Image url"
                                            variant="outlined"
                                            value={form.image}
                                            name={'image'}
                                            InputLabelProps={{shrink: true}}
                                            onChange={changeForm}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={4} item>
                                        <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                            <FormControlLabel
                                                control={<Switch checked={form.isEnabled} name={'isEnabled'} onChange={changeSwitch}/>}
                                                label="is enabled"/>
                                        </FormGroup>
                                    </Grid>
                                    <Grid xs={12} sm={4} item>
                                        <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                            <FormControlLabel
                                                control={<Switch checked={form.isPaymentMethod} name={'isPaymentMethod'} onChange={changeSwitch}/>}
                                                label="is payment method"/>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )
            }
        </StyledDiv>
    )
}
