import React from 'react'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Unstable_Grid2'
import Alert from '@mui/material/Alert'

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

const StyledDiv = styled(Box)``

export default function Tab2(props) {
	const navigate = useNavigate()
	const {event} = props
	const eventPackages = event.EticketPackages

	return (
		<StyledDiv>
			{
				_.isEmpty(eventPackages) ? (
					<Alert severity={'info'} sx={{m: 2}}>No available packages yet</Alert>
				) : (
					<TableContainer>
						<Table sx={{minWidth: 650}}>
							<TableHead className="tblHead">
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell align="left">Title</TableCell>
									<TableCell align="left">Description</TableCell>
									<TableCell align="left">Price</TableCell>
									<TableCell align="center">Max availability</TableCell>
									<TableCell align="center">Max buy quantity</TableCell>
									<TableCell align="center">Available quantity</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									eventPackages.map((row, index) => {
										return (
											<TableRow
												key={index}
												hover
												sx={{cursor: 'pointer'}}
												onClick={() => navigate(`/eticket/package/${row.id}`)}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>
													<Grid container spacing={1}>
														{
															!row.isActive && <Grid xs={4} sm={2}><HighlightOffOutlinedIcon/></Grid>
														}
														<Grid xs={!row.isActive? 8 : 12} sm={!row.isActive ? 10 : 12}>{row.title}</Grid>
													</Grid>
												</TableCell>
												<TableCell>{row.description}</TableCell>
												<TableCell>RM {parseFloat(row.price).toFixed(2)}</TableCell>
												<TableCell align={'center'}>{row.max}</TableCell>
												<TableCell align={'center'}>{row.maxQuantity}</TableCell>
												<TableCell align={'center'}>{row.availableQuantity}</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				)
			}
		</StyledDiv>
	)

}
