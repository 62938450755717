import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import {DataGrid} from '@mui/x-data-grid'
import {getProfileByIDAudit} from "../../../lib/request";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)}px;
  `}
`

const collumnsDef = [{
	field: 'note',
	headerName: 'Notes',
	width: 500
}, {
	field: 'old_expiry',
	headerName: 'Old expiry',
	width: 200,
	valueGetter: params => {
		const d = dayjs(params.row.old_expiry)
		return (d.isValid())? d.format('DD-MM-YYYY') : '-'
	}
}, {
	field: 'new_expiry',
	headerName: 'New expiry',
	width: 200,
	valueGetter: params => {
		const d = dayjs(params.row.new_expiry)
		return (d.isValid())? d.format('DD-MM-YYYY') : '-'
	}
}, {
	field: 'plan_id',
	headerName: 'Plan ID',
	width: 100
}, {
	field: 'payment_order_id',
	headerName: 'Order ID',
	width: 150
}]

export default function Tab7(props) {
	const {id} = useParams()
	const {brandInFocus} = useSelector(state => state.profile)
	const [audits, setAudits] = useState([])

	useEffect(() => {
		let promise = null
		const qs = {}
		let params = {bId: brandInFocus}
		window.location.search.replace(/^\?/,'').split('&').forEach(l => {
			const split2 = l.split('=')
			qs[split2[0]] = decodeURIComponent(split2[1])
		})

		if(!_.isEmpty(_.trim(qs.isProfileId))) {
			params.isProfileId = qs.isProfileId;
			params.profileId = qs.profileId;
		}

		getProfileByIDAudit(id, params)
			.then(response => {
				const auditLogs = response.data
				const auditItem = []
				auditLogs.forEach((a,i) => {
					a.id = i
					auditItem.push(a)
				})
				setAudits(auditItem)
			})
			.catch(err => console.error(err))


	}, [])

	return (
		<StyledDiv>
			{
				_.isEmpty(audits) ? (
					<Alert severity={'info'} sx={{m: 2}}>No audit information can be found</Alert>
				) : (
					<DataGrid
						autoHeight
						rows={audits}
						columns={collumnsDef}
						initialState={{pagination: {paginationModel: {pageSize: 1}}}}
						pageSizeOptions={[5]}
						disableRowSelectionOnClick
					/>
				)
			}
		</StyledDiv>
	)
}
