import React from 'react'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import {FormControl, Switch} from '@mui/material'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const StyledBox = styled(Box)`

`

export default function Tab1(props) {

	const {form, changeForm, onSubmit, networks, changeNetworks} = props

	return (
		<StyledBox sx={{p: 2}}>
			<Box component={'form'} onSubmit={onSubmit}>
				<button style={{display: 'none'}} type={'submit'}></button>

				{
					!_.isNull(form.nameErr) && (
						<Alert severity={'warning'} sx={{mb: 3}}>{form.nameErr || ''}</Alert>
					)
				}

				{
					!_.isNull(form.networkErr) && (
						<Alert severity={'warning'} sx={{mb: 3}}>{form.networkErr || ''}</Alert>
					)
				}

				<TextField
					fullWidth
					required
					margin={'normal'}
					label="Brand name"
					variant="outlined"
					value={form.name}
					name={'name'}
					error={!_.isNull(form.nameErr)}
					InputLabelProps={{shrink: true}}
					onChange={changeForm}
					helperText={!_.isNull(form.nameErr) ? form.nameErr : ''}
				/>

				<Box sx={{p: 1}}>
					<FormControl component="fieldset" variant="standard">
						<FormLabel component="legend">Assign to network(s)*</FormLabel>
						<FormGroup>
							{
								networks.map(n => {
									return (
										<FormControlLabel
											key={n.id}
											value={n.id}
											control={<Checkbox checked={form.networks.indexOf(n.id) !== -1}/>}
											onChange={changeNetworks}
											label={n.name}
										/>
									)
								})
							}
						</FormGroup>
					</FormControl>
				</Box>

				<Box sx={{p: 1}}>
					<FormControl component="fieldset" variant="standard">
						<FormLabel component="legend">Settings</FormLabel>
						<FormGroup>
							<FormControlLabel
								control={<Switch name={'isNeedVerify'} checked={form.isNeedVerify} onChange={changeForm} />}
								label="Authentication check need to be verified" />
						</FormGroup>
					</FormControl>
				</Box>

			</Box>
		</StyledBox>
	)

}