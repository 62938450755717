import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import copy from 'copy-to-clipboard'

import Box from '@mui/material/Box'
import {styled, useTheme} from '@mui/material/styles'
import {Button, ListItem, TextField} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import InputAdornment from '@mui/material/InputAdornment'

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {editCredential, getAllApplications, getCredentials} from '../../../lib/request'
import PanelHead from '../../../components/panel-head'
import {setApplications} from '../../../store/reducers/application'
import {setCredentials} from '../../../store/reducers/credentials'

const StyledDiv = styled(Box)`
`

export default function CredentialDetail() {
	const theme = useTheme()
	const {id, clientId} = useParams()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {me} = useSelector(state => state.profile)
	const applications = useSelector((state) => state.application.applications)
	const credentials = useSelector((state) => state.credential.credentials)

	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const [application, setApplication] = useState({})
	const [credential, setCredential] = useState({})

	const [form, setForm] = useState({
		id: null,
		client_id: '',
		task: 'reset',
		name: '',
		nameErr: null,
		redirect_uri: '',
		redirectUriErr: null,
		sso_key: '',
		t_name: '',
		ga: '',
		pay_prefix: '',
		dc_url: '',
	})

	const [redirectUris, setRedirectUris] = useState([{redirect_uri: '',}])

	useEffect(() => {
		if (_.isEmpty(applications)) {
			getAllApplications()
				.then(res => dispatch(setApplications(res.data)))
				.then(() => getCredentials())
				.then(res => dispatch(setCredentials(res.data.credentials)))
				.catch(err => console.log(err.response.data.message))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applications])

	useEffect(() => {
		if (!_.isEmpty(credentials)) {
			findIt()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [credentials])

	function findIt() {
		const application = _.find(applications, {id: parseInt(id)})
		const credential = _.find(credentials, {client_id: clientId})

		if (!_.isEmpty(credential)) {
			setApplication(application)
			setCredential(credential)
			setForm({
				id: credential.id,
				client_id: credential.client_id,
				task: 'reset',
				name: credential.name,
				nameErr: null,
				redirect_uri: credential.redirect_uri,
				redirectUriErr: null,
				sso_key: credential.sso_key,
				t_name: credential.t_name,
				ga: credential.ga_id,
				dc_url: credential.dc_url,
			})

			if (!_.isEmpty(_.trim(credential.redirect_uri))) {
				let redirectArr = []
				_.forEach(credential.redirect_uri.split(','), function (value) {
					if (!_.isEmpty(_.trim(value))) {
						redirectArr.push({redirect_uri: value})
					}
				})
				setRedirectUris(redirectArr)
			}
		}
	}

	function addRedirectUri() {
		setRedirectUris([...redirectUris, {redirect_uri: ''}])
	}

	function removeRedirectUris(index) {
		const rows = [...redirectUris]
		rows.splice(index, 1)
		setRedirectUris(rows)
	}

	function handleChange(index, e) {
		const {name, value} = e.target
		const list = [...redirectUris]
		list[index][name] = value
		setRedirectUris(list)

		const formClone = _.cloneDeep(form)
		formClone.redirectUriErr = null

		setForm(formClone)
	}


	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)

		formClone[name] = value
		formClone.nameErr = null
		formClone.redirectUriErr = null

		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)

		let redirectUriArr = []
		_.forEach(redirectUris, function (value) {
			if (!_.isEmpty(_.trim(value.redirect_uri))) {
				redirectUriArr.push(value.redirect_uri)
			}
		})

		formClone.redirect_uri = redirectUriArr.join()

		let err = false
		if (_.isEmpty(_.trim(form.name))) {
			err = true
			formClone.nameErr = 'Application name is required'
		}
		if (_.isEmpty(_.trim(formClone.redirect_uri))) {
			err = true
			formClone.redirectUriErr = 'Redirect Uri is required'
		}

		if (err) {
			return setForm(formClone)
		}

		if (_.isEmpty(_.trim(formClone['t_name']))) {
			formClone['t_name'] = 'null'
		}

		dispatch(setLoading(true))

		editCredential(formClone)
			.then(() => getCredentials())
			.then(res => dispatch(setCredentials(res.data.credentials)))
			.then(() => dispatch(setSnackbarMsg('Credentials updated')))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.msg))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function copyClipboard (e, text, msg) {
		e.preventDefault()
		e.stopPropagation()

		copy(text)
		dispatch(setSnackbarMsg(!_.isEmpty(_.trim(msg)) ? msg : `text copied`))
	}

	return (
		<>
			<StyledDiv>
				<PanelHead title={`Apps credential detail`} subtitle={application.apiKey}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile ? 0 : 2}}
						onClick={() => navigate(-1)}>
						Back
					</Button>

					{
						!_.isEmpty(me) && (me.permission.includes('U')) && (
							<Button
								fullWidth={isMobile}
								variant="outlined"
								onClick={submit}
								sx={{mt: isMobile ? 1 : 0}}
								disabled={form.id === 404}>
								Save
							</Button>
						)
					}

				</PanelHead>

				{
					_.isNull(form.id) && <Alert severity={'error'}>The credential cannot be found!</Alert>
				}

				{
					!_.isNull(form.nameErr) && (
						<Alert severity={'warning'} sx={{m: 2}}>{form.nameErr || ''}</Alert>
					)
				}

				{
					!_.isNull(form.redirectUriErr) && (
						<Alert severity={'warning'} sx={{m: 2}}>{form.redirectUriErr || ''}</Alert>
					)
				}

				<Box component={'form'} onSubmit={submit} sx={{p: 2}}>
					<button style={{display: 'none'}} type={'submit'}/>

					<Grid container spacing={2}>
						<Grid xs={6}>
							{
								!_.isEmpty(application) && (
									<TextField
										fullWidth
										type="text"
										margin="normal"
										label="Site application name"
										InputLabelProps={{shrink: true}}
										InputProps={{readOnly: true}}
										defaultValue={application.label} />
								)
							}
						</Grid>
						<Grid xs={6}>
							<TextField
								required
								fullWidth
								type="text"
								margin="normal"
								label="Application Name"
								name="name"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.nameErr)}
								value={form.name}
								onChange={changeForm}/>
						</Grid>
						<Grid xs={6}>
							{
								!_.isEmpty(credential) && (
									<TextField
										fullWidth
										type="text"
										margin="normal"
										label="Client Id"
										defaultValue={credential.client_id}
										InputLabelProps={{shrink: true}}
										InputProps={{
											readOnly: true,
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={(e) => copyClipboard(e, credential.client_id, 'Client ID copied')}
														edge="end">
														<ContentCopyOutlinedIcon/>
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								)
							}
						</Grid>
						<Grid xs={6}>
							{
								!_.isEmpty(credential) && (
									<TextField
										fullWidth
										type="text"
										margin="normal"
										label="Client Secret"
										defaultValue={credential.client_secret}
										InputLabelProps={{shrink: true}}
										InputProps={{
											readOnly: true,
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={(e) => copyClipboard(e, credential.client_secret, 'Client secret copied')}
														edge="end">
														<ContentCopyOutlinedIcon/>
													</IconButton>
												</InputAdornment>
											)
										}} />
								)
							}
						</Grid>
						<Grid xs={6}>
							<TextField
								fullWidth
								margin={'normal'}
								label="t_name"
								variant="outlined"
								value={_.isEmpty(form.t_name) ? ' ' : form.t_name}
								name={'t_name'}
								InputLabelProps={{shrink: true}}
								helperText={'Template ID. Leave empty to use default template'}
								onChange={changeForm}
							/>
						</Grid>
						<Grid xs={6} sm={6}>
							<TextField
								fullWidth
								margin={'normal'}
								label="ga id"
								variant="outlined"
								value={_.isEmpty(form.ga) ? ' ' : form.ga}
								name={'ga'}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}
							/>
						</Grid>
						<Grid xs={12} sm={12}>
							<TextField
								fullWidth
								margin={'normal'}
								label="Direct checkout Url"
								variant="outlined"
								value={_.isEmpty(form.dc_url) ? ' ' : form.dc_url}
								name={'dc_url'}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} sx={{mt: 2}} alignItems={'center'}>
						<Grid xs={12} md={10}>
							<Typography variant={'h5'}>
								Redirect Uri
							</Typography>
						</Grid>
						<Grid xs={12} md={2} sx={{textAlign: 'right'}}>
							<Button variant={'outlined'} onClick={addRedirectUri}>Add</Button>
						</Grid>
					</Grid>

					<List>
						{
							redirectUris.map((data, index) => {
								const {redirect_uri} = data
								return (
									<ListItem
										disableGutters
										key={index}
										secondaryAction={
											redirectUris.length !== 1 && (
												<IconButton edge="end" aria-label="delete" onClick={() => removeRedirectUris(index)}>
													<DeleteIcon/>
												</IconButton>
											)
										}>
										<TextField
											fullWidth
											required
											margin={'normal'}
											label="Redirect Uri"
											variant="outlined"
											value={redirect_uri}
											error={!_.isEmpty(form.redirectUriErr)}
											helperText={form.redirectUriErr}
											name={'redirect_uri'}
											InputLabelProps={{shrink: true}}
											onChange={(e) => handleChange(index, e)}/>
									</ListItem>
								)
							})
						}
					</List>

				</Box>
			</StyledDiv>
		</>

	)
}
