import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone'

import PanelHead from '../../../components/panel-head'
import SearchBox from './search'
import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import { getProfiles } from '../../../lib/request'
import { setProfilePage, setProfileRowsPerPage, setProfiles } from '../../../store/reducers/profile'

const StyledDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
	
  .tablerow {
    cursor: pointer;

    &:last-child td, &:last-child th {
      border: 0px;
    }
		
		a.strike {
      text-decoration: line-through;
		}
  }
`

export default function Listing(props) {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { profiles, page, rowsPerPage, brandInFocus } = useSelector(state => state.profile)

	const [search, setSearch] = useState({ text: '', verify: 'x' })
	const [total, setTotal] = useState(0)
	const [init, setInit] = useState(null)


	useEffect(() => {
		fetchProfiles()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (init === true) {
			fetchProfiles()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowsPerPage, page])

	useEffect(() => {
		handleChangePage(null, 0)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandInFocus])

	function fetchProfiles() {
		dispatch(setLoading(true))

		let q = {
			brandId: brandInFocus,
			limit: _.clone(rowsPerPage),
			offset: rowsPerPage * page
		}

		getProfiles(q)
			.then(response => {
				dispatch(setProfiles(response.data.rows))
				setTotal(response.data.count)
			})
			.catch(err => dispatch(setSnackbarMsg(err.response.data.message)))
			.finally(() => {
				setInit(true)
				dispatch(setLoading(false))
			})
	}

	function beginSearch(e) {
		e.preventDefault()
		e.stopPropagation()

		let q = {
			brandId: brandInFocus,
			limit: _.clone(rowsPerPage),
			offset: rowsPerPage * page
		}

		if (!_.isEmpty(search.text) && /^[0-9]+$/.test(search.text)) {
			q.phone = _.clone(search.text)
		} else if (!_.isEmpty(search.text)) {
			q.email = _.clone(search.text)
		}

		if (!_.isEqual(search.verify, 'x')) {
			q.verify = _.clone(search.verify);
		}

		dispatch(setLoading(true))

		getProfiles(q)
			.then(response => {
				if (!_.isEmpty(response.data.rows)) {
					dispatch(setProfiles(response.data.rows))
					setTotal(response.data.count)
				} else if (!_.isEmpty(_.trim(search.text))) {
					dispatch(setSnackbarMsg(`Sorry, ${search.text} is not in our system.`))
					dispatch(setProfiles([]))
				} else {
					dispatch(setProfiles([]))
				}
			})
			.catch(err => dispatch(setSnackbarMsg(err.response.data.message)))
			.finally(() => {
				dispatch(setLoading(false))
				setInit(true)
			})
	}

	function handleChangePage(e, newPage) {
		dispatch(setProfilePage(newPage))
	}

	function handleChangeRowsPerPage(e) {
		dispatch(setProfileRowsPerPage(parseInt(e.target.value, 10)))
		dispatch(setProfilePage(0))
	}

	function prevent(e) {
		e.stopPropagation()
	}

	return (
		<StyledDiv>
			<PanelHead title={'User Profiles'} />
			<SearchBox beginSearch={beginSearch} search={search} setSearch={setSearch} />

			<TablePagination
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={total}
				rowsPerPage={rowsPerPage}
				page={!total || total <= 0 ? 0 : page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage} />

			<TableContainer>
				<Table>
					<TableHead className="tblHead">
						<TableRow>
							<TableCell align="left" width={10}>#</TableCell>
							<TableCell align="left" width={130}>Phone</TableCell>
							<TableCell align="left">Email</TableCell>
							<TableCell align="center" width={70}>Verified</TableCell>
							<TableCell align="center" width={70}>REV.ID</TableCell>
							<TableCell align="center" width={70}>Subscriber</TableCell>
							<TableCell align={'right'} width={200}>Last logged</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							profiles.map((row, index) => {
								const isExpiry = row.expiry ? dayjs(row.expiry).isBefore(dayjs()) : false
								return (
									<TableRow
										hover
										className={'tablerow'}
										onClick={() => navigate(`/profiles/${row.id}`)}
										key={index}>
										<TableCell width={10}>
											{index + 1 + (rowsPerPage * page)}
										</TableCell>
										<TableCell width={130}>
											{row.phone}
										</TableCell>
										<TableCell>
											<Link
												to={`/profiles/${row.id}`}
												target={'_blank'}
												onClick={prevent}
												className={row.isOptOut ? 'strike' : ''}>
												{row.email}
											</Link>
										</TableCell>
										<TableCell width={70} align={'center'}>
											{row.verify ? <CheckOutlinedIcon color={'success'} /> : <ClearTwoToneIcon />}
										</TableCell>
										<TableCell width={70} align={'center'}>
											{row.revid ? <CheckOutlinedIcon color={'success'} /> : <ClearTwoToneIcon />}
										</TableCell>
										<TableCell width={70} align={'center'}>
											{row.plansId ? <CheckOutlinedIcon color={isExpiry ? 'warning' : 'success'} /> : <ClearTwoToneIcon />}
										</TableCell>
										<TableCell align={'right'} width={200}>
											{row.lastLogged ? dayjs(row.lastLogged).format('DD/MM/YYYY h:mm A') : '-'}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				sx={{ mt: 2 }}
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={total}
				rowsPerPage={rowsPerPage}
				page={!total || total <= 0 ? 0 : page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage} />

		</StyledDiv>
	)
}
