import React from 'react'
import _ from 'lodash'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import dayjs from 'dayjs'

export default function DialogConfirmChangeUserSubscription(props) {

	const {onClose, onConfirm, plans, before, after} = props

	const newPlan = _.find(plans, {id: after.plansId})
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog  open fullScreen={isMobile} onClose={onClose}>
			<DialogTitle>Confirm to update user subscription detail</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you confirm to update this user subscription.
					Below is the changes
				</DialogContentText>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align={'left'}></TableCell>
								<TableCell align={'left'}>Original</TableCell>
								<TableCell align={'left'}>Change to</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Subscription Plan</TableCell>
								<TableCell>{before ? before.Subscriptionplans.name : '-'}</TableCell>
								<TableCell>{newPlan.name || 'No Plan'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Expiry</TableCell>
								<TableCell>{before ? dayjs(before.expiry).format('DD MMMM YYYY') : '-'}</TableCell>
								<TableCell>{after.expiry.clone().format('DD MMMM YYYY')}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Notes</TableCell>
								<TableCell>{before ? before.notes : '-'}</TableCell>
								<TableCell>{after.notes}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button variant={isMobile? 'outlined' : 'text'} color={'inherit'} onClick={onClose} fullWidth={isMobile}>Cancel</Button>
				<Button variant={'outlined'} color={'primary'} onClick={onConfirm} fullWidth={isMobile}>Yes, Confirm</Button>
			</DialogActions>
		</Dialog>
	)
}
