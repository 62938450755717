import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Unstable_Grid2'
import { Button } from '@mui/material'
import { getAllApplications, getCredentials } from '../../lib/request'
import { setApplications } from '../../store/reducers/application'
import Form from './form'
import PanelHead from '../../components/panel-head'
import { useNavigate } from 'react-router-dom'
import { setCredentials } from '../../store/reducers/credentials'
import SearchBox from './search'
import { setSnackbarMsg } from '../../store/reducers/misc'
import { TablePagination } from '@mui/material'

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
`

export default function Application() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const applications = useSelector((state) => state.application.applications)
	const credentials = useSelector((state) => state.credential.credentials)
	const brands = useSelector((state) => state.brand.brands)
	const { me } = useSelector(state => state.profile)

	const [applicationListing, setApplicationListings] = useState(applications)
	const [formOpen, setFormOpen] = useState(false)
	const [search, setSearch] = useState('')

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	function handleChangePage(e, newPage) {
		setPage(newPage)
	}

	function handleChangeRowsPerPage(e) {
		setRowsPerPage(parseInt(e.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		fetchApplications()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function fetchApplications() {
		getAllApplications()
			.then((res) => {
				dispatch(setApplications(res.data))
				setApplicationListings(res.data)
				return getCredentials()
			})
			.then(response => dispatch(setCredentials(response.data.credentials)))
			.catch(err => console.log(err.response.data.message))
	}

	function nextPage(id) {
		navigate(`/application/credentials/${id}`)
	}

	function beginSearch(e) {
		e.preventDefault()
		e.stopPropagation()

		if (!_.isEmpty(search)) {
			const credential = _.find(credentials, { client_id: search })
			if (credential) {
				const application = _.find(applications, { apiKey: credential.sso_key })
				if (application) {
					navigate(`/application/credentials/${application.id}/detail/${search}`)
				} else {
					dispatch(setSnackbarMsg(`Cannot found matching client ID ${search}`))
				}
			} else {
				dispatch(setSnackbarMsg(`Cannot found matching client ID ${search}`))
			}
		}
	}


	return (
		<StyleDiv>
			<PanelHead title={'Site applications (Apps)'}>
				<Grid container spacing={2} justifyContent="end">
					<Grid lg={'auto'} xs={12} order={{ xs: 2, lg: 1 }}>
						<SearchBox beginSearch={beginSearch} search={search} setSearch={setSearch} />
					</Grid>
					<Grid lg={'auto'} xs={12} order={{ xs: 1, lg: 2 }}>
						{
							!_.isEmpty(me) && (me.permission.includes('C')) && (
								<Button variant="outlined" onClick={() => setFormOpen(true)} fullWidth sx={{ height: 1 }}>
									Add application
								</Button>
							)
						}
					</Grid>
				</Grid>
			</PanelHead>

			<TableContainer>
				<Table>
					<TableHead className="tblHead">
						<TableRow>
							<TableCell>#</TableCell>
							<TableCell align="left">Label</TableCell>
							<TableCell align="left">Brand</TableCell>
							<TableCell align="right">Total Credentials</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							applicationListing.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
								let total = 0
								credentials.forEach(c => {
									if (c.sso_key === row.apiKey) {
										total++
									}
								})

								return (
									<TableRow
										hover
										onClick={() => nextPage(row.id)}
										sx={{ cursor: 'pointer' }}
										key={index}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>{row.label}</TableCell>
										<TableCell>
											{!_.isNull(row.Brands) ? row.Brands.name : '-'}
										</TableCell>
										<TableCell align="right">{total}</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				sx={{ mt: 1 }}
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={applicationListing.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage} />
			{
				formOpen && <Form onClose={() => setFormOpen(false)} brands={brands} />
			}
		</StyleDiv>
	)
}
