import React from 'react'
import QRCode from 'react-qr-code'
import dayjs from 'dayjs'
import _ from 'lodash'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function DialogShowPaymentDetail(props) {

	const {payment, onClose} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog open={true} onClose={onClose} fullScreen={isMobile} fullWidth>
			<DialogTitle>View payment detail</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Order ID'}
							defaultValue={payment.payment.orderId}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Total amount payment'}
							defaultValue={parseFloat(payment.payment.amount).toFixed(2)}
							InputProps={{
								startAdornment: <InputAdornment position={'start'}>RM</InputAdornment>
							}}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Transaction ID'}
							defaultValue={payment.payment.transactionId}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Status'}
							defaultValue={payment.payment.status}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Payment captured date'}
							defaultValue={dayjs(payment.payment.payDate).isValid() ? dayjs(payment.payment.payDate).format('DD MMM YYYY hh:mm A') : ''}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Channel'}
							defaultValue={payment.payment.EticketPaymentsMeta?.channel || ''}
						/>
					</Grid>
				</Grid>
				{
					!_.isEmpty(payment.tickets) && (
						<>
							<Typography variant={'h6'} sx={{mt: 2}}>
								Buyer detail
							</Typography>
							<Grid container spacing={2}>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										margin={'normal'}
										label={'Email'}
										defaultValue={payment.payment.Profile.Account.email}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										margin={'normal'}
										label={'Phone number'}
										defaultValue={payment.payment.Profile.Account.phone}
									/>
								</Grid>
								<Grid xs={12}>
									<TextField
										fullWidth
										margin={'normal'}
										label={'Full name'}
										defaultValue={payment.payment.Profile.name}
									/>
								</Grid>
							</Grid>
							<Typography variant={'h6'} sx={{mt: 2}}>
								Tickets
							</Typography>
							<TableContainer>
								<Table>
									<TableHead className="tblHead">
										<TableRow>
											<TableCell align="left" width={10}>#</TableCell>
											<TableCell align="center">QR</TableCell>
											<TableCell align="left">Package name</TableCell>
											<TableCell align="left">Package price</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											payment.tickets.map((t,i) => {
												return (
													<TableRow key={i} hover sx={{ cursor: 'pointer', height: 103 }}>
														<TableCell>{i+1}</TableCell>
														<TableCell align="center" width={64}>
															<Box sx={{maxWidth: 64}}>
																{
																	t.Eticket.EticketPayments.status=== '00' && (
																		<QRCode
																			size={256}
																			style={{ height: "auto", maxWidth: "100%", width: "100%" }}
																			viewBox={`0 0 256 256`}
																			value={t.qrHash} />
																	)
																}
															</Box>
														</TableCell>
														<TableCell>{t.package.title}</TableCell>
														<TableCell>RM {parseFloat(t.package.price).toFixed(2)}</TableCell>
													</TableRow>
												)
											})
										}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)
				}
			</DialogContent>
			<DialogActions>
				<Button
					fullWidth={isMobile}
					variant={isMobile? 'outlined' : 'text'}
					onClick={onClose}
					color={'inherit'}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}
