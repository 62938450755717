import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useParams, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import dayjs from 'dayjs'
import {useDispatch, useSelector} from 'react-redux'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import {styled, useTheme} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import {cancelPaymentRecurring} from '../../lib/request'

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'

import DeleteConfirmationDialog from './delete-confirmation-dialog'
import {viewPayment, deletePaymentCoupon, revalidateWithRazer, refundPayment} from '../../lib/request'
import PanelHead from '../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import useMediaQuery from '@mui/material/useMediaQuery'
import CancelRecurringDialog from './cancel-recurring-dialog'

const StyleDiv = styled(Box)``

export default function PaymentDetail() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {id} = useParams()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {me} = useSelector(state => state.profile)

	const [detail, setDetail] = useState({})
	const [errMsg, setErrMsg] = useState('')
	const [toBeDelete, setToBeDelete] = useState(false)
	const [cancelRecurring, setCancelRecurring] = useState(false)

	useEffect(() => {

		if (!isNaN(id)) {
			dispatch(setLoading(true))
			viewPayment(id)
				.then((res) => {
					setDetail(res.data)
				})
				.catch(err => {
					setErrMsg(err.response.data.message)
				})
				.finally(() => {
					dispatch(setLoading(false))
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function confirmDelete() {
		dispatch(setLoading(true))

		deletePaymentCoupon(detail.Coupon.id)
			.then(() => dispatch(setSnackbarMsg('Coupon deleted')))
			.catch(err => console.error(err.toString()))
			.finally(() => {
				dispatch(setLoading(false))
				setToBeDelete(false)
				navigate(0)
			})
	}

	function confirmCancelRecurring() {
		dispatch(setLoading(true))
		cancelPaymentRecurring(id)
			.then(() => {
				dispatch(setSnackbarMsg('Cancel Recurring Successful'))
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => {
				dispatch(setLoading(false))
				navigate(0)
			})
	}

	function checkWithRazer () {
		dispatch(setLoading(true))
		revalidateWithRazer(id)
			.then(response => dispatch(setSnackbarMsg(response.data.message)))
			.then(() => viewPayment(id))
			.then(res => setDetail(res.data))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function refund () {
		dispatch(setLoading(true))
		refundPayment(id)
			.then(response => dispatch(setSnackbarMsg(response.data.message)))
			.then(() => viewPayment(id))
			.then(res => setDetail(res.data))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}
	
	// console.log(detail.Meta?.isRefunded)
	// console.log(detail.Meta?.isReverted)


	return (
		<>
			{
				!_.isEmpty(detail) && (
					<>
						<StyleDiv>
							<PanelHead title={`Payment Detail`}>
								<Link to={'/payment'}>
									<Button
										fullWidth={isMobile}
										color={'inherit'}
										sx={{mr: 2}}
										variant={isMobile ? 'outlined' : 'text'}>
										Back
									</Button>
								</Link>
								{
									!_.isEmpty(me) && me.permission.includes('U') && !_.isEmpty(detail.Meta?.token) && detail.isRecurring === false && (
										<Button
											color={'error'}
											variant="outlined" sx={{mr: 2}} onClick={() => setCancelRecurring(true)}>
											Cancel Recurring
										</Button>
									)
								}
								{
									!_.isEmpty(me) && me.permission.includes('U') && !detail.isIAP && !_.isEmpty(detail.transactionId) && detail.Meta?.isRefunded === false && (
										<Button
											color={'error'}
											variant={'contained'} sx={{mr: 2}} onClick={refund}>
											Refund
										</Button>
									)
								}
								{
									!_.isEmpty(me) && me.permission.includes('U') && !detail.isIAP && !_.isEmpty(detail.transactionId) && detail.Meta?.isReverted === false && (
										<Button
											color={'warning'}
											variant={'contained'} sx={{mr: 2}} onClick={checkWithRazer}>
											Revalidate
										</Button>
									)
								}
							</PanelHead>
							<Box sx={{p: 2}}>
								{
									!_.isEmpty(detail) && (
										<Box>
											{
												detail.isRecurring && (
													<Alert severity={'info'} sx={{mb: 2}}>
														Payment is created by recurring scheduler
													</Alert>
												)
											}
											<Grid container spacing={2}>
												<Grid xs={12} md={6}>
													<Card>
														<CardHeader title={'Payment Info'}/>
														<CardContent>
															<TextField
																fullWidth
																margin="normal"
																label="Order ID"
																value={_.isEmpty(detail.orderId)
																	? ' '
																	: detail.orderId}
																InputLabelProps={{
																	shrink: true,
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Transaction ID"
																value={_.isEmpty(detail.transactionId)
																	? ' '
																	: detail.transactionId}
																InputLabelProps={{
																	shrink: true,
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Amount"
																value={isNaN(detail.amount)
																	? ' '
																	: `RM${detail.amount.toFixed(2)}`}
																InputLabelProps={{
																	shrink: true,
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Status"
																value={_.isEmpty(detail.status)
																	? ' '
																	: detail.status}
																InputLabelProps={{
																	shrink: true,
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Channel"
																defaultValue={detail.Meta?.channel}
																InputProps={{readOnly: true}}
																InputLabelProps={{shrink: true}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Pay Date"
																value={_.isNull(detail.payDate)
																	? ' '
																	: dayjs(detail.payDate).format('DD MMM YYYY h:mm A')}
																InputLabelProps={{
																	shrink: true,
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Plan name"
																value={_.isEmpty(detail.Plan.name)
																	? ' '
																	: detail.Plan.name}
																InputLabelProps={{
																	shrink: true,
																}}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<Link to={`/subscription/${detail.plansId}`}>
																				<OpenInNewOutlinedIcon/>
																			</Link>
																		</InputAdornment>
																	),
																}}
															/>
														</CardContent>
													</Card>

												</Grid>
												<Grid xs={12} md={6}>
													<Card>
														<CardHeader title={'Profile Info'}/>
														<CardContent>
															<TextField
																fullWidth
																margin="normal"
																label="Email"
																defaultValue={detail.Profile.Account.email}
																InputLabelProps={{shrink: true}}
																InputProps={{
																	readOnly: true,
																	endAdornment: (
																		<InputAdornment position="end">
																			<Link to={`/profiles/${detail.Profile.Account.id}?b=${detail.Plan.Brands.id}`}>
																				<OpenInNewOutlinedIcon/>
																			</Link>
																		</InputAdornment>
																	)
																}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Full Name"
																defaultValue={detail.Profile.name || ''}
																InputProps={{readOnly: true}}
																InputLabelProps={{shrink: true}}/>
															<TextField
																fullWidth
																margin="normal"
																label="Phone"
																defaultValue={detail.Profile.Account.phone || ''}
																InputProps={{readOnly: true}}
																InputLabelProps={{shrink: true}}/>
														</CardContent>
													</Card>
													<Card sx={{mt: 2}}>
														<CardHeader title={'Coupon Info'}/>
														<CardContent>
															{
																!_.isEmpty(detail.Coupon) ? (
																	<>
																		<TextField
																			fullWidth
																			margin="normal"
																			label="Code"
																			defaultValue={detail.Coupon.Couponcode.code}
																			InputProps={{readOnly: true}}
																			InputLabelProps={{shrink: true}}/>
																		<TextField
																			fullWidth
																			margin="normal"
																			label="Name"
																			defaultValue={detail.Coupon.Couponcode.Coupons.name}
																			InputLabelProps={{shrink: true}}
																			InputProps={{
																				readOnly: true,
																				endAdornment: (
																					<InputAdornment position="end">
																						<Link to={`/coupon/${detail.Coupon.Couponcode.Coupons.id}`}>
																							<OpenInNewOutlinedIcon/>
																						</Link>
																					</InputAdornment>
																				)
																			}}/>
																		<Box sx={{mt: 2}} align="right">
																			<Button color={'error'} variant="outlined" sx={{mr: 2}}
																							onClick={() => setToBeDelete(true)} disabled={detail.status === '00'}>
																				Delete
																			</Button>
																		</Box>

																	</>
																) : (
																	<Alert severity={'info'}>
																		Payment not using any coupon
																	</Alert>
																)
															}
														</CardContent>
													</Card>
												</Grid>
											</Grid>
											<Box>
												<Card>
													<CardHeader title={'Payment meta'}/>
													<CardContent>
														<TextField
															fullWidth
															margin="normal"
															label="Token"
															defaultValue={detail.Meta?.token || ''}
															InputProps={{readOnly: true}}
															InputLabelProps={{shrink: true}}/>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="CC Type"
																	defaultValue={detail.Meta?.ccType || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="CC Brand"
																	defaultValue={detail.Meta?.ccBrand || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="CC Last 4 digits"
																	defaultValue={detail.Meta?.ccLast4 || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Channel"
																	defaultValue={detail.Meta?.channel || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="FPX Buyer Name"
																	defaultValue={detail.Meta?.fpxBuyerName || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="FPX Txn ID"
																	defaultValue={detail.Meta?.fpxTxnId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="App code"
																	defaultValue={detail.Meta?.appcode || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Auto renewing"
																	defaultValue={detail.Meta?.autoRenewing ? true : false}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<TextField
															fullWidth
															margin="normal"
															label="Description"
															defaultValue={detail.Meta?.description || ''}
															InputProps={{readOnly: true}}
															InputLabelProps={{shrink: true}}/>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Bank Issuer"
																	defaultValue={detail.bankIssuer}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Transaction type"
																	defaultValue={detail.Meta?.txntype || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<TextField
															fullWidth
															margin="normal"
															label="Avs response"
															defaultValue={detail.Meta?.avsResponse || ''}
															InputProps={{readOnly: true}}
															InputLabelProps={{shrink: true}}/>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Avs street"
																	defaultValue={detail.Meta?.avsStreet || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Avs zip"
																	defaultValue={detail.Meta?.avsZip || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Captured date"
																	defaultValue={detail.Meta?.capturedDate || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="eci"
																	defaultValue={detail.Meta?.eci || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="dbtracct type"
																	defaultValue={detail.Meta?.dbtracct_type || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="dbtragt"
																	defaultValue={detail.Meta?.dbtragt || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Device Id"
																	defaultValue={detail.Meta?.deviceId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="DuitNow Id"
																	defaultValue={detail.Meta?.duitNowId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Device Id"
																	defaultValue={detail.Meta?.deviceId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Fraud screen"
																	defaultValue={detail.Meta?.fraudScreen || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Grab Pay Ind"
																	defaultValue={detail.Meta?.grabPayInd || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Original transaction id"
																	defaultValue={detail.Meta?.originalTransactionId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Processor CCV response"
																	defaultValue={detail.Meta?.processorCCVResponse || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Processor response code"
																	defaultValue={detail.Meta?.processorResponseCode || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<TextField
															fullWidth
															margin="normal"
															label="Purchase token"
															defaultValue={detail.Meta?.purchaseToken || ''}
															InputProps={{readOnly: true}}
															InputLabelProps={{shrink: true}}/>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Schema transaction id"
																	defaultValue={detail.Meta?.schemeTransactionID || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Signature"
																	defaultValue={detail.Meta?.signature || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Store address"
																	defaultValue={detail.Meta?.storeAddress || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Store front"
																	defaultValue={detail.Meta?.storeFront || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>

														<Grid container spacing={2}>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Store id"
																	defaultValue={detail.Meta?.storeId || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12} md={6}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Store name"
																	defaultValue={detail.Meta?.storeName || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
															<Grid xs={12}>
																<TextField
																	fullWidth
																	margin="normal"
																	label="Recurring body"
																	defaultValue={detail.Meta?.recurringFormBody || ''}
																	InputProps={{readOnly: true}}
																	InputLabelProps={{shrink: true}}/>
															</Grid>
														</Grid>


													</CardContent>
												</Card>
											</Box>
										</Box>
									)
								}
								{
									!_.isEmpty(errMsg) && (
										<Alert severity="error">
											<AlertTitle>{errMsg}</AlertTitle>
										</Alert>
									)
								}
							</Box>
							{
								toBeDelete && (
									<DeleteConfirmationDialog
										onCancel={() => setToBeDelete(false)}
										onConfirm={confirmDelete}
									/>
								)
							}
							{
								cancelRecurring && (
									<CancelRecurringDialog
										onCancel={() => setCancelRecurring(false)}
										onConfirm={confirmCancelRecurring}
									/>
								)
							}
						</StyleDiv>
					</>
				)
			}
		</>

	)
}
