import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        coupons: [],
    },
    reducers: {
        setCoupons: (state, action) => {
            state.coupons = _.isArray(action.payload) ? _.orderBy(action.payload,['id'], ['asc']) : []
        },
        createCoupon: (state, action) => {
            state.coupons.push(action.payload)
        },
        updateCoupon: (state, action) => {
            const index = _.findIndex(state.coupons, {id: action.payload.id})
            if(index !== -1) {
                state.coupons[index] = action.payload
            }
        },
        removeCoupon: (state, action) => {
            state.coupons = state.coupons.filter(coupon => coupon.id !== action.payload.id)
        }
    }
})

export const {setCoupons,createCoupon,updateCoupon, removeCoupon}  = couponSlice.actions

export default couponSlice.reducer
