import React, {useEffect, useRef} from 'react'

import * as echarts from 'echarts'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    #barRaceChart {
    	margin-left: ${theme.spacing(2)};
    	width: 600px;
    	height: 600px;
    }
  `}
`

let init = false

export default function BarRaceSiteOrigin(props) {
	const {update, setUpdate, data} = props

	const chart = useRef()

	useEffect(() => {

		if (init === false) {
			init = true

			const chartDom = document.getElementById('barRaceChart')
			if (chartDom) {
				if (chart.current) {
					chart.current.dispose()
				}

				chart.current = echarts.init(chartDom, null, {renderer: 'svg'})
				chart.current.setOption({
					title: {
						text: 'Number of hits',
						subtext: 'Number of hits to the domain',
						left: 'center'
					},
					xAxis: {
						max: 'dataMax'
					},
					yAxis: {
						type: 'category',
						data: [],
						inverse: true,
						animationDuration: 300,
						animationDurationUpdate: 300,
						max: 2 // only the largest 3 bars will be displayed
					},
					series: [
						// {
						// 	realtimeSort: true,
						// 	name: 'X',
						// 	type: 'bar',
						// 	data: data,
						// 	label: {
						// 		show: true,
						// 		position: 'right',
						// 		valueAnimation: true
						// 	}
						// }
					],
					legend: {
						show: true
					},
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				})

				return () => {
					console.log('Disponse?')
				}
			}

		}

	}, [])

	useEffect(() => {

		if (chart.current) {
			console.log(data)
			
			// chart.current.setOption({
			// 	series: [{
			// 		name: 'Site',
			// 		data: data
			// 	}]
			// })
		}

		setUpdate(false)
	}, [update])
	
	console.log('Current', chart.current?.series)

	return (
		<StyledDiv>
			<div id={'barRaceChart'}/>
		</StyledDiv>
	)
}
