import React from 'react'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

export default function NotFound(props) {

	return (
		<Alert sx={{m: 3}} severity={'error'}>
			<AlertTitle>Error 404</AlertTitle>
			The page you request is not found
		</Alert>
	)
}
