import React, {useEffect, useState, useRef} from 'react'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'


import PanelHead from '../../components/panel-head'
import SitesPie from './sites-pie'
import BarRaceSiteOrigin from './bar-race-site-origin'
import TableStreaming from './table-streaming'


const StyledDiv = styled(Box)`

`

let init = false

export default function PaymentMethod() {

	const ws = useRef()
	const wsInterval = useRef(null)
	const chartUpdateInterval = useRef(null)
	const streamRef = useRef([])

	const [streams, setStreams] = useState([])
	const [websocketOpen, setWebsocketOpen] = useState(false)
	const [update, setUpdate] = useState(false)

	useEffect(() => {

		if (init === false) {
			init = true
		}

	}, [])

	function initialize() {

		if (ws && ws.current && ws.current.readyState === 1) {
			if (wsInterval.current) {
				clearInterval(wsInterval.current)
				wsInterval.current = null
			}
			if (chartUpdateInterval.current) {
				clearInterval(chartUpdateInterval.current)
				chartUpdateInterval.current = null
			}
			return ws.current.close()
		}

		if (_.isNull(ws) || ws.readyState !== 1) {
			ws.current = new WebSocket('wss://hb.revid.my/ws')
		}

		ws.current.onopen = () => {
			setWebsocketOpen(true)
			console.log('Websocket open')
			wsInterval.current = setInterval(() => {
				ws.current.send('Hi')
			}, 60000)  // Every 1 minute
			chartUpdateInterval.current = setInterval(() => {
				// setStreams(streamRef.current)
				setUpdate(true)
			}, 1000)
		}

		ws.current.onclose = () => {
			if (websocketOpen) {
				// Websocket close prematurely
				ws.current = new WebSocket('wss://hb.revid.my/ws')
				return
			}

			setWebsocketOpen(false)
			console.log('Websocket close')
			if (wsInterval.current) {
				clearInterval(wsInterval.current)
				wsInterval.current = null
			}
			if (chartUpdateInterval.current) {
				clearInterval(chartUpdateInterval.current)
				chartUpdateInterval.current = null
			}
		}

		ws.current.onmessage = event => {
			try {
				const msg = JSON.parse(event.data)
				const streamRefClone = JSON.parse(JSON.stringify(streamRef.current))

				streamRefClone.unshift(msg)
				if (streamRefClone.length > 10) {
					streamRefClone.pop()
				}

				streamRef.current = streamRefClone

				setStreams(streamRef.current)

			} catch (e) {

			}
		}
	}

	return (
		<StyledDiv>
			<PanelHead title={'REV.ID'}>
				<Button
					variant={'outlined'}
					color={websocketOpen ? 'error' : 'primary'}
					onClick={initialize}>
					{websocketOpen ? 'Close' : 'Open'}
				</Button>
			</PanelHead>

			<Grid container spacing={2}>
				<Grid xs={12} md={4}>
					<SitesPie update={update} setUpdate={setUpdate} streams={streams}/>
				</Grid>
			</Grid>

			{/*<TableStreaming streams={streams} />*/}

		</StyledDiv>
	)

}