import React from 'react'

import {styled, useTheme} from '@mui/material/styles'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledDialog = styled(Dialog)`
  ${({theme}) => `
    
  `}
`

export default function DeleteConfirmationDialog(props) {

	const {onCancel, onConfirm} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<StyledDialog open onClose={onCancel}>
			<DialogTitle>Are you confirm to delete this coupon</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure to delete this payment information?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'outlined'}
					onClick={onCancel}
					fullWidth={isMobile}>
					Cancel
				</Button>
				<Button
					color={'error'}
					variant={'outlined'}
					onClick={onConfirm}
					fullWidth={isMobile}>
					{isMobile? 'Delete' : 'Confirm delete'}
				</Button>
			</DialogActions>
		</StyledDialog>
	)
}
