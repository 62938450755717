import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscriptions: [],
        dropdown: [],
        page: 0,
        rowsPerPage: 50,
        selectedBrand: null
    },
    reducers: {
        setSubscriptions: (state, action) => {
            state.subscriptions = _.isArray(action.payload) ? _.orderBy(action.payload, ['id'], ['asc']) : []
        },
        createSubscription: (state, action) => {
            state.subscriptions.push(action.payload)
        },
        updateSubscription: (state, action) => {
            const index = _.findIndex(state.subscriptions, {id: action.payload.id})
            if(index !== -1) {
                state.subscriptions[index] = action.payload
            }
        },
        removeSubscription: (state, action) => {
            state.subscriptions = state.subscriptions.filter(subscription => subscription.id !== action.payload.id)
        },
        updateSubscriptionDropdown: (state, action) => {
            state.dropdown = []
            action.payload.forEach((s) => {
                state.dropdown.push(_.pick(s, ['id','name']))
            })
        },
        storePage: (state, action) => {
            state.page = action.payload
        },
        storeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
        },
        storeBrandId: (state, action) => {
            state.selectedBrand = action.payload
        }
    }
})

export const {setSubscriptions, updateSubscription, createSubscription, removeSubscription, updateSubscriptionDropdown, storePage, storeRowsPerPage, storeBrandId} = subscriptionSlice.actions

export default subscriptionSlice.reducer
