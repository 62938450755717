import React, { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setLoading } from "../../store/reducers/misc";
import { getAdditionalPackages } from "../../lib/request";
import _ from "lodash";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const StyledDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
  `

export default function Tab2() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { brandInFocus } = useSelector(state => state.profile)

    const [additionalPackages, setAdditionalPackages] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(null)
    const [inLoad, setInLoad] = useState(true)

    useEffect(() => {
        fetchAdditionalPackages()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (init === true) {
            fetchAdditionalPackages()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page])

    function fetchAdditionalPackages() {
        const q = {
            limit: _.clone(rowsPerPage),
            offset: rowsPerPage * page,
            brandId: brandInFocus
        }

        dispatch(setLoading(true))
        getAdditionalPackages(q)
            .then((res) => {
                setAdditionalPackages(res.data.rows)
                setTotal(res.data.count)
                setInLoad(false)
            })
            .catch(err => console.log(err.response.data.message))
            .finally(() => {
                setInit(true)
                dispatch(setLoading(false))
            })
    }

    function handleChangePage(e, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(e) {
        setRowsPerPage(parseInt(e.target.value, 10))
        setPage(0)
    }



    return (
        <StyledDiv>
            <Box sx={{ p: 2 }}>
                {
                    inLoad ? (
                        <Alert severity={'info'}>Please wait. Fetching additional packages </Alert>
                    ) : (_.isEmpty(additionalPackages) ? (
                        <Alert severity={'info'}>No additional packages available </Alert>
                    ) : (
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead className="tblHead">
                                        <TableRow>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">System Name</TableCell>
                                            <TableCell align="left">Price</TableCell>
                                            <TableCell align="left">Active</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            additionalPackages.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        className={'tablerow'}
                                                        onClick={() => navigate(`/subscription/additional-package/${row.id}`)}
                                                        key={index}>
                                                        <TableCell>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.systemName}
                                                        </TableCell>
                                                        <TableCell>
                                                            RM{row.price ? row.price.toFixed(2) : 0}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.isActive ? 'Yes' : 'No'}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={!total || total <= 0 ? 0 : page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />

                        </Box>
                    )
                    )
                }
            </Box>
        </StyledDiv>
    )
}
