import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import EchartsPie from './echarts-pie'
import BarRaceSiteOrigin from './bar-race-site-origin'

const StyledDiv = styled(Box)`
  ${({theme}) => `
  `}
`

export default function SitesPie(props) {

	const {streams, update, setUpdate} = props

	const data = useRef([])

	useEffect(() => {

		if (!_.isEmpty(streams)) {
			process(streams)
		}

	}, [streams])

	function process(streams) {

		const last = streams[streams.length - 1]
		let domain = last.url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/)
		if (!_.isNull(domain)) {
			domain = domain[1]

			if (/^api\./.test(domain)) {
				return null
			}

			const index = _.findIndex(data.current, {label: domain})
			if (index === -1) {
				data.current.push({
					id: domain,
					label: domain,
					name: domain,
					color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
					value: 1
				})
			} else {
				data.current[index].value += 1
			}
		}
	}

	return (
		<StyledDiv>
			<EchartsPie update={update} setUpdate={setUpdate} data={data.current} />

			<BarRaceSiteOrigin update={update} setUpdate={setUpdate} data={data.current} />
		</StyledDiv>
	)
}
