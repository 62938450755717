import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {setLoading} from "../../store/reducers/misc";
import {getPaymentToday} from "../../lib/request";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import dayjs from "dayjs";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function PaymentTodayStats() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useDispatch()
    const brandInFocus = useSelector(state => state.profile.brandInFocus)

    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState({brand: '', today: ''})
    const today = dayjs().format('YYYY-MM-DD')
    const [result, setResult] = useState([])

    useEffect(() => {
        setSelected({brand: brandInFocus, today})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandInFocus])

    useEffect(() => {
        const {brand, today} = selected

        if (brand && today) {
            setIsLoading(true)
            dispatch(setLoading(true))

            const q = { brand, today}

            getPaymentToday(q)
                .then((res) => {
                    setResult(res.data)
                })
                .catch(err => {
                    console.log(err.response.data.message)
                })
                .finally(() => {
                    setIsLoading(false)
                    dispatch(setLoading(false))
                })
        }

    },[selected, dispatch])


    return (
        <Box sx={{mx: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{mb: 2}}>
                    <Card sx={{backgroundColor: "#03fcbe"}} variant="outlined">
                        <CardContent>
                            <Typography component="h1" variant="h6">
                                Successful payment today
                            </Typography>
                        </CardContent>
                        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                            <AttachMoneyIcon style={{ fontSize: '2rem', marginRight: '1rem' }} />
                            {
                                _.isEmpty(result) ? (
                                  <div>
                                      <Typography>Total Captured: 0</Typography>
                                      <Typography>Total Amount: 0</Typography>
                                  </div>
                                ) : (
                                  <div>
                                      <Typography>Total captured: {result[0].count}</Typography>
                                      <Typography>Total amount: RM{result[0].totalAmount}</Typography>
                                  </div>
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
