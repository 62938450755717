import React from 'react'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

export default function DialogConfirmDeleteUser(props) {

	const {onClose, onConfirm} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog  open fullScreen={isMobile} onClose={onClose}>
			<DialogTitle>Confirm to delete user profile</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you confirm to delete this user profile
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant={'contained'} color={'error'} onClick={onConfirm} fullWidth={isMobile}>Yes, Confirm</Button>
				<Button variant={'outlined'} color={'inherit'} onClick={onClose} fullWidth={isMobile}>Cancel</Button>
			</DialogActions>
		</Dialog>
	)
}
