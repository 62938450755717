import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import copy from 'copy-to-clipboard'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteIcon from '@mui/icons-material/Delete'

import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {addCredential, editCredential, getCredentials} from '../../../lib/request'
import {setCredentials} from '../../../store/reducers/credentials'
import InputAdornment from '@mui/material/InputAdornment'

export default function CredentialDialog(props) {

	const {credential, onClose} = props

	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [form, setForm] = useState({
		name: credential.name,
		nameErr: null,
		redirect_uri: '',
		redirectUriErr: null,
		sso_key: credential.application.apiKey,
		t_name: credential.t_name,
		ga: credential.ga_id,
		payPrefix: credential.pay_prefix,
		dc_url: credential.dc_url,
	})
	const [redirectUris, setRedirectUris] = useState([])

	useEffect(() => {
		const rr = []
		credential.redirect_uri.split(',').forEach(r => {
			if (!_.isEmpty(_.trim(r))) {
				rr.push(r)
			}
		})
		setRedirectUris(rr)

	}, [credential.redirect_uri])

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)

		const cleanRR = []
		redirectUris.forEach(rr => {
			if (!_.isEmpty(_.trim(rr))) {
				cleanRR.push(rr)
			}
		})
		formClone.redirect_uri = cleanRR.join(',')

		formClone.nameErr = null
		formClone.redirectUriErr = null
		formClone.ssoKeyErr = null

		let err = false
		if (_.isEmpty(_.trim(formClone.name))) {
			formClone.nameErr = 'Application name is required'
			err = true
		}
		if (_.isEmpty(_.trim(formClone.redirect_uri))) {
			formClone.redirectUriErr = 'At least one redirect URI is required'
			err = true
		}

		setForm(formClone)
		if (err) {
			return null
		}

		const formClone2 = _.cloneDeep(formClone)

		dispatch(setLoading(true))

		delete formClone2.redirectUriErr
		delete formClone2.nameErr

		if (!_.isEmpty(credential.client_id)) {
			if (_.isEmpty(_.trim(formClone2.t_name))) {
				formClone2.t_name = 'null'
			}
			if (_.isEmpty(_.trim(formClone2.dc_url))) {
				formClone2.dc_url = 'null'
			}
			if (_.isEmpty(_.trim(formClone2.payPrefix))) {
				formClone2.payPrefix = 'null'
			}
			if (_.isEmpty(_.trim(formClone2.ga))) {
				formClone2.ga = 'null'
			}

			formClone2.client_id = credential.client_id
			formClone2.task = 'reset'

			editCredential(formClone2)
				.then(() => getCredentials())
				.then(res => dispatch(setCredentials(res.data.credentials)))
				.then(() => dispatch(setSnackbarMsg('Credentials updated')))
				.then(() => onClose())
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.msg))
					}
				})
				.finally(() => dispatch(setLoading(false)))
		} else {
			addCredential(formClone2)
				.then(() => getCredentials())
				.then(res => dispatch(setCredentials(res.data.credentials)))
				.then(() => dispatch(setSnackbarMsg('Credentials created')))
				.then(() => onClose())
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.msg))
					}
				})
				.finally(() => dispatch(setLoading(false)))
		}

	}

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)

		formClone[name] = value
		formClone.nameErr = null
		formClone.redirectUriErr = null

		setForm(formClone)
	}

	function addRedirectUri() {
		const list = _.cloneDeep(redirectUris)
		list.push('')
		setRedirectUris(list)
	}

	function removeRedirectUris(index) {
		const list = _.cloneDeep(redirectUris)
		list.splice(index, 1)
		setRedirectUris(list)
	}

	function changeRedirectUri(e, index) {
		const {value} = e.target
		const list = _.cloneDeep(redirectUris)
		list[index] = value
		setRedirectUris(list)
	}

	function copyTxt(e, text, msg) {
		e.preventDefault()
		e.stopPropagation()

		copy(text)
		dispatch(setSnackbarMsg(!_.isEmpty(_.trim(msg)) ? msg : `text copied`))
	}

	return (
		<Dialog open fullWidth fullScreen={isMobile} onClose={onClose}>
			<DialogTitle>
				{
					_.isEmpty(_.trim(credential.client_id)) ? 'Create new' : `Edit`
				} credential
			</DialogTitle>
			<DialogContent>
				{
					_.isEmpty(_.trim(credential.client_id)) && (
						<Alert severity={'info'} variant={'outlined'} sx={{mb: 2}}>
							Create a new OAuth credential for application label {credential.application.label}.
							Client ID and Client Secret will be given after creation
						</Alert>
					)
				}

				<Box component={'form'} onSubmit={submit}>
					<Button type={'submit'} sx={{display: 'none'}}></Button>

					<Grid container spacing={2}>
						{
							!_.isEmpty(_.trim(credential.client_id)) && (
								<>
									<Grid xs={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Client Id"
											defaultValue={credential.client_id}
											InputLabelProps={{shrink: true}}
											InputProps={{
												readOnly: true,
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={(e) => copyTxt(e, credential.client_id, 'Client ID copied')}
															edge="end">
															<ContentCopyOutlinedIcon/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</Grid>
									<Grid xs={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Client Secret"
											defaultValue={credential.client_secret}
											InputLabelProps={{shrink: true}}
											InputProps={{
												readOnly: true,
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															onClick={(e) => copyTxt(e, credential.client_secret, 'Client secret copied')}
															edge="end">
															<ContentCopyOutlinedIcon/>
														</IconButton>
													</InputAdornment>
												)
											}} />
									</Grid>
								</>
							)
						}
						<Grid xs={6}>
							<TextField
								required
								fullWidth
								type="text"
								margin="normal"
								label="Application Name"
								name="name"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.nameErr)}
								helperText={form.nameErr}
								value={form.name}
								onChange={changeForm}/>
						</Grid>
						<Grid xs={6}>
							<TextField
								fullWidth
								margin={'normal'}
								label="t_name"
								variant="outlined"
								value={form.t_name}
								name={'t_name'}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								fullWidth
								margin={'normal'}
								label="Direct checkout Url"
								variant="outlined"
								value={form.dc_url}
								name={'dc_url'}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}/>
						</Grid>
						<Grid xs={6}>
							<TextField
								fullWidth
								margin={'normal'}
								label="ga id"
								variant="outlined"
								value={form.ga}
								name={'ga'}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid xs={12} md={10}>
							<Typography variant={'h6'}>
								Redirect URI
							</Typography>
						</Grid>
						<Grid xs={12} md={2} sx={{textAlign: 'right'}}>
							<IconButton variant="outlined" onClick={addRedirectUri}>
								<AddCircleOutlineOutlinedIcon color={'primary'}/>
							</IconButton>
						</Grid>
					</Grid>

					{
						!_.isEmpty(_.trim(form.redirectUriErr)) && <Alert severity={'error'} sx={{mt: 1}}>{form.redirectUriErr}</Alert>
					}

					<List>
						{
							redirectUris.map((value, index) => {
								return (
									<ListItem
										disableGutters
										key={index}
										secondaryAction={
											redirectUris.length !== 1 &&
											<IconButton edge="end" aria-label="delete" onClick={() => removeRedirectUris(index)}>
												<DeleteIcon/>
											</IconButton>
										}>
										<TextField
											fullWidth
											required
											margin={'normal'}
											label="Redirect Uri"
											variant="outlined"
											value={value}
											InputLabelProps={{shrink: true}}
											onChange={(e) => changeRedirectUri(e, index)}/>
									</ListItem>
								)
							})
						}
					</List>
				</Box>

			</DialogContent>
			<DialogActions>
				<Button
					variant={isMobile ? 'outlined' : 'text'}
					color={'inherit'}
					fullWidth={isMobile}
					onClick={onClose}>
					Cancel
				</Button>
				<Button fullWidth={isMobile} variant="outlined" onClick={submit}>Save</Button>
			</DialogActions>
		</Dialog>
	)
}
