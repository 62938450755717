import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        payments: [],
        page: 0,
        rowsPerPage: 50,
    },
    reducers: {
        setPayments: (state, action) => {
            state.payments = _.isArray(action.payload) ?  action.payload : []
        },
        storePage: (state, action) => {
            state.page = action.payload
        },
        storeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
        }
    }
})

export const {setPayments, storePage, storeRowsPerPage} = paymentSlice.actions

export default paymentSlice.reducer
