import _ from 'lodash'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

const StyledDiv = styled(Box)`
`

export default function Search(props) {

	const {search, setSearch, beginSearch} = props
	const {me} = useSelector(state => state.profile)

	function changeForm(e) {
		const {name, value} = e.target
		const searchClone = _.clone(search)
		searchClone[name] = name === 'code' ? value.toUpperCase() : value
		setSearch(searchClone)
	}

	// me.permission = []

	return (
		<StyledDiv sx={{pl: 2, pr: 2, pb: 2}}>
			<form onSubmit={beginSearch}>
				<Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
					<Grid xs={12} md={(!_.isEmpty(me) && me.permission.includes('C')) ? 3 : 7} lg={(!_.isEmpty(me) && me.permission.includes('C')) ? 5 : 7}>
						<TextField
							fullWidth
							variant={'outlined'}
							size={'small'}
							label={'Coupon group name'}
							name={'name'}
							value={search.name}
							onChange={changeForm}
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid xs={12} md={3} lg={2}>
						<TextField
							fullWidth
							variant={'outlined'}
							size={'small'}
							label={'Coupon Code'}
							name={'code'}
							value={search.code}
							onChange={changeForm}
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid xs={12} md={2} lg={2}>
						<TextField
							fullWidth
							select
							label={'Show all coupon groups'}
							variant={'outlined'}
							size={'small'}
							name={'showAll'}
							value={search.showAll}
							onChange={changeForm}
							InputLabelProps={{shrink: true}}
						>
							<MenuItem value={false}>Only not too many</MenuItem>
							<MenuItem value={true}>All</MenuItem>
						</TextField>
					</Grid>
					<Grid xs={12} md={2} lg={1}>
						<Button
							type={'submit'}
							fullWidth
							onClick={beginSearch}
							variant={'outlined'}>
							Search
						</Button>
					</Grid>
					{
						!_.isEmpty(me) && (me.permission.includes('C')) && (
							<Grid xs={12} md={2} lg={2}>
								<Link to={'/coupon/create'}>
									<Button variant="outlined" fullWidth>
										New Coupon
									</Button>
								</Link>
							</Grid>
						)
					}
				</Grid>
			</form>
		</StyledDiv>
	)
}