import {configureStore} from '@reduxjs/toolkit'
import profileReducer from './reducers/profile'
import miscReducer from './reducers/misc'
import networkReducer from './reducers/network'
import brandReducer from './reducers/brand'
import subscriptionReducer from './reducers/subscription'
import applicationReducer from './reducers/application'
import couponReducer from './reducers/coupon'
import paymentReducer from './reducers/payment'
import credentialReducer from './reducers/credentials'
import eventReducer from './reducers/event'
import paymentMethodReducer from './reducers/paymentMethod'

export default configureStore({
    reducer: {
        profile: profileReducer,
        misc: miscReducer,
        network: networkReducer,
        brand: brandReducer,
        subscription: subscriptionReducer,
        application: applicationReducer,
        coupon: couponReducer,
        payment: paymentReducer,
        credential: credentialReducer,
        event: eventReducer,
        paymentMethod: paymentMethodReducer
    }
})
