import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'

import PanelHead from '../../components/panel-head'
import Tab1 from "./tab1"
import Tab2 from "./tab2"
import Tab3 from "./tab3"
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";



const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
`

export default function Subscription() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {me} = useSelector(state => state.profile)

	const [tabIndex, setTabIndex] = useState(0)

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const tab = urlParams.get('tab')
		if(!_.isEmpty(tab)){
			setTabIndex(parseInt(tab))
		}
	}, [])

	return (
		<StyleDiv>
			<PanelHead title={'Subscriptions plan'}>
				{
					!_.isEmpty(me) && (me.permission.includes('C')) &&  tabIndex === 0 && (
						<Link to={'/subscription/create'}>
							<Button fullWidth={isMobile} variant="outlined">New Plan</Button>
						</Link>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C')) &&  tabIndex === 1 && (
						<Link to={'/subscription/additional-package/create'}>
							<Button fullWidth={isMobile} variant="outlined">New Additional Package</Button>
						</Link>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C')) &&  tabIndex === 2 && (
						<Link to={'/subscription/campaign/create'}>
							<Button fullWidth={isMobile} variant="outlined">New Campaign</Button>
						</Link>
					)
				}
			</PanelHead>
			<Box>
				<Paper square variant={'outlined'}>
					<Tabs
						value={tabIndex}
						onChange={(e, v) => setTabIndex(v)}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example">
						<Tab label="Plans"/>
						<Tab label="Additional Packages"/>
						<Tab label="Campaign"/>
					</Tabs>
				</Paper>
				<Box role={'tabpanel'} hidden={tabIndex !== 0}>
					{ tabIndex === 0 && <Tab1/> }
				</Box>
				<Box role={'tabpanel'} hidden={tabIndex !== 1}>
					{ tabIndex === 1 && <Tab2/> }
				</Box>
				<Box role={'tabpanel'} hidden={tabIndex !== 2}>
					{ tabIndex === 2 && <Tab3/> }
				</Box>
			</Box>
		</StyleDiv>
	)
}
