import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const networkSlice = createSlice({
    name: 'network',
    initialState: {
        networks: [],
    },
    reducers: {
        setNetworks: (state, action) => {
            state.networks = _.isArray(action.payload) ? action.payload : []
        }
    }
})

export const {setNetworks} = networkSlice.actions

export default networkSlice.reducer
