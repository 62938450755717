import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {Line} from 'react-chartjs-2'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

import {getProfilesByMonth} from '../../lib/request'
import {setLoading} from '../../store/reducers/misc'

export default function UserLoggedStats() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useDispatch()
    const brandInFocus = useSelector(state => state.profile.brandInFocus)

    const [dataset, setDataset] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState({brand: '', year: ''})

    const currentYear = new Date().getFullYear()

    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
        years.push(year);
    }

    const options = {
        responsive: true,
        plugins: {
            legend: false
        }
    }

    const labels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

    useEffect(() => {
        setSelected({brand: brandInFocus, year: currentYear})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandInFocus])

    useEffect(() => {

        const {brand, year} = selected

        if (brand && year) {
            fetchProfile(brand, year)
        }

        async function fetchProfile(brand, year) {

            setIsLoading(true)
            dispatch(setLoading(true))
            const q = {}

            if (!_.isEmpty(_.trim(brand))) {
                q.brand = brand
                q.year = year
            }

            try {
                const response = await getProfilesByMonth(q)
                let result = []

                if (!_.isEmpty(response.data)) {
                    result = labels.map(label => {
                        const matchingData = response.data.find(item => {
                            const month = new Date(item.month)
                            month.setDate(1)
                            return labels[month.getMonth()] === label
                        })

                        return matchingData ? parseInt(matchingData.count, 10) : 0
                    })
                }
                const chartData = {
                    labels,
                    datasets: [
                        {
                            label: 'User',
                            data: result,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)'
                        }
                    ],
                    maintainAspectRatio: false,
                    responsive: false
                }
                setDataset(chartData)
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
                dispatch(setLoading(false))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, dispatch])


    function handleChange(event) {
        const {name, value} = event.target
        setSelected(prevOptions => ({
            ...prevOptions,
            [name]: value
        }))
    }

    return (
        <Box sx={{mx: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{mb: 3}}>
                    <Card>
                        <Box sx={{px: 2, pt: 3}}>
                            <Typography component="h1" variant="h6" sx={{mb: 2}}>
                                Total User Logged
                            </Typography>
                            <Grid container spacing={1} justifyContent="end">
                                <Grid item xs={12} md={3}>

                                    <TextField
                                        select
                                        fullWidth
                                        size={'small'}
                                        label={'Year'}
                                        name={'year'}
                                        value={selected.year}
                                        onChange={handleChange}
                                        sx={{mt: isMobile ? 1 : 0}}
                                        InputLabelProps={{shrink: true}}>
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>{year}</MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                            </Grid>
                        </Box>
                        <CardContent>
                            {
                                isLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <Line
                                        data={dataset}
                                        options={options}
                                        width={'100%'}
                                        style={{maxHeight: '250px', minHeight: '100px'}}/>
                                )
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
