import React from 'react'
import _ from 'lodash'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Alert from '@mui/material/Alert'


const StyledDiv = styled(Box)`
.tblHead th {
    font-weight: bolder;
  }

  .textIcon{
    display: inline-flex;
    align-items: center;
    gap: 5px;
    & .MuiSvgIcon-root {
        font-size: inherit;
    }
  }
  .tagsIcon{
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    position: absolute;
    bottom: 10px; 
    right: 10px; 
    background: #fff;
    border-radius: 10px;
    gap: 5px;
    & .MuiSvgIcon-root {
        font-size: inherit;
    }
  }
  .edatetime{
     display: flex;
  justify-content: space-between;
  }
`

export default function Tab2(props) {

	const { event } = props
	const eventMeta = event.EticketEventsMeta

	return (
		<StyledDiv>
			{
				_.isEmpty(eventMeta) ? (
					<Alert severity={'info'} sx={{m: 2}}>No additional information available yet</Alert>
				) : (
					<TableContainer>
						<Table sx={{ minWidth: 650 }}>
							<TableHead className="tblHead">
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell align="left">Type</TableCell>
									<TableCell align="left">Image</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									eventMeta.map((row, index) => {
										return (
											<TableRow key={index}
																hover
																sx={{ cursor: 'pointer' }}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{row.type}</TableCell>
												<TableCell>
													<Typography variant="body1">
														<a href={row.value} target="_blank" rel="noopener noreferrer">
															{row.value}
														</a>
													</Typography>
												</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				)
			}

		</StyledDiv>
	)

}
