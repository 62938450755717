import React, {useEffect, useRef} from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import * as echarts from 'echarts';

const StyledDiv = styled(Box)`
  ${({theme}) => `
    #chartMain {
    	margin-left: ${theme.spacing(2)};
    	width: 600px;
    	height: 600px;
    }
  `}
`

let init = false

export default function EchartsPie(props) {

	const {update, setUpdate, data} = props
	
	const chart = useRef()

	useEffect(() => {

		if (init === false) {
			init = true

			const chartDom = document.getElementById('chartMain')
			if (chartDom) {
				if (chart.current) {
					chart.current.dispose()
				}

				chart.current = echarts.init(chartDom, null, {renderer: 'svg'})
				chart.current.setOption({
					title: {
						text: 'Number of hits',
						subtext: 'Number of hits to the domain',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'horizontal',
						left: 'bottom'
					},
					series: [
						{
							name: 'Site',
							type: 'pie',
							radius: '50%',
							data: [],
							label: {
								position: 'outer',
								alignTo: 'edge',
								margin: 0,
								fontSize: 11,
								width: 300,
								edgeDistance: '0%',
								formatter: params => {
									return `${params.name} - ${params.value}`
								}
							},
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							left: 100
						}
					]
				})

				return () => {
					console.log('Disponse?')
				}
			}

		}

	}, [])

	useEffect(() => {

		if (chart.current) {
			chart.current.setOption({
				series: [{
					name: 'Site',
					data: data
				}]
			})
		}

		setUpdate(false)
	}, [update])

	return (
		<StyledDiv>
			<div id={'chartMain'} />
		</StyledDiv>
	)
}
