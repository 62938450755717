import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const miscSlice = createSlice({
    name: 'misc',
    initialState: {
        loading: false,
        snackbarmsg: '',
        page: 0,
        rowsPerPage: 10,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = _.isBoolean(action.payload) ? action.payload : false
        },
        setSnackbarMsg: (state, action) => {
            state.snackbarmsg = action.payload || ''
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    setLoading,
    setSnackbarMsg,
    setPage,
    setRowsPerPage
} = miscSlice.actions

export default miscSlice.reducer
