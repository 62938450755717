import SearchBox from "./search";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setLoading, setPage, setRowsPerPage, setSnackbarMsg} from "../../store/reducers/misc";
import {getPaymentMethods} from "../../lib/request";
import {styled} from "@mui/material/styles";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Tab1() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [paymentMethods, setPaymentMethods] = useState([])
    const { rowsPerPage, page } = useSelector(state => state.misc)
    const [search, setSearch] = useState({text: ''})
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(null)
    const [inLoad, setInLoad] = useState(true)


    useEffect(() => {
        fetchPaymentMethods()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (init === true) {
            fetchPaymentMethods()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page])

    function fetchPaymentMethods() {
        const q = {
            limit: _.clone(rowsPerPage),
            offset: rowsPerPage * page
        }

        dispatch(setLoading(true))
        getPaymentMethods(q)
            .then((res) => {
                setPaymentMethods(res.data.rows)
                setTotal(res.data.count)
                setInLoad(false)
            })
            .catch(err => console.log(err.response.data.message))
            .finally(() => {
                setInit(true)
                dispatch(setLoading(false))
            })
    }

    function beginSearch(e) {
        e.preventDefault()
        e.stopPropagation()

        let q = {
            limit: _.clone(rowsPerPage),
            offset: rowsPerPage * page
        }
        if (!_.isEmpty(search.text)) {
            q.channel_name = _.clone(search.text)
        }

        dispatch(setLoading(true))

        getPaymentMethods(q)
            .then((res) => {
                if (!_.isEmpty(res.data.rows)) {
                    setPaymentMethods(res.data.rows)
                    setTotal(res.data.count)
                } else if (!_.isEmpty(_.trim(search.text))) {
                    dispatch(setSnackbarMsg(`Sorry, ${search.text} is not in our system.`))
                    setPaymentMethods([])
                } else {
                    setPaymentMethods([])
                }
            })
            .catch(err => console.log(err.response.data.message))
            .finally(() => {
                setInit(true)
                dispatch(setLoading(false))
            })


    }

    function handleChangePage(e, newPage) {
        dispatch(setPage(newPage))
    }

    function handleChangeRowsPerPage(e) {
        dispatch(setRowsPerPage(parseInt(e.target.value, 10)))
        dispatch(setPage(0))
    }

    return (
        <StyledDiv>
            <Box sx={{p: 2}}>
                {
                    inLoad ? (
                        <Alert severity={'info'}>Please wait. Fetching Payment Methods </Alert>
                    ) : ( _.isEmpty(paymentMethods) ? (
                            <Alert severity={'info'}>No payment methods available </Alert>
                            ) : (
                                <Box>
                                <SearchBox beginSearch={beginSearch} search={search} setSearch={setSearch}/>
                                <Box>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        page={!total || total <= 0 ? 0 : page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                                    <TableContainer>
                                        <Table>
                                            <TableHead className="tblHead">
                                                <TableRow>
                                                    <TableCell align="left">Group</TableCell>
                                                    <TableCell align="left">Channel Name</TableCell>
                                                    <TableCell align="left">Display Name</TableCell>
                                                    <TableCell align="left">Active</TableCell>
                                                    <TableCell align="left">Enabled</TableCell>
                                                    <TableCell align="left">Auto Renewal</TableCell>
                                                    <TableCell align="left">Direct carrier billing</TableCell>
                                                    <TableCell align="left">Order</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    paymentMethods.map((row, index) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                className={'tablerow'}
                                                                onClick={() => navigate(`/payment-methods/${row.id}`)}
                                                                key={index}>
                                                                <TableCell>
                                                                    {row.group}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.channel_name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.isActive ? 'Yes' : 'No'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.isEnabled ? 'Yes' : 'No'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.isAutoRenewal ? 'Yes' : 'No'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.isDcb ? 'Yes' : 'No'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.order}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        page={!total || total <= 0 ? 0 : page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                                </Box>
                            </Box>
                            )

                        )
                }
            </Box>
        </StyledDiv>

    )
}
