import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Button, TextField} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PanelHead from "../../components/panel-head";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {setLoading, setSnackbarMsg} from "../../store/reducers/misc";
import {editPaymentmethod, getPaymentGroups, viewPaymentMethod} from "../../lib/request"
import MenuItem from "@mui/material/MenuItem";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function PaymentMethodDetail(props) {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {me} = useSelector(state => state.profile)

    const [detail, setDetail] = useState({})
    const [groups, setGroups]= useState([])

    const [form, setForm] = useState({
        id: null,
        group: '',
        groupId: '',
        name: '',
        channel_name: '',
        channel_filename: '',
        channel_parameter: '',
        image: '',
        order: null,
        isActive: false,
        isEnabled: false,
        isAutoRenewal: false,
        isDcb: false,
        groupErr: '',
        channelNameErr: '',
        channelFilenameErr: '',
        channelParameterErr: ''
    })

    useEffect(() => {
        if (_.isEmpty(groups)) {
            getPaymentGroups({limit:100})
                .then((res) => {
                    setGroups(res.data.rows)
                })
                .catch(err => console.log(err.response.data.message))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (!_.isUndefined(id)) {
            dispatch(setLoading(true))
            viewPaymentMethod(id)
                .then((res) => {
                    setDetail(res.data)
                    const formClone = _.cloneDeep(res.data)
                    formClone.image = res.data.image ? res.data.image: ''
                    formClone.order = res.data.order ? res.data.order: ''
                    formClone.groupErr = ''
                    formClone.channelNameErr = ''
                    formClone.channelFilenameErr = ''
                    formClone.channelParameterErr = ''
                    setForm(formClone)
                })
                .catch(err => {
                    if (err.response) {
                        dispatch(setSnackbarMsg(err.response.data.message))
                    }
                })
                .finally(() => {
                    dispatch(setLoading(false))
                })
        }
    }, [id, dispatch, groups])


    function changeForm(e) {
        const { name, value } = e.target
        const formClone = _.cloneDeep(form)

        if(name === 'groupId') {
            const selectedGroup = groups.find(group => group.id === value)
            formClone.group = selectedGroup ? selectedGroup.name : ''
        }

        formClone[name] = value

        formClone.groupErr = ''
        formClone.channelNameErr = ''
        formClone.channelFilenameErr = ''
        formClone.channelParameterErr = ''
        setForm(formClone)
    }

    function changeSwitch(e, v) {
        const {name} = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = v
        setForm(formClone)
    }

    function submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.groupId))) {
            formClone.groupErr = 'Group is required'
            error = true
        } else {
            const selectedGroup = groups.find(group => group.id === form.groupId)
            formClone.group = selectedGroup ? selectedGroup.name : ''
        }

        if (_.isEmpty(_.trim(form.channel_name))) {
            formClone.channelNameErr = 'channel name is required'
            error = true
        }

        if (_.isEmpty(_.trim(form.channel_filename))) {
            formClone.channelFilenameErr = 'channel filename is required'
            error = true
        }

        if (_.isEmpty(_.trim(form.channel_parameter))) {
            formClone.channelParameterErr = 'channel parameter is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        editPaymentmethod(form.id, form)
            .then((res) => {
                setDetail(res.data)
                dispatch(setSnackbarMsg('Payment method updated'))
            })
            .catch(err => console.error(err.toString()))
            .finally(() => dispatch(setLoading(false)))
    }


    return (
        <StyledDiv>
            <PanelHead title={`Payment method detail`}>
                <Link to={'/payment-methods'}>
                    <Button variant="text" color={'inherit'} sx={{mr: 2}}>Back</Button>
                </Link>
                {
                    !_.isEmpty(me) && (me.permission.includes('U') || me.username === 'superadmin') && (
                        <Button variant="outlined" onClick={submit} disabled={form.id === 404}>
                            Save
                        </Button>
                    )
                }
            </PanelHead>
            {
                !_.isEmpty(detail) && (
                    <Box sx={{pl: 2, pr: 2}}>
                        <Box component="form" noValidate onSubmit={submit}>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        select
                                        fullWidth
                                        required
                                        margin={'normal'}
                                        label="Group"
                                        variant="outlined"
                                        value={form.groupId}
                                        name={'groupId'}
                                        error={!_.isEmpty(form.groupErr)}
                                        InputLabelProps={{shrink: true}}
                                        onChange={changeForm}
                                        helperText={!_.isEmpty(form.groupErr) ? form.groupErr : ''}>
                                        {
                                            groups.map((g) => (
                                                <MenuItem key={g.id} value={g.id}>{g.label}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        fullWidth
                                        margin={'normal'}
                                        label="Display name"
                                        variant="outlined"
                                        value={form.name}
                                        name={'name'}
                                        InputLabelProps={{shrink: true}}
                                        onChange={changeForm}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'normal'}
                                        label="Channel name"
                                        variant="outlined"
                                        value={form.channel_name}
                                        name={'channel_name'}
                                        error={!_.isEmpty(form.channelNameErr)}
                                        InputLabelProps={{shrink: true}}
                                        onChange={changeForm}
                                        helperText={!_.isEmpty(form.channelNameErr) ? form.channelNameErr : ''}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'normal'}
                                        label="Channel filename"
                                        variant="outlined"
                                        value={form.channel_filename}
                                        name={'channel_filename'}
                                        error={!_.isEmpty(form.channelFilenameErr)}
                                        InputLabelProps={{shrink: true}}
                                        onChange={changeForm}
                                        helperText={!_.isEmpty(form.channelFilenameErr) ? form.channelFilenameErr : ''}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'normal'}
                                        label="Channel parameter"
                                        variant="outlined"
                                        value={form.channel_parameter}
                                        name={'channel_parameter'}
                                        error={!_.isEmpty(form.channelParameterErr)}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={changeForm}
                                        helperText={!_.isEmpty(form.channelParameterErr) ? form.channelParameterErr : ''}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        fullWidth
                                        margin={'normal'}
                                        label="Order"
                                        variant="outlined"
                                        value={form.order}
                                        name={'order'}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={changeForm}
                                    />
                                </Grid>
                                <Grid xs={12} sm={12} item>
                                    <TextField
                                        fullWidth
                                        margin={'normal'}
                                        label="Image url"
                                        variant="outlined"
                                        value={form.image}
                                        name={'image'}
                                        InputLabelProps={{shrink: true}}
                                        onChange={changeForm}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} item>
                                    <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                        <FormControlLabel
                                            control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
                                            label="is active"/>
                                    </FormGroup>
                                </Grid>
                                <Grid xs={12} sm={3} item>
                                    <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                        <FormControlLabel
                                            control={<Switch checked={form.isEnabled} name={'isEnabled'} onChange={changeSwitch}/>}
                                            label="is enabled"/>
                                    </FormGroup>
                                </Grid>
                                <Grid xs={12} sm={3} item>
                                    <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                        <FormControlLabel
                                            control={<Switch checked={form.isAutoRenewal} name={'isAutoRenewal'} onChange={changeSwitch}/>}
                                            label="is auto renewal"/>
                                    </FormGroup>
                                </Grid>
                                <Grid xs={12} sm={3} item>
                                    <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                        <FormControlLabel
                                            control={<Switch checked={form.isDcb} name={'isDcb'} onChange={changeSwitch}/>}
                                            label="is Direct Carrier Billing"/>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )
            }
        </StyledDiv>
    )
}
