import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PanelHead from '../../components/panel-head';
import UserRegisteredStats from "./user-registered";
import UserLoggedStats from "./user-logged";
import PaymentStatusStats from "./payment-status";
import Typography from '@mui/material/Typography';
import PaymentTodayStats from "./payment-today";
import { ssoInstance } from '../../lib/request';
import { setLoading, setSnackbarMsg } from '../../store/reducers/misc';

const StyleDiv = styled(Box)``;

export default function Homepage() {
    const dispatch = useDispatch();
    const { brands } = useSelector(state => state.brand);
    const { me, brandInFocus } = useSelector(state => state.profile);
    const [pause, setPause] = useState(false);

    // Ensure that focusedBrand is defined before accessing its properties
    const focusedBrand = brands.find(brand => brand.id === brandInFocus) || {};

    useEffect(() => {
        if (!_.isEmpty(me)) {
            ssoInstance.get('/pause')
                .then(response => setPause(response.data.maintenance === 'T'))
                .catch(err => {
                    dispatch(setSnackbarMsg('There is an error fetching maintenance'));
                });
        }
    }, [me, dispatch]);

    function changeActivity(e, v) {
        if (_.isArray(brands) && me && _.isArray(me.Brands) && brands.length === me.Brands.length) {
            setPause(v);
            dispatch(setLoading(true));
            ssoInstance.post('/pause', { pause: v })
                .then(() => dispatch(setSnackbarMsg(`SSO entry point is ${v ? '' : 'un'}pause!`)))
                .catch(() => dispatch(setSnackbarMsg('There is an error. Please reload!')))
                .finally(() => dispatch(setLoading(false)));
        }
    }

    return (
        <StyleDiv>
            <PanelHead title={'Homepage'} />
            {(_.isArray(brands) && me && _.isArray(me.Brands) && brands.length === me.Brands.length) && (
                <Box sx={{ pl: 3, pb: 2 }}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={pause} onChange={changeActivity} />}
                            label="Set application under development"
                        />
                    </FormGroup>
                </Box>
            )}
            {(focusedBrand.name !== 'E-ticketing') ? (
                <>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} item>
                            <PaymentTodayStats />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} item>
                            <UserLoggedStats />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <UserRegisteredStats />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} item>
                            <PaymentStatusStats />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Box sx={{ pl: 2, pb: 2 }}>
                    <Typography variant='h5'>Welcome to {focusedBrand.name} Dashboard</Typography>
                </Box>
            )}
        </StyleDiv>
    );
}
