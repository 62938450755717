import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const StyledDiv = styled(Box)`
`

export default function Search(props) {

	const {search, setSearch, beginSearch} = props

	return (
		<StyledDiv sx={{pl: 0, pr: 0, pb: 0}}>
			<form onSubmit={beginSearch}>
				<TextField
					fullWidth
					variant={'outlined'}
					size={'small'}
					placeholder={'Client Id'}
					value={search}
					onChange={e => setSearch(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton type={'submit'} onClick={beginSearch} onMouseDown={beginSearch} edge="end">
									<SearchOutlinedIcon />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</form>
		</StyledDiv>
	)
}
