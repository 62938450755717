import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {setLoading} from "../../store/reducers/misc";
import {getPaymentGroups} from "../../lib/request";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Tab2() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [paymentGroups, setPaymentGroups] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(null)
    const [inLoad, setInLoad] = useState(true)

    useEffect(() => {
        fetchPaymentGroups()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (init === true) {
            fetchPaymentGroups()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page])

    function fetchPaymentGroups() {
        const q = {
            limit: _.clone(rowsPerPage),
            offset: rowsPerPage * page
        }

        dispatch(setLoading(true))
        getPaymentGroups(q)
            .then((res) => {
                setPaymentGroups(res.data.rows)
                setTotal(res.data.count)
                setInLoad(false)
            })
            .catch(err => console.log(err.response.data.message))
            .finally(() => {
                setInit(true)
                dispatch(setLoading(false))
            })
    }

    function handleChangePage(e, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(e) {
        setRowsPerPage(parseInt(e.target.value, 10))
        setPage(0)
    }

    return (
        <StyledDiv>
            <Box sx={{p: 2}}>
                {
                    inLoad ? (
                        <Alert severity={'info'}>Please wait. Fetching Payment Groups </Alert>
                    ) : ( _.isEmpty(paymentGroups) ? (
                                <Alert severity={'info'}>No payment groups available </Alert>
                            ) : (
                                <Box>
                                    <Box>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 50, 100]}
                                            component="div"
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={!total || total <= 0 ? 0 : page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}/>
                                        <TableContainer>
                                            <Table>
                                                <TableHead className="tblHead">
                                                    <TableRow>
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="left">Label</TableCell>
                                                        <TableCell align="left">Enabled</TableCell>
                                                        <TableCell align="left">Payment Method</TableCell>
                                                        <TableCell align="left">Order</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        paymentGroups.map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    className={'tablerow'}
                                                                    onClick={() => navigate(`/payment-methods/group/${row.id}`)}
                                                                    key={index}>
                                                                    <TableCell>
                                                                        {row.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.label}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.isEnabled ? 'Yes' : 'No'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.isPaymentMethod ? 'Yes' : 'No'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.order ? row.order : '-'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 50, 100]}
                                            component="div"
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={!total || total <= 0 ? 0 : page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}/>
                                    </Box>
                                </Box>
                                )
                        )
                }
            </Box>
        </StyledDiv>
    )



}
