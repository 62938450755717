import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import copy from 'copy-to-clipboard'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'
import Alert from '@mui/material/Alert'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import PanelHead from '../../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {deleteApplication, getAllApplications, getCredentials} from '../../../lib/request'
import {setCredentials} from '../../../store/reducers/credentials'
import {removeApplication, setApplications} from '../../../store/reducers/application'
import DeleteConfirmationDialog from '../delete-confirmation-dialog'

const StyleDiv = styled(Box)`
	max-width: 100vw;
	
  .tblHead th {
    font-weight: bolder;
  }
`
export default function CredentialIndex() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {id} = useParams()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {me} = useSelector(state => state.profile)
	const applications = useSelector((state) => state.application.applications)
	const credentials = useSelector((state) => state.credential.credentials)

	const [onDelete, setOnDelete] = useState(null)
	const [applicationState, setApplicationState] = useState({})
	const [applicationCredentails, setApplicationCredentails] = useState([])

	useEffect(() => {
		if (_.isEmpty(applications)) {
			getAllApplications()
				.then(res => {
					dispatch(setApplications(res.data))
					return getCredentials()
						.then(response => {
							dispatch(setCredentials(response.data.credentials))
							getInfo(res.data, response.data.credentials)
						})
				})
				.catch(err => console.log(err.response.data.message))
		} else {
			getInfo(applications, credentials)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applications])

	function getInfo(applications, credentialsLists) {
		const application = _.find(applications, {id: parseInt(id)})
		if (application) {
			setApplicationState(application)
			const listOfThisCredentials = []
			credentialsLists.forEach(c => {
				if (c.sso_key === application.apiKey) {
					listOfThisCredentials.push(c)
				}
			})
			setApplicationCredentails(listOfThisCredentials)
		}
	}

	function confirmDelete() {
		dispatch(setLoading(true))
		deleteApplication({id: onDelete})
			.then(response => {
				dispatch(setSnackbarMsg('Application credentials deleted'))
				dispatch(removeApplication({id: onDelete}))
				setOnDelete(null)
				navigate('/application')
			})
			.catch(err => {
				if (err.response) {
					const {message} = err.response.data
					dispatch(setSnackbarMsg(message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function nextPage(clientId) {
		navigate(`/application/credentials/${id}/detail/${clientId}`)
	}

	return (
		<StyleDiv>
			<PanelHead title={'Apps credentials'}>
				<Link to={'/application'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
				{
					!_.isEmpty(credentials) && (!_.isEmpty(me) && (me.permission.includes('D'))) && (
						<Button
							fullWidth={isMobile}
							color={'error'}
							variant="outlined"
							sx={{mr: isMobile ? 0 : 2, mt: isMobile ? 1 : 0}}
							onClick={() => setOnDelete(id)}>
							Delete
						</Button>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C')) && (
						<Link to={`/application/credentials/${id}/create`}>
							<Button
								fullWidth={isMobile}
								variant="outlined"
								sx={{mt: isMobile ? 1 : 0}}>
								new credential
							</Button>
						</Link>
					)
				}
			</PanelHead>

			<Box sx={{p: 2}}>
				<Typography variant="h6">{applicationState.label}</Typography>
				<Typography variant="caption" component={'div'} noWrap>SSO Key: {applicationState.apiKey}</Typography>
			</Box>
			{
				_.isEmpty(applicationCredentails) ? (
					<Box sx={{p: 2}}><Alert severity={'info'}>No credentials for this application</Alert></Box>
				) : (
					<TableContainer>
						<Table>
							<TableHead className="tblHead">
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell align="left">Name</TableCell>
									<TableCell align="left">Client Id</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									applicationCredentails.map((c, index) => {
										return (
											<TableRow hover onClick={() => nextPage(c.client_id)} sx={{cursor: 'pointer'}} key={index}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{c.name}</TableCell>
												<TableCell>{c.client_id}</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				)
			}
			{
				!_.isNull(onDelete) && <DeleteConfirmationDialog onCancel={() => setOnDelete(null)} onConfirm={confirmDelete}/>
			}
		</StyleDiv>

	)

}
