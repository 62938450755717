import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { TextField } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'

import PanelHead from '../../../components/panel-head'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'

import { addAdditionalPackages } from '../../../lib/request'
import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import useMediaQuery from '@mui/material/useMediaQuery'


const StyledDiv = styled(Box)`
  ${({ theme }) => `
    
  `}
`

export default function Create(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const me = useSelector((state) => state.profile.me)
    const brandInFocus = useSelector((state) => state.profile.brandInFocus)

    const [brands, setBrands] = useState([])
    const [form, setForm] = useState({
        name: '',
        systemName: '',
        nameErr: null,
        description: '',
        price: 0,
        priceErr: null,
        isActive: false,
        brandId: brandInFocus,
        brandIdErr: null
    })



    useEffect(() => {
        setBrands(me['Brands'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    function changeForm(e) {
        const { name, value } = e.target
        const formClone = _.cloneDeep(form)

        formClone.nameErr = null
        formClone.priceErr = null
        formClone.brandIdErr = null

        formClone[name] = value

        setForm(formClone)
    }

    function changeSwitch(e, v) {
        const { name } = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = v

        setForm(formClone)
    }

    function submit(e) {
        e.preventDefault()
        e.stopPropagation()

        const formClone = _.cloneDeep(form)

        formClone.nameErr = null
        formClone.priceErr = null
        formClone.brandIdErr = null

        setForm(formClone)

        let err = false
        if (_.isEmpty(_.trim(form.name))) {
            err = true
            formClone.nameErr = 'Name of the subscription plan is required'
        }
        if (_.isEmpty(_.trim(form.price))) {
            err = true
            formClone.priceErr = 'Price of the subscription plan is required'
        }
        if (_.isEmpty(form.brandId.toString())) {
            err = true
            formClone.brandIdErr = 'A brand for this plan is required'
        }

        if (err) {
            return setForm(formClone)
        }


        formClone.price = parseFloat(formClone.price)

        dispatch(setLoading(true))
        addAdditionalPackages(formClone)
            .then(() => {
                dispatch(setSnackbarMsg('Additional Package Created'))
                navigate('/subscription?tab=1')
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }

    return (
        <StyledDiv>
            <PanelHead title={`New Additional Package`}>
                <Link to={'/subscription'}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'}
                        sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 1 : 0 }}>
                        Back
                    </Button>
                </Link>
                <Button fullWidth={isMobile} variant="outlined" onClick={submit}>
                    Save
                </Button>
            </PanelHead>

            <Box sx={{ p: 2 }}>
                <Box component="form" noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                type="text"
                                                margin="normal"
                                                label="Name"
                                                name="name"
                                                InputLabelProps={{ shrink: true }}
                                                error={!_.isEmpty(form.nameErr)}
                                                helperText={form.nameErr}
                                                value={form.name}
                                                onChange={changeForm} />

                                            <TextField
                                                required
                                                fullWidth
                                                type="text"
                                                margin="normal"
                                                label="System name"
                                                name="systemName"
                                                InputLabelProps={{ shrink: true }}
                                                value={form.systemName}
                                                onChange={changeForm} />

                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                type="text"
                                                margin="normal"
                                                label="Description internal"
                                                name="description"
                                                InputLabelProps={{ shrink: true }}
                                                value={form.description}
                                                onChange={changeForm} />

                                            <TextField
                                                required
                                                fullWidth
                                                type="text"
                                                margin="normal"
                                                label="Price"
                                                name="price"
                                                InputLabelProps={{ shrink: true }}
                                                error={!_.isEmpty(form.priceErr)}
                                                helperText={form.priceErr}
                                                value={form.price}
                                                onChange={changeForm} />

                                            <TextField
                                                required
                                                fullWidth
                                                select
                                                margin="normal"
                                                label="Brand"
                                                name="brandId"
                                                type="text"
                                                error={!_.isNull(form.brandIdErr)}
                                                helperText={form.brandIdErr}
                                                InputLabelProps={{ shrink: true }}
                                                value={form.brandId}
                                                onChange={changeForm}
                                                autoComplete="brands">
                                                {brands.map((brand) => (
                                                    <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>


                                    </Grid>
                                    <Grid xs={12}>
                                        <FormGroup component="fieldset">
                                            <FormControlLabel
                                                control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch} />}
                                                label="is active" />
                                        </FormGroup>
                                    </Grid>

                                </CardContent>
                            </Card>

                        </Grid>


                    </Grid>



                </Box>

            </Box>
        </StyledDiv>
    )
}
