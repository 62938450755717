import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from '@mui/icons-material/Logout'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { setBrandInFocus } from '../store/reducers/profile'

export default function Topbar(props) {

	const { open, setOpen } = props

	const profile = useSelector(state => state.profile)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const location = useLocation()

	const [anchorEl, setAnchorEl] = useState(null)
	const openMenu = Boolean(anchorEl)

	// Automatically select "eticketing" and disable other options if URL is /eticket
	useEffect(() => {
		const eticketPathPattern = /^\/eticket(\/.*)?$/
		if (eticketPathPattern.test(location.pathname)) {
			const eticketBrand = profile.me.Brands.find(brand => brand.name.toLowerCase() === 'e-ticketing')
			if (eticketBrand) {
				dispatch(setBrandInFocus(eticketBrand.id))
			}
		}
	}, [location, dispatch, profile.me.Brands])

	return (
		<AppBar position="fixed" sx={{ bgcolor: '#f05b2d', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
			<Toolbar>
				{
					isMobile && (
						<IconButton color="inherit" onClick={() => setOpen(!open)} edge="start">
							<MenuIcon />
						</IconButton>
					)
				}
				{
					!isMobile && (
						<Box>
							<Link to="/">
								<Typography color={'#fff'} variant="h6" noWrap>
									SSO Dashboard
								</Typography>
							</Link>
						</Box>
					)
				}

				<Box sx={{ pl: 2, pr: 2, flexGrow: 1 }}>
					<Box sx={{ maxWidth: isMobile ? 'auto' : 150 }}>
						<FormControl fullWidth>
							<Select
								sx={{ backgroundColor: '#fff', borderColor: '#fff' }}
								size={'small'}
								value={profile.brandInFocus}
								onChange={e => dispatch(setBrandInFocus(parseInt(e.target.value)))}
								disabled={/^\/eticket(\/.*)?$/.test(location.pathname)}
							>
								{
									profile.me.Brands.map(b => {
										const isEticketing = b.name.toLowerCase() === 'eticketing'
										return <MenuItem key={b.id} value={b.id} disabled={!isEticketing && location.pathname === '/eticket'}>{b.name}</MenuItem>
									})
								}
							</Select>
						</FormControl>
					</Box>
				</Box>

				{
					!_.isEmpty(profile.me) && (
						<Box>
							<IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small" >
								<AccountCircleOutlinedIcon sx={{color: '#fff'}} />
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={openMenu}
								onClose={() => setAnchorEl(null)}
								onClick={() => setAnchorEl(null)}
								transformOrigin={{horizontal: 'right', vertical: 'top'}}
								anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
								<MenuItem onClick={() => navigate('/me')}>
									<ListItemIcon>
										<ManageAccountsOutlinedIcon />
									</ListItemIcon>
									<ListItemText primary={'My account'} />
								</MenuItem>
								<Divider/>
								<MenuItem onClick={() => navigate('/logout')}>
									<ListItemIcon>
										<LogoutIcon/>
									</ListItemIcon>
									<ListItemText primary={'Logout'} />
								</MenuItem>
							</Menu>
						</Box>
					)
				}
			</Toolbar>
		</AppBar>
	)

}