import {useSelector} from 'react-redux'
import {styled} from '@mui/material/styles'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

const StyleBox = styled(Box)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.4);
`

export default function Loading() {

	const misc = useSelector((state) => state.misc)

	return (
		<Box>
			{
				misc.loading && (
					<StyleBox>
						<LinearProgress/>
					</StyleBox>
				)
			}
		</Box>
	)
}