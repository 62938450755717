import React, {useEffect, useState} from 'react'

import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';

import {getPayments, downloadFile} from '../../lib/request'
import {setPayments, storePage, storeRowsPerPage} from '../../store/reducers/payment'

import PanelHead from '../../components/panel-head'
import useMediaQuery from '@mui/material/useMediaQuery'
import {setLoading} from '../../store/reducers/misc'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }

  .swal2-popup {
    font-family: "Roboto", sans-serif !important;
  }

  .inline {
    display: flex;
    align-items: center;
  }
`

export default function Payment() {

	const theme = useTheme()
	const dispatch = useDispatch()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const navigate = useNavigate()

	const payments = useSelector((state) => state.payment.payments)
	const pageReducer = useSelector((state) => state.payment.page)
	const rowsPerPageReducer = useSelector((state) => state.payment.rowsPerPage)
	const {brandInFocus} = useSelector(state => state.profile)

	const [total, setTotal] = useState(0)
	const [form, setForm] = useState({
		orderId: '',
		transactionId: '',
		status: 'x',
		other: 'x',
		payDateFrom: '',
		payDateTo: '',
		payDateFromErr: '',
		payDateToErr: '',
	})
	const [init, setInit] = useState(null)

	const [page, setPage] = useState(pageReducer)
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageReducer)
	const [searchParams, setSearchParams] = useState({})

	useEffect(() => {
		fetchPayments()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (init === true) {
			fetchPayments()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage])

	useEffect(() => {
		handleChangePage(null, 0)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandInFocus])

	function handleChangePage(e, newPage) {
		dispatch(storePage(newPage))
		setPage(newPage)
	}

	function handleChangeRowsPerPage(e) {
		dispatch(storeRowsPerPage(parseInt(e.target.value, 10)))
		dispatch(storePage(0))
		setRowsPerPage(parseInt(e.target.value, 10))
		setPage(0)
	}

	function fetchPayments() {
		const q = {
			brandId: brandInFocus,
			limit: _.clone(rowsPerPage),
			offset: rowsPerPage * page
		}

		let hasSearch = false
		let error = false
		const formClone = _.cloneDeep(form)

		if (!_.isEmpty(_.trim(form.orderId))) {
			q.orderId = form.orderId
			hasSearch = true
		}

		if (!_.isEmpty(_.trim(form.transactionId))) {
			q.transactionId = form.transactionId
			hasSearch = true
		}

		if (form.status !== 'x') {
			q.status = form.status
		}
		if (form.other !== 'x') {
			q.other = form.other
		}

		if (!_.isEmpty(_.trim(form.payDateFrom))) {
			q.payDateFrom = form.payDateFrom
		}

		if (!_.isEmpty(_.trim(form.payDateTo))) {
			q.payDateTo = form.payDateTo
		}

		if (!_.isEmpty(_.trim(form.payDateFrom)) && _.isEmpty(_.trim(form.payDateTo))) {
			formClone.payDateToErr = 'PayDate To is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.payDateFrom)) && !_.isEmpty(_.trim(form.payDateTo))) {
			formClone.payDateFromErr = 'PayDate from is required'
			error = true
		}

		if(!_.isEmpty(_.trim(form.payDateFrom)) && !_.isEmpty(_.trim(form.payDateTo))) {
			const dateFrom = dayjs(form.payDateFrom)
			const dateTo = dayjs(form.payDateTo)
			if (dateFrom.isAfter(dateTo)) {
				formClone.payDateFromErr = 'PayDate from must be before PayDate to';
				error = true
			}
		}

		if (error) {
			return setForm(formClone)
		}

		if (hasSearch) {
			q.limit = 1
			q.offset = 0
		}

		if(_.size(_.keys(q)) > 3) {
			setSearchParams(q)
		}

		dispatch(setLoading(true))
		getPayments(q)
			.then((res) => {
				if (hasSearch) {
					dispatch(storePage(0))
				} else {
					dispatch(storePage(page))
				}
				dispatch(setPayments(res.data.rows))
				setTotal(res.data.count)
			})
			.catch(err => {
				console.log(err.response.data.message)
			})
			.finally(() => {
				dispatch(setLoading(false))
				setInit(true)
			})
	}

	function changeForm(e) {
		let {name, value} = e.target
		const formClone = _.cloneDeep(form)

		if(name === 'payDateFrom' || name === 'payDateTo') {
			value = dayjs(value).format('YYYY-MM-DD')
		}

		formClone[name] = value

		formClone.payDateFromErr = ''
		formClone.payDateToErr = ''

		setForm(formClone)

	}

	function searchPayment(e) {
		e.preventDefault()
		e.stopPropagation()

		fetchPayments()
	}

	const handleRowClick = (paymentId, newTab = false, e) => {
		const url = `/payment/${paymentId}`
		if (newTab) {
			window.open(url, '_blank')
			if (e) {
				e.preventDefault()
				e.stopPropagation()
			}
		} else {
			navigate(url)
		}
	}

	function handleDownloadFile(e, fileType) {
		e.preventDefault()
		e.stopPropagation()

		let q = {
			brandId: brandInFocus,
			limit: 500,
			fileType
		}

		if(!_.isEmpty(searchParams)) {
			q = {
				...q,
				..._.omit(searchParams, ['brandId', 'limit', 'offset'])
			}
		}

		downloadFile(q)
			.then((res) => {
				const fileData = res.data
				if(!_.isEmpty(fileType) && _.trim(fileType) === 'csv') {
					const blob = new Blob([fileData], { type: 'text/csv' })
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'payments.csv'
					link.click()
					window.URL.revokeObjectURL(link.href)
				} else if(!_.isEmpty(fileType) && _.trim(fileType) === 'pdf') {
					const newTab = window.open('', '_blank')
					newTab.document.write(`<iframe src="${fileData}" style="width: 100%; height: 100%; border: none;"></iframe>`)
				}
			})
			.catch(err => {
				console.log(err.response.data.message)
			})
	}

	return (
		<StyleDiv>
			<PanelHead title={'Payments'}>
				{
					(!_.isEmpty(payments) && total <= 500) && (
						<>
							<Button variant="outlined" fullWidth={isMobile} onClick={(e) => handleDownloadFile(e, 'csv')}>
								Download Csv
							</Button>
							<Button variant="outlined" fullWidth={isMobile} onClick={(e) => handleDownloadFile(e, 'pdf')} sx={{ml: isMobile ? 0: 2, mt: isMobile ? 2 : 0}}>
								Download Pdf
							</Button>
						</>
					)
				}
			</PanelHead>

			<Box sx={{p: 2}}>
				<Box component="form" noValidate onSubmit={searchPayment}>
					<Grid container spacing={1}>
						<Grid xs={12} md={3}>
							<TextField
								fullWidth
								id="outlined-search"
								size="small"
								name="orderId"
								value={form.orderId}
								onChange={changeForm}
								label="Order Id"
								InputLabelProps={{shrink: true}}
								type="text"/>
						</Grid>
						<Grid xs={12} md={3}>
							<TextField
								fullWidth
								id="outlined-search"
								size="small"
								name="transactionId"
								value={form.transactionId}
								onChange={changeForm}
								label="Transaction Id"
								InputLabelProps={{shrink: true}}
								sx={{mt: isMobile ? 2 : 0}}
								type="text"/>
						</Grid>
						<Grid xs={12} md={2}>
							<TextField
								select
								fullWidth
								size={'small'}
								label={'Status'}
								name={'status'}
								value={form.status}
								onChange={changeForm}
								sx={{mt: isMobile ? 2 : 0}}
								InputLabelProps={{shrink: true}}>
								<MenuItem value="x">All</MenuItem>
								<MenuItem value="null">Unknown</MenuItem>
								<MenuItem value="00">Success</MenuItem>
								<MenuItem value="11">Fail</MenuItem>
								<MenuItem value="22">Pending</MenuItem>
							</TextField>
						</Grid>
						<Grid xs={12} md={2}>
							<TextField
								select
								fullWidth
								size={'small'}
								label={'Other'}
								name={'other'}
								value={form.other}
								onChange={changeForm}
								sx={{mt: isMobile ? 2 : 0}}
								InputLabelProps={{shrink: true}}>
								<MenuItem value="x">All</MenuItem>
								<MenuItem value="recurringinit">Recurring Initial</MenuItem>
								<MenuItem value="recurringafter">Recurring Scheduler</MenuItem>
								<MenuItem value="redeem">Redeem</MenuItem>
							</TextField>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid xs={12} md={3}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label="PayDate From"
									value={form.payDateFrom}
									name='payDateFrom'
									inputFormat="YYYY-MM-DD"
									disableTime
									onChange={(value) => changeForm({ target: { name: 'payDateFrom', value } })}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											size={'small'}
											InputLabelProps={{shrink: true}}
											sx={{mt: 2}}
											error={!_.isEmpty(form.payDateFromErr)}
											helperText={form.payDateFromErr}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid xs={12} md={3}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label="PayDate To"
									value={form.payDateTo}
									name='payDateTo'
									inputFormat="YYYY-MM-DD"
									disableTime
									onChange={(value) => changeForm({ target: { name: 'payDateTo', value } })}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											size={'small'}
											InputLabelProps={{shrink: true}}
											sx={{mt: 2}}
											error={!_.isEmpty(form.payDateToErr)}
											helperText={form.payDateToErr}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid xs={12} md={2} sx={{alignSelf: 'center', textAlign: 'right', mt: 2}}>
							<Button type="submit" variant="outlined" fullWidth>
								Search
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>

			<TablePagination
				sx={{mt: 1, mb: 1}}
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={total}
				rowsPerPage={rowsPerPage}
				page={!total || total <= 0 ? 0 : page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}/>

			<TableContainer>
				<Table>
					<TableHead className="tblHead">
						<TableRow>
							<TableCell align="left" width={10}>#</TableCell>
							<TableCell align="left">Order Id</TableCell>
							<TableCell align="left">Amount</TableCell>
							<TableCell align="left">TransactionId</TableCell>
							<TableCell align="left">Channel</TableCell>
							<TableCell align="left">Pay Date</TableCell>
							<TableCell align="center" width={50}>Coupon</TableCell>
							<TableCell align="center" width={50}>Recurring</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							payments.map((payment, index) => {
								let sx = {cursor: 'pointer'}
								if (payment.status === '00') {
									sx.backgroundColor = theme.palette.success.light
								} else if (payment.status === '11') {
									sx.backgroundColor = theme.palette.error.light
								} else if (payment.status === '22') {
									sx.backgroundColor = theme.palette.warning.light
								}

								let description = null
								if (payment.Meta && !_.isEmpty(_.trim(payment.Meta.description))) {
									if (!_.isEmpty(_.trim(payment.Meta.description.replace('-', '')))) {
										description = payment.Meta.description
									}
								}

								let channel = payment.Meta?.channel || ''
								if (channel === 'credit') {
									channel = `${payment.Meta.ccType} CARD`
								}

								channel = _.lowerCase(channel)

								let txnId = payment.transactionId
								let payDate = _.isNull(payment.payDate) ? null : dayjs(payment.payDate)
								let couponCode = ''
								if ((_.isEmpty(payment.transactionId) || payment.transactionId === 'INTERNAL_PLAN' || payment.transactionId === 'COUPON') && !_.isEmpty(payment.Coupon)) {
									sx.backgroundColor = theme.palette.info.light
									txnId = payment.Plan.name
									channel = 'coupon'
									payDate = dayjs(payment.createdAt)
									couponCode = <Tooltip title={payment.Coupon.Couponcode?.code || ''} placement={"left"}><DoneAllOutlinedIcon /></Tooltip>
								}

								return (
									<TableRow hover key={index} sx={sx} onClick={() => handleRowClick(payment.id)}>
										<TableCell width={10}>
											{index + 1 + (rowsPerPage * page)}
										</TableCell>
										<TableCell width={190}>
											<Box className="inline">
												{payment.isRecurring && <CodeOutlinedIcon sx={{marginRight: 1}}/>}
												<Box alignSelf="center" onClick={e => handleRowClick(payment.id, true, e)}>
													{payment.orderId}
												</Box>
											</Box>
										</TableCell>
										<TableCell width={100}>
											RM {payment.amount.toFixed(2)}
										</TableCell>
										<TableCell width={150}>
											<Tooltip title={txnId} placement={"left"}>
												<Typography noWrap sx={{maxWidth: 150}}>{txnId}</Typography>
											</Tooltip>
										</TableCell>
										<TableCell width={200}>
											{channel}
										</TableCell>
										<TableCell width={300}>
											{
												_.isNull(payDate) ? '' : payDate.format('DD MMM YYYY h:mm A')
											}
											<br />
											{
												description && (
													<Typography variant={'caption'}>
														<small>{payment.Meta.description}</small>
													</Typography>
												)
											}
										</TableCell>
										<TableCell align="center" width={10}>
											{ couponCode }
										</TableCell>
										<TableCell  align="center" width={10}>
											{
												!_.isEmpty(payment.Meta?.token) && <CheckCircleOutlineOutlinedIcon />
											}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				sx={{mt: 1}}
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={total}
				rowsPerPage={rowsPerPage}
				page={!total || total <= 0 ? 0 : page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}/>
		</StyleDiv>
	)
}
