import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {TextField} from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'

import PanelHead from '../../components/panel-head'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import InputAdornment from '@mui/material/InputAdornment'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

import {addSubscription, getSubscriptions} from '../../lib/request'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import {updateSubscriptionDropdown} from '../../store/reducers/subscription'
import useMediaQuery from '@mui/material/useMediaQuery'


const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Create(props) {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const me = useSelector((state) => state.profile.me)
	const brandInFocus = useSelector((state) => state.profile.brandInFocus)

	const [brands, setBrands] = useState([])
	const [isUsingDays, setIsUsingDays] = useState(false)
	const [form, setForm] = useState({
		name: '',
		nameErr: null,
		descriptionPublic: '',
		description: '',
		price: 0,
		priceErr: null,
		isRecommended: false,
		isActive: false,
		isAnnually: false,
		isInternal: false,
		from: null,
		to: null,
		intervals: 1,
		intervalsErr: null,
		brandId: brandInFocus,
		brandIdErr: null,
		days: 0,
		daysErr: null,
		isSubscribeAble: true,
		token: ''
	})

	useEffect(() => {

		if (form.isAnnually) {
			const formClone = _.cloneDeep(form)
			formClone.intervals = 1
			formClone.intervalsErr = null

			setForm(formClone)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.isAnnually])

	useEffect(() => {
		setBrands(me['Brands'])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)

		formClone.nameErr = null
		formClone.priceErr = null
		formClone.intervalsErr = null
		formClone.brandIdErr = null
		formClone.daysErr = null

		if (name === 'intervals') {
			if (parseInt(value) === 12) {
				formClone[name] = 1
				formClone.isAnnually = true
			} else if (!_.isEmpty(_.trim(value)) && (value > 11 || value <= 0)) {
				formClone.intervalsErr = 'Invalid duration value. Between 1 month and a year'
			} else {
				formClone[name] = value
				formClone.isAnnually = false
			}
		} else if (name === 'days') {
			const v = parseInt(value)
			if (_.isEmpty(_.trim(value))) {
				formClone[name] = value
			} else if (isNaN(v)) {
				formClone.daysErr = 'Invalid duration value. Either 1 or more'
			} else if (v === 0) {
				setIsUsingDays(false)
			} else if (v < 0) {
				formClone.daysErr = 'Invalid duration value. Either 1 or more'
			} else {
				formClone[name] = value
			}
		} else {
			formClone[name] = value
		}

		setForm(formClone)
	}

	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v

		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)

		formClone.nameErr = null
		formClone.priceErr = null
		formClone.intervalsErr = null
		formClone.brandIdErr = null
		formClone.daysErr = null

		if (isUsingDays && (parseInt(formClone.days) <= 0 || isNaN(parseInt(formClone.days)))) {
			setIsUsingDays(false)
		}

		setForm(formClone)

		let err = false
		if (_.isEmpty(_.trim(form.name))) {
			err = true
			formClone.nameErr = 'Name of the subscription plan is required'
		}
		if (_.isEmpty(_.trim(form.price))) {
			err = true
			formClone.priceErr = 'Price of the subscription plan is required'
		}
		if (isUsingDays) {
			if (isNaN(parseInt(form.days)) || parseInt(form.days) < 0) {
				err = true
				formClone.daysErr = 'Duration of the subscription plan is required'
			}
		} else {
			if (isNaN(parseInt(form.intervals))) {
				err = true
				formClone.intervalsErr = 'Duration of the subscription plan is required'
			} else if (parseInt(form.intervals) > 11 || parseInt(form.intervals) < 1) {
				err = true
				formClone.intervalsErr = 'Duration of the subscription plan is invalid'
			}
		}
		if (_.isEmpty(form.brandId.toString())) {
			err = true
			formClone.brandIdErr = 'A brand for this plan is required'
		}

		if (err) {
			return setForm(formClone)
		}

		if (formClone.days <= 0 || !isUsingDays) {
			formClone.days = 0
		}
		formClone.price = parseFloat(formClone.price)

		dispatch(setLoading(true))
		addSubscription(formClone)
			.then(() => {
				dispatch(setSnackbarMsg('Plan created'))
				navigate('/subscription')
			})
			.then(() => {
				return getSubscriptions({unlimited: true, withSubscriber :false})
					.then((res) => {
						dispatch(updateSubscriptionDropdown(res.data.rows))
					})
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDiv>
			<PanelHead title={`New subscriptions plan`}>
				<Link to={'/subscription'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile ? 0 : 2, mb: isMobile ? 1 : 0}}>
						Back
					</Button>
				</Link>
				<Button fullWidth={isMobile} variant="outlined" onClick={submit}>
					Save
				</Button>
			</PanelHead>

			<Box sx={{p: 2}}>

				<Box component="form" noValidate onSubmit={submit}>

					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<Card>
								<CardContent>
									<Grid container spacing={2}>
										<Grid xs={12}>
											<TextField
												required
												fullWidth
												type="text"
												margin="normal"
												label="Name"
												name="name"
												InputLabelProps={{shrink: true}}
												error={!_.isEmpty(form.nameErr)}
												helperText={form.nameErr}
												value={form.brand}
												onChange={changeForm}/>

											<TextField
												required
												fullWidth
												type="text"
												margin="normal"
												label="Price"
												name="price"
												InputLabelProps={{shrink: true}}
												error={!_.isEmpty(form.priceErr)}
												helperText={form.priceErr}
												value={form.price}
												onChange={changeForm}/>

											<TextField
												required
												fullWidth
												select
												margin="normal"
												label="Brand"
												name="brandId"
												type="text"
												error={!_.isNull(form.brandIdErr)}
												helperText={form.brandIdErr}
												InputLabelProps={{shrink: true}}
												value={form.brandId}
												onChange={changeForm}
												autoComplete="brands">
												{brands.map((brand) => (
													<MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid xs={6}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													label="From"
													value={form.from}
													inputFormat="DD/MM/YYYY"
													onChange={(value) => changeForm({target: {value, name: 'from'}})}
													renderInput={(params) => <TextField {...params} margin="normal" fullWidth
																															InputLabelProps={{shrink: true}}/>}
												/>
											</LocalizationProvider>
										</Grid>
										<Grid xs={6}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													label="To"
													value={form.to}
													minDate={form.from}
													inputFormat="DD/MM/YYYY"
													onChange={(value) => changeForm({target: {value, name: 'to'}})}
													renderInput={(params) => <TextField {...params} margin="normal" fullWidth
																															InputLabelProps={{shrink: true}}/>}
												/>
											</LocalizationProvider>
										</Grid>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="CelcomDigi token"
											name="token"
											InputLabelProps={{shrink: true}}
											value={form.token}
											onChange={changeForm}/>
									</Grid>

								</CardContent>
							</Card>

						</Grid>
						<Grid xs={12} md={6}>

							<Card>
								<CardContent>
									<Grid container>
										<Grid xs={12}>
											<TextField
												fullWidth
												multiline
												rows={4}
												type="text"
												margin="normal"
												label="Description internal"
												name="description"
												InputLabelProps={{shrink: true}}
												value={form.description}
												onChange={changeForm}/>

											<TextField
												fullWidth
												multiline
												rows={4}
												type="text"
												margin="normal"
												label="Description public"
												name="descriptionPublic"
												InputLabelProps={{shrink: true}}
												value={form.descriptionPublic}
												onChange={changeForm}/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>

						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<Card>
								<CardContent>
									<Grid container>
										<Grid xs={12}>
											<FormGroup component="fieldset">
												<FormControlLabel
													control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
													label="is active"/>
												<FormControlLabel
													control={<Switch checked={form.isInternal} name={'isInternal'} onChange={changeSwitch}/>}
													label="is internal use"/>
												<FormControlLabel
													control={<Switch checked={form.isRecommended} name={'isRecommended'}
																					 onChange={changeSwitch}/>}
													label="is recommended"/>
											</FormGroup>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid xs={12} md={6}>
							<Card>
								<CardContent>
									<Grid container>
										<Grid xs={12}>
											<FormGroup component="fieldset">
												<FormControlLabel
													control={<Switch checked={isUsingDays} onChange={(e, v) => setIsUsingDays(v)}/>}
													label="Set duration using days"/>
											</FormGroup>

											{
												isUsingDays ? (
													<TextField
														required
														fullWidth
														type="number"
														margin="normal"
														label={'Duration in days'}
														name="days"
														InputLabelProps={{shrink: true}}
														error={!_.isEmpty(form.daysErr)}
														helperText={form.daysErr}
														value={form.days}
														onChange={changeForm}
														InputProps={{endAdornment: <InputAdornment position="end">days</InputAdornment>}}
													/>
												) : (
													<>
														<TextField
															required
															fullWidth
															disabled={form.isAnnually}
															type="number"
															margin="normal"
															label={'Duration'}
															name="intervals"
															InputLabelProps={{shrink: true}}
															error={!_.isEmpty(form.intervalsErr)}
															helperText={form.intervalsErr}
															value={form.intervals}
															onChange={changeForm}
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">{form.isAnnually ? 'year' : 'months'}</InputAdornment>
																)
															}}
														/>
														<FormGroup component="fieldset">
															<FormControlLabel
																control={<Switch checked={form.isAnnually} name={'isAnnually'}
																								 onChange={changeSwitch}/>}
																label="is anually"/>
														</FormGroup>
													</>
												)
											}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>

				</Box>

			</Box>
		</StyledDiv>
	)
}
