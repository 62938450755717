import React from 'react'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'

const StyledBox = styled(Box)`

`

export default function Tab2(props) {

	const {form, changeForm, onSubmit} = props

	return (
		<StyledBox sx={{p: 2}}>
			<Box component={'form'} onSubmit={onSubmit}>

				<button style={{display: 'none'}} type={'submit'}></button>

				<Grid container spacing={2}>
					<Grid xs={12} sm={4}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Order prefix"
							variant="outlined"
							value={form.orderPrefix}
							name={'orderPrefix'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
					<Grid xs={12} sm={8}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Merchant ID"
							variant="outlined"
							value={form.merchantId}
							name={'merchantId'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
					<Grid xs={12} sm={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Merchant payment endpoint"
							variant="outlined"
							value={form.merchantPaymentUrl}
							name={'merchantPaymentUrl'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
					<Grid xs={12} sm={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Merchant proxy payment endpoint"
							variant="outlined"
							value={form.merchantProxyDomain}
							name={'merchantProxyDomain'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid xs={12} sm={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Merchant verify key"
							variant="outlined"
							value={form.merchantVerifyKey}
							name={'merchantVerifyKey'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
					<Grid xs={12} sm={6}>
						<TextField
							fullWidth
							margin={'normal'}
							label="Merchant secret key"
							variant="outlined"
							value={form.merchantSecretKey}
							name={'merchantSecretKey'}
							InputLabelProps={{shrink: true}}
							onChange={changeForm}
						/>
					</Grid>
				</Grid>

			</Box>
		</StyledBox>
	)

}