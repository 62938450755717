import React from 'react'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Alert from '@mui/material/Alert'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    
    .tablerow {
    	cursor: pointer;
    }    
  `}
`

export default function Tab2(props) {

	const {coupon} = props
	const navigate = useNavigate()
	return (
		<StyledDiv>
			{
				_.isEmpty(coupon.Usedby) ? (
					<Alert severity={'info'} sx={{ml: 2, mr: 2}}>No used by records found for this coupon code</Alert>
				) : (
					<TableContainer>
						<Table>
							<TableHead className="tblHead">
								<TableRow>
									<TableCell align="left" width={10}>#</TableCell>
									<TableCell align="left" width={120}>Email</TableCell>
									<TableCell align={'right'} width={200}>Coupon code claim</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
										coupon.Usedby.map((row, index) => {
										return (
											<TableRow
												hover
												className={'tablerow'}
												onClick={() => navigate(`/profiles/${row.Profile.accountsId}`)}
												key={index}>
												<TableCell width={10}>
													{index + 1}
												</TableCell>
												<TableCell width={120}>
													{row.Profile.Account.email}
												</TableCell>
												<TableCell align={'right'} width={200}>
													{dayjs(row.createdAt).format('DD/MM/YYYY h:mm A')}
												</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				)
			}
		</StyledDiv>
	)
}
