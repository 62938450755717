import {createSlice} from '@reduxjs/toolkit'

export const credentialSlice = createSlice({
	name: 'credentials',
	initialState: {
		credentials: [],
		page: 0,
		rowsPerPage: 10,
		count: 0
	},
	reducers: {
		setCredentials: (state, action) => {
			state.credentials = action.payload
		},
		storePage: (state, action) => {
			state.page = action.payload
		},
		storeRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		}
	}
})

export const {setCredentials, storePage, storeRowsPerPage} = credentialSlice.actions

export default credentialSlice.reducer
