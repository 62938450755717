import {styled, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {setLoading, setSnackbarMsg} from "../../store/reducers/misc";
import {addPaymentmethod, getPaymentGroups} from "../../lib/request";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PanelHead from "../../components/panel-head";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Create() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [groups, setGroups]= useState([])
    const [form, setForm] = useState({
        id: null,
        group: '',
        groupId: groups.length === 1 ? groups[0].id : '',
        name: '',
        channel_name: '',
        channel_filename: '',
        channel_parameter: '',
        image: '',
        order: '',
        isActive: false,
        isEnabled: false,
        isAutoRenewal: false,
        isDcb: false,
        groupErr: '',
        channelNameErr: '',
        channelFilenameErr: '',
        channelParameterErr: '',

    })

    useEffect(() => {
        if (_.isEmpty(groups)) {
            getPaymentGroups({limit:100})
                .then((res) => {
                    setGroups(res.data.rows)
                    if (res.data.rows.length >= 1) {
                        const formClone = _.cloneDeep(form)
                        formClone.groupId = res.data.rows[0].id
                        setForm(formClone)
                    }
                })
                .catch(err => console.log(err.response.data.message))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[groups])

    function changeForm(e) {
        const { name, value } = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = value

        if(name === 'groupId') {
            const selectedGroup = groups.find(group => group.id === value)
            formClone.group = selectedGroup ? selectedGroup.name : ''
        }

        formClone.groupErr = ''
        formClone.channelNameErr = ''
        formClone.channelFilenameErr = ''
        formClone.channelParameterErr = ''
        setForm(formClone)
    }

    function changeSwitch(e, v) {
        const {name} = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = v
        setForm(formClone)
    }

    function submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.groupId))) {
            formClone.groupErr = 'Group is required'
            error = true
        } else {
            const selectedGroup = groups.find(group => group.id === form.groupId)
            formClone.group = selectedGroup ? selectedGroup.name : ''
        }

        if (_.isEmpty(_.trim(form.channel_name))) {
            formClone.channelNameErr = 'channel name is required'
            error = true
        }

        if (_.isEmpty(_.trim(form.channel_filename))) {
            formClone.channelFilenameErr = 'channel filename is required'
            error = true
        }

        if (_.isEmpty(_.trim(form.channel_parameter))) {
            formClone.channelParameterErr = 'channel parameter is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        addPaymentmethod(form)
            .then(() => {
                dispatch(setSnackbarMsg('Payment method created'))
                navigate('/payment-methods')
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }


    return (
        <StyledDiv>
            <PanelHead title={`New Payment method`}>
                <Link to={'/payment-methods?tab=0'}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
                        Back
                    </Button>
                </Link>
                <Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
                    Save
                </Button>
            </PanelHead>
            <Box sx={{p: 2}}>
                <Box component="form" noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                select
                                fullWidth
                                required
                                margin={'normal'}
                                label="Group"
                                variant="outlined"
                                value={form.groupId}
                                name={'groupId'}
                                error={!_.isEmpty(form.groupErr)}
                                InputLabelProps={{shrink: true}}
                                onChange={changeForm}
                                helperText={!_.isEmpty(form.groupErr) ? form.groupErr : ''}>
                                {
                                    groups.map((g) => (
                                        <MenuItem key={g.id} value={g.id}>{g.label}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                margin={'normal'}
                                label="Display name"
                                variant="outlined"
                                value={form.name}
                                name={'name'}
                                InputLabelProps={{shrink: true}}
                                onChange={changeForm}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                required
                                margin={'normal'}
                                label="Channel name"
                                variant="outlined"
                                value={form.channel_name}
                                name={'channel_name'}
                                error={!_.isEmpty(form.channelNameErr)}
                                InputLabelProps={{shrink: true}}
                                onChange={changeForm}
                                helperText={!_.isEmpty(form.channelNameErr) ? form.channelNameErr : ''}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                required
                                margin={'normal'}
                                label="Channel filename"
                                variant="outlined"
                                value={form.channel_filename}
                                name={'channel_filename'}
                                error={!_.isEmpty(form.channelFilenameErr)}
                                InputLabelProps={{shrink: true}}
                                onChange={changeForm}
                                helperText={!_.isEmpty(form.channelFilenameErr) ? form.channelFilenameErr : ''}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                required
                                margin={'normal'}
                                label="Channel parameter"
                                variant="outlined"
                                value={form.channel_parameter}
                                name={'channel_parameter'}
                                error={!_.isEmpty(form.channelParameterErr)}
                                InputLabelProps={{ shrink: true }}
                                onChange={changeForm}
                                helperText={!_.isEmpty(form.channelParameterErr) ? form.channelParameterErr : ''}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                margin={'normal'}
                                label="Order"
                                variant="outlined"
                                value={form.order}
                                name={'order'}
                                InputLabelProps={{ shrink: true }}
                                onChange={changeForm}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                fullWidth
                                margin={'normal'}
                                label="Image url"
                                variant="outlined"
                                value={form.image}
                                name={'image'}
                                InputLabelProps={{shrink: true}}
                                onChange={changeForm}
                            />
                        </Grid>
                        <Grid xs={12} sm={3} item>
                            <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                <FormControlLabel
                                    control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
                                    label="is active"/>
                            </FormGroup>
                        </Grid>
                        <Grid xs={12} sm={3} item>
                            <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                <FormControlLabel
                                    control={<Switch checked={form.isEnabled} name={'isEnabled'} onChange={changeSwitch}/>}
                                    label="is enabled"/>
                            </FormGroup>
                        </Grid>
                        <Grid xs={12} sm={3} item>
                            <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                <FormControlLabel
                                    control={<Switch checked={form.isAutoRenewal} name={'isAutoRenewal'} onChange={changeSwitch}/>}
                                    label="is auto renewal"/>
                            </FormGroup>
                        </Grid>
                        <Grid xs={12} sm={3} item>
                            <FormGroup component="fieldset" sx={{mt: 3, mb: 3}}>
                                <FormControlLabel
                                    control={<Switch checked={form.isDcb} name={'isDcb'} onChange={changeSwitch}/>}
                                    label="is Direct Carrier Billing"/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </StyledDiv>
    )
}
