import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        events: null,
        dropdown: [],
        tabIndex: 0
    },
    reducers: {
        setEvents: (state, action) => {
            state.events = action.payload
        },
        setTabIndex: (state, action) => {
            state.tabIndex = _.isInteger(action.payload) ? action.payload : 0
        },
        updateEventDropdown: (state, action) => {
            state.dropdown = []
            action.payload.forEach((s) => {
                state.dropdown.push(_.pick(s, ['id', 'title']))
            })
        },
    }
})

export const { setEvents, setTabIndex, updateEventDropdown } = eventSlice.actions

export default eventSlice.reducer
