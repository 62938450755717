import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

const StyledDiv = styled(Box)`
  ${({theme}) => `
		padding: ${theme.spacing(2)};    
  `}
`

export default function Tab1(props) {
    const { coupon } = props

	return (
		<StyledDiv>
			<Grid container spacing={2}>
				<Grid xs={12} sm={12} md={6} lg={6}>
					<Paper sx={{p: 2}} variant={'outlined'}>
						<Typography variant={'h6'} sx={{pb: 2}}>Coupon information</Typography>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Group name'}
							defaultValue={coupon.Coupons.name}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
						<TextField
							fullWidth
							margin={'normal'}
							label={'Coupon created at '}
							defaultValue={dayjs(coupon.Coupons.createdAt).format('DD MMMM YYYY h:mm A')}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
                        <TextField
							fullWidth
							margin={'normal'}
							label={'Coupon is Active'}
							defaultValue={coupon.Coupons.isActive? 'Yes, Active' : 'Not Active'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
                        <TextField
							fullWidth
							margin={'normal'}
							label={'Coupon is Generic'}
							defaultValue={coupon.Coupons.isGeneric? 'Yes, Generic' : 'Not Generic'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
                        <TextField
							fullWidth
							margin={'normal'}
							label={'Coupon value'}
							defaultValue={coupon.Coupons.value}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
                        <TextField
							fullWidth
							margin={'normal'}
							label={'From'}
							defaultValue={dayjs(coupon.Coupons.from).format('DD MMMM YYYY h:mm A')}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
                        <TextField
							fullWidth
							margin={'normal'}
							label={'to'}
							defaultValue={dayjs(coupon.Coupons.to).format('DD MMMM YYYY h:mm A')}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
                        />
					</Paper>
				</Grid>
				<Grid xs={12} sm={12} md={6} lg={6}>
					<Paper sx={{p: 2}} variant={'outlined'}>
						<Typography variant={'h6'} sx={{pb: 2}}>Others information</Typography>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Brand'}
							defaultValue={coupon.Coupons.Brands.name}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						{_.isEmpty(coupon.Coupons.Subscriptionplans) ? (
							<Alert severity={'info'} sx={{ mt: 1}}>No plan records found for this coupon code</Alert>
						) : (
							<TextField
								fullWidth
								margin={'normal'}
								label={'Subscription Plan'}
								defaultValue={coupon.Coupons.Subscriptionplans.name}
								InputLabelProps={{ shrink: true }}
								InputProps={{ readOnly: true }}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
		</StyledDiv>
	)
}
