import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import _ from 'lodash'
import dayjs from 'dayjs'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {TextField} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Unstable_Grid2'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {editEticketEvents} from '../../../lib/request'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Tab1(props) {

	const {organizerDropdown, event, beginSubmit, setBeginSubmit} = props

	const {id} = useParams()
	const dispatch = useDispatch()
	const {me, brandInFocus} = useSelector((state) => state.profile)

	useEffect(() => {

		const formClone = _.cloneDeep(form)
		_.forEach(event, (v,k) => {
			const isDayJs = ['date', 'time', 'activeDatetime', 'deactiveDatetime']
			if (isDayJs.indexOf(k) !== -1) {
				const vd = k !== 'time' ? dayjs(v) : dayjs(v, 'HH:mm:ss')
				formClone[k] = vd.isValid()? vd : ''
			} else {
				formClone[k] = _.isNull(v) ? '' : v
			}
		})
		setForm(formClone)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event])

	useEffect(() => {
		if (beginSubmit === true) {
			setBeginSubmit(false)
			submit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beginSubmit])

	const [brands] = useState(!_.isEmpty(me['Brands']) ? me['Brands'] : [])
	const [form, setForm] = useState({
		title: '',
		description: '',
		date: '',
		time: '',
		location: '',
		locationLatLong: '',
		tags: '',
		fb: '',
		twitter: '',
		ig: '',
		tiktok: '',
		tnc: '',
		isActive: true,
		isHighTraffic: true,
		activeDatetime: '',
		deactiveDatetime: '',
		orderPrefix: '',
		organizerId: '',
		titleErr: '',
		dateErr: '',
		timeErr: '',
		locationErr: '',
		activeDatetimeErr: '',
		deactiveDatetimeErr: '',
		organizerIdErr: '',
		brandId: brandInFocus,
		brandIdErr: '',
		maxTicket: '',
		maxTicketErr: '',
		staffDiscount: 0,
		partnerDiscount: 0,
		userDiscount: 0,
		staffDiscountErr: '',
		partnerDiscountErr: '',
		userDiscountErr: '',
	})


	function changeForm(e) {
		const { name, value } = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		if (!_.isEmpty(value) && (name === 'maxTicket')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'staffDiscount')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'partnerDiscount')) {
			formClone[name] = parseInt(value)
		}

		if (!_.isEmpty(value) && (name === 'userDiscount')) {
			formClone[name] = parseInt(value)
		}

		formClone.titleErr = ''
		formClone.dateErr = ''
		formClone.timeErr = ''
		formClone.locationErr = ''
		formClone.activeDatetimeErr = ''
		formClone.deactiveDatetimeErr = ''
		formClone.organizerIdErr = ''
		formClone.maxTicketErr = ''
		formClone.staffDiscountErr = ''
		formClone.partnerDiscountErr = ''
		formClone.userDiscountErr = ''
		setForm(formClone)
	}

	function changeSwitch(e, v) {
		changeForm({ target: { name: e.target.name, value: v } })
	}


	function submit(e) {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		const formClone = _.cloneDeep(form)

		let error = false

		if (_.isEmpty(_.trim(form.title))) {
			formClone.titleErr = 'Title is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.date))) {
			formClone.dateErr = 'Event Date is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.time))) {
			formClone.timeErr = 'Time is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.location))) {
			formClone.locationErr = 'Location is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.activeDatetime))) {
			formClone.activeDatetimeErr = 'Event active date is required'
			error = true
		}
		if (_.isEmpty(_.trim(formClone.deactiveDatetime))) {
			formClone.deactiveDatetimeErr = 'Event deactive date is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.organizerId))) {
			formClone.organizerIdErr = 'Organizer is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.maxTicket))) {
			formClone.maxTicketErr = 'Max ticket value is required'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.staffDiscount))) {
			formClone.staffDiscountErr = 'Staff discount cannot be empty. Put 0 for no discount'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.partnerDiscount))) {
			formClone.partnerDiscountErr = 'Partner discount cannot be empty. Put 0 for no discount'
			error = true
		}

		if (_.isEmpty(_.trim(formClone.userDiscount))) {
			formClone.userDiscountErr = 'User discount cannot be empty. Put 0 for no discount'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		if (!_.isEmpty(formClone.date) && !_.isEmpty(formClone.time)) {
			const dateTime = dayjs(`${formClone.date.format('YYYY-MM-DD')}T${formClone.time.format('HH:mm:ss')}`).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DDTHH:mm:ssZ'); 
			formClone.date = dateTime;
		}
	

		setForm(formClone)
		dispatch(setLoading(true))
	
		editEticketEvents(id, formClone)
			.then(() => dispatch(setSnackbarMsg('Event updated')))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDiv>
			<Box component="form" noValidate onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid xs={12} sm={6}>
						<Card>
							<CardHeader title={'Main details'}/>
							<CardContent>
								<Grid container spacing={2}>
									<Grid xs={12}>
										<FormGroup component="fieldset" sx={{mb: 2}}>
											<FormControlLabel
												control={
													<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>
												}
												label="Event is active to be view/buy"/>
										</FormGroup>

										<TextField
											required
											fullWidth
											type="text"
											margin="normal"
											label="Title"
											name="title"
											InputLabelProps={{shrink: true}}
											error={!_.isEmpty(form.titleErr)}
											helperText={form.titleErr}
											value={form.title}
											onChange={changeForm}/>
										<TextField
											multiline
											rows={3}
											label="Description"
											name="description"
											fullWidth
											margin="normal"
											value={form.description}
											InputLabelProps={{shrink: true}}
											onChange={changeForm}/>

									</Grid>
									<Grid xs={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Brand"
											name="brandId"
											select
											InputLabelProps={{shrink: true}}
											InputProps={{readOnly: true}}
											value={form.brandId}
											onChange={changeForm}>
											{
												brands.map((org) => (
													<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
												))
											}
										</TextField>
									</Grid>
									<Grid xs={6}>
										<TextField
											required
											fullWidth
											type="text"
											margin="normal"
											label="Organizer"
											name="organizerId"
											select
											InputLabelProps={{shrink: true}}
											error={!_.isEmpty(form.organizerIdErr)}
											helperText={form.organizerIdErr}
											value={form.organizerId}
											onChange={changeForm}>
											{
												organizerDropdown.map((org) => (
													<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
												))
											}
										</TextField>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											label="Location"
											name="location"
											fullWidth
											margin="normal"
											value={form.location}
											error={!_.isEmpty(form.locationErr)}
											helperText={form.locationErr}
											InputLabelProps={{shrink: true}}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											label="Location Latitude & Longitude"
											name="locationLatLong"
											fullWidth
											margin="normal"
											value={form.locationLatLong}
											InputLabelProps={{shrink: true}}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											label="Tags"
											name="tags"
											fullWidth
											margin="normal"
											value={form.tags}
											InputLabelProps={{shrink: true}}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Order Prefix"
											name="orderPrefix"
											value={form.orderPrefix}
											onChange={changeForm}
											InputLabelProps={{shrink: true}}
										/>
									</Grid>
									<Grid xs={12}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="TnC URL"
											name="tnc"
											value={form.tnc}
											onChange={changeForm}
											InputLabelProps={{shrink: true}}
										/>
									</Grid>
									<Grid xs={12} md={6}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
											    views={['year', 'month', 'day']}
												disablePast
												label="Event date"
												value={form.date}
												error={!_.isEmpty(form.dateErr)}
												helperText={form.dateErr}
												inputFormat="DD/MM/YYYY"
												onChange={(value) => changeForm({target: {value, name: 'date'}})}
												renderInput={(params) => (
													<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid xs={12} md={6}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												label="Event time"
												value={form.time}
												error={!_.isEmpty(form.timeErr)}
												helperText={form.timeErr}
												inputFormat="hh:mm a"
												onChange={(value) => changeForm({target: {value, name: 'time'}})}
												renderInput={(params) => (
													<TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}}/>
												)}/>
										</LocalizationProvider>
									</Grid>
								</Grid>

							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} sm={6}>
						<Card sx={{mb: 2}}>
							<CardHeader title={'Ticketing detail'}/>
							<CardContent>
								<Grid container spacing={2}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Grid xs={12} md={6}>
											<DateTimePicker
												label="Available date time"
												value={form.activeDatetime}
												inputFormat="DD/MM/YYYY hh:mm a"
												onChange={(value) => changeForm({target: {value, name: 'activeDatetime'}})}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														margin="normal"
														InputLabelProps={{shrink: true}}
														helperText={'Date and time when the ticket available to be purchase'}
													/>
												)}
											/>
										</Grid>
										<Grid xs={12} md={6}>
											<DateTimePicker
												minDate={dayjs.isDayjs(form.activeDatetime) ? form.activeDatetime.clone().add(1, 'd') : dayjs()}
												label="End availability date time"
												value={form.deactiveDatetime}
												inputFormat="DD/MM/YYYY hh:mm a"
												onChange={(value) => changeForm({ target: { value, name: 'deactiveDatetime' } })}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														margin="normal"
														InputLabelProps={{ shrink: true }}
														helperText={'Date and time when ticket not available to be purchase'}
													/>
												)}
											/>
										</Grid>
									</LocalizationProvider>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Max Ticket Per User"
											name="maxTicket"
											error={!_.isEmpty(form.maxTicketErr)}
											value={form.maxTicket}
											onChange={changeForm}
											InputLabelProps={{ shrink: true }}
											helperText={!_.isEmpty(form.maxTicketErr) ? form.maxTicketErr : 'Max ticket value user can purchase'}
										/>
									</Grid>
									<Grid xs={12} md={6}>
										<FormGroup component="fieldset" sx={{ mt: 3, mb: 3 }}>
											<FormControlLabel
												control={
													<Switch checked={form.isHighTraffic} name={'isHighTraffic'} onChange={changeSwitch} />
												}
												label="Expected to be high traffic" />
										</FormGroup>
									</Grid>

								</Grid>
							</CardContent>
						</Card>
						<Card>
							<CardHeader title={'Media socials'}/>
							<CardContent>
								<Grid container spacing={2}>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Facebook URL"
											name="fb"
											InputLabelProps={{shrink: true}}
											value={form.fb}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Twitter URL"
											name="twitter"
											InputLabelProps={{shrink: true}}
											value={form.twitter}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Instagram URL"
											name="ig"
											InputLabelProps={{shrink: true}}
											value={form.ig}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="tiktok URL"
											name="tiktok"
											InputLabelProps={{shrink: true}}
											value={form.tiktok}
											onChange={changeForm}/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Card sx={{mt:2}}>
							<CardHeader title={'Discounts'}/>
							<CardContent>
								<Grid container spacing={2}>
								<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="User Discount"
											name="userDiscount"
											error={!_.isEmpty(form.userDiscountErr)}
											helperText={form.userDiscountErr}
											InputLabelProps={{shrink: true}}
											value={form.userDiscount}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Staff Discount"
											name="staffDiscount"
											error={!_.isEmpty(form.staffDiscountErr)}
											helperText={form.staffDiscountErr}
											InputLabelProps={{shrink: true}}
											value={form.staffDiscount}
											onChange={changeForm}/>
									</Grid>
									<Grid xs={12} md={6}>
										<TextField
											fullWidth
											type="text"
											margin="normal"
											label="Partner Discount/Special Guest Discount"
											name="partnerDiscount"
											error={!_.isEmpty(form.partnerDiscountErr)}
											helperText={form.partnerDiscountErr}
											InputLabelProps={{shrink: true}}
											value={form.partnerDiscount}
											onChange={changeForm}/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</StyledDiv>
	)
}
