import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Unstable_Grid2";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";

import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import PanelHead from "../../components/panel-head";
import { ssoInstance } from '../../lib/request'
import {setLoading, setSnackbarMsg} from "../../store/reducers/misc";

const StyledDiv = styled(Box)`
`

export default function Account(props) {

  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const {me} = useSelector(state => state.profile)

  const [form, setForm] = useState({
    oldPassword: '',
    oldPasswordErr: '',
    password: '',
    passwordErr: '',
    passwordConfirm: '',
    passwordConfirmErr: ''
  })

  function updateForm (e) {
    const {name, value} = e.target

    const formClone = _.cloneDeep(form)
    formClone[name] = value

    formClone.oldPasswordErr = ''
    formClone.passwordErr = ''
    formClone.passwordConfirmErr = ''

    setForm(formClone)
  }

  function save (e) {
    e.preventDefault()
    e.stopPropagation()

    const formClone = _.cloneDeep(form)
    let err = false

    if (_.isEmpty(_.trim(form.oldPassword)) && _.isEmpty(_.trim(form.password)) && _.isEmpty(_.trim(form.passwordConfirm))) {
      return
    }

    if (_.isEmpty(_.trim(form.oldPassword))) {
      err = true
      formClone.oldPasswordErr = 'Your current password is required'
    }

    if (_.isEmpty(_.trim(form.password))) {
      err = true
      formClone.passwordErr = 'Your new password is required'
    } else if (form.password.length < 8) {
      err = true
      formClone.passwordErr = 'Your new password must be at least 8 characters'
    }
    if (_.isEmpty(_.trim(form.passwordConfirm))) {
      err = true
      formClone.passwordConfirmErr = 'Your new password confirmation is required'
    }

    if (err) {
      return setForm(formClone)
    }

    dispatch(setLoading(true))
    ssoInstance.post('/me', formClone)
      .then(() => dispatch(setSnackbarMsg('Your password have been change')))
      .catch(err => {
        if (err.response) {
          dispatch(setSnackbarMsg(err.response.data.message))
        } else {
          dispatch(setSnackbarMsg('There is an error'))
        }
      })
      .finally(() => dispatch(setLoading(false)))

  }

  return (
    <StyledDiv>
      {
        !_.isEmpty(me) && (
          <>
            <PanelHead title={'My account'}>
              <Button variant="outlined" fullWidth={isMobile} onClick={save}>
                Save
              </Button>
            </PanelHead>

            <Box sx={{p: 2}}>
              <Grid container spacing={2}>
                <Grid xs={12} md={5}>
                  <Card variant={"outlined"}>
                    <CardHeader title={'Your detail'} />
                    <CardContent>
                      <TextField
                        fullWidth
                        label={'username'}
                        margin={"normal"}
                        InputLabelProps={{shrink: true}}
                        defaultValue={me.username}
                      />
                      <Alert severity={"info"} sx={{mt: 2}}>
                        Leave password field empty if you don't want to change password
                      </Alert>
                      <form onSubmit={save}>
                        <TextField
                          fullWidth
                          type={'password'}
                          margin={"normal"}
                          InputLabelProps={{shrink: true}}
                          label={'Current password'}
                          name={'oldPassword'}
                          value={form.oldPassword}
                          onChange={updateForm}
                          error={!_.isEmpty(form.oldPasswordErr)}
                          helperText={_.isEmpty(form.oldPasswordErr)? 'If you forget your current password. Ask help from admin' : form.oldPasswordErr}
                        />
                        <TextField
                          fullWidth
                          type={'password'}
                          margin={"normal"}
                          InputLabelProps={{shrink: true}}
                          label={'New password'}
                          name={'password'}
                          value={form.password}
                          onChange={updateForm}
                          error={!_.isEmpty(form.passwordErr)}
                          helperText={_.isEmpty(form.passwordErr)? '' : form.passwordErr}
                        />
                        <TextField
                          fullWidth
                          type={'password'}
                          margin={"normal"}
                          InputLabelProps={{shrink: true}}
                          label={'New password confirmation'}
                          name={'passwordConfirm'}
                          value={form.passwordConfirm}
                          onChange={updateForm}
                          error={!_.isEmpty(form.passwordConfirmErr)}
                          helperText={_.isEmpty(form.passwordConfirmErr)? '' : form.passwordConfirmErr}
                        />
                      </form>
                    </CardContent>
                  </Card>
                  <Card variant={"outlined"} sx={{mt: 2}}>
                    <CardHeader
                      title={'Permission'}
                      subheader={'Hover to view detail'}
                      subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
                    <CardContent>
                      <Alert severity={"info"} sx={{mb: 2}}>
                        Ask admin to change permission
                      </Alert>

                      <Box display={"flex"} justifyContent={"space-between"}>
                        {
                          me.permission.split('').map((p,i) => {
                            if (p === 'C') {
                              return <Tooltip key={i} title={'Create information'} placement="top"><FiberNewOutlinedIcon /></Tooltip>
                            } else if (p === 'R') {
                              return <Tooltip key={i} title={'View information'} placement="top"><AutoStoriesOutlinedIcon /></Tooltip>
                            } else if (p === 'U') {
                              return <Tooltip key={i} title={'Edit information'} placement="top"><ModeEditOutlineOutlinedIcon /></Tooltip>
                            } else if (p === 'D') {
                              return <Tooltip key={i} title={'Delete information'} placement="top"><DeleteOutlinedIcon /></Tooltip>
                            } else {
                              return <></>
                            }
                          })
                        }
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={12} md={7}>
                  <Card variant={"outlined"}>
                    <CardHeader title={'Audit logs'} />
                    <CardContent>
                      <Alert severity={"info"}>
                        This feature still work in progress
                      </Alert>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </>
        )
      }
    </StyledDiv>
  )
}