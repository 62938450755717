import React, {useState} from 'react'
import _ from 'lodash'
import copy from 'copy-to-clipboard'
import {useDispatch} from 'react-redux'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

import AddNewApplicationDialog from './add-new-application-dialog'
import CredentialDialog from './credential-dialog'

import {setSnackbarMsg} from '../../../store/reducers/misc'

const StyledBox = styled(Box)`
`

export default function Tab3(props) {

	const {applications, credentials, brand} = props

	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const brandApplications = []
	applications.forEach(app => {
		if (brand.id === app.Brands.id) {
			const brandApp = _.cloneDeep(app)
			brandApp.credentials = []
			credentials.forEach(c => {
				if (c.sso_key === brandApp.apiKey) {
					brandApp.credentials.push(c)
				}
			})

			brandApplications.push(brandApp)
		}
	})

	const [addNew, setAddNew] = useState(false)
	const [credentialObj, setCredentialObj] = useState({})

	function copyTxt(e, text, msg) {
		e.preventDefault()
		e.stopPropagation()

		copy(text)
		dispatch(setSnackbarMsg(!_.isEmpty(_.trim(msg)) ? msg : `text copied`))
	}

	function viewCredential(credential, application) {
		const cloneCredential = _.cloneDeep(credential)
		_.forEach(cloneCredential, (v,k) => {
			if (_.isNull(v)) {
				cloneCredential[k] = ''
			}
		})

		cloneCredential.application = application
		setCredentialObj(cloneCredential)
	}

	function beginAddNewCredential(e, application) {
		e.preventDefault()
		e.stopPropagation()

		setCredentialObj({
			application,
			t_name: '',
			ga_id: '',
			name: '',
			client_secret: '',
			redirect_uri: '',
			pay_prefix: '',
			dc_url: '',
			sso_key: '',
			client_id: ''
		})
	}

	return (
		<StyledBox sx={{p: 2}}>
			<Box textAlign={'right'} sx={{mb: 2}}>
				<Button fullWidth={isMobile} variant={'outlined'} onClick={() => setAddNew(true)}>
					Add new application
				</Button>
			</Box>
			{
				brandApplications.map((b, i) => {
					return (
						<Accordion key={i}>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{overflowX: 'hidden'}}>
								<Grid
									container
									spacing={2}
									sx={{width: '100%', maxWidth: isMobile ? 'calc(100vw - 100px)' : 'unset', alignItems: 'center'}}>
									<Grid xs={10}>
										<Typography noWrap>{b.label}</Typography>
										<Typography variant={'caption'} component={'div'} noWrap>{b.apiKey}</Typography>
									</Grid>
									<Grid xs={2}>
										<Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
											<Box sx={{mr: 1}}>
												<Typography variant={'h5'} textAlign={'right'}>{b.credentials.length}</Typography>
											</Box>
											<Box>
												<IconButton onClick={e => beginAddNewCredential(e, b)}>
													<AddBoxOutlinedIcon color={'primary'}/>
												</IconButton>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</AccordionSummary>
							<AccordionDetails sx={{p: 0}}>
								<TableContainer>
									<Table size="small" aria-label="a dense table">
										<TableHead>
											<TableRow>
												<TableCell>Credential name</TableCell>
												<TableCell align="left">Redirect URI</TableCell>
												<TableCell align="center">DC URL</TableCell>
												<TableCell align="right">Template</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												b.credentials.map((row, j) => {
													return (
														<TableRow
															key={j}
															onClick={() => viewCredential(row, b)}
															sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer'}}>
															<TableCell component="th" scope="row">
																<Box sx={{display: 'flex', alignItems: 'center'}}>
																	<ContentCopyOutlinedIcon
																		fontSize={'small'}
																		sx={{mr: 1, cursor: 'pointer'}}
																		onClick={(e) => copyTxt(e, row.client_id, 'Client ID copied')}
																	/>
																	<Box sx={{flexGrow: 1}}>
																		{row.client_id}
																		<br/>
																		<strong>{row.name}</strong>
																	</Box>
																	<ContentCopyOutlinedIcon
																		fontSize={'small'}
																		sx={{mr: 1, cursor: 'pointer'}}
																		onClick={(e) => copyTxt(e, row.client_secret, 'Client Secret copied')}
																	/>
																</Box>
															</TableCell>
															<TableCell align="left">
																<Typography noWrap fontSize={12}>
																	{row.redirect_uri.replace(/,/g, ', ')}
																</Typography>
															</TableCell>
															<TableCell align="center">
																{
																	!_.isEmpty(_.trim(row.dc_url)) ? (
																		<TaskAltOutlinedIcon fontSize={'small'} color={'success'}/>
																	) : ('-')
																}
															</TableCell>
															<TableCell align="right">{row.t_name || ''}</TableCell>
														</TableRow>
													)
												})
											}
										</TableBody>
									</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					)
				})
			}

			{
				addNew && <AddNewApplicationDialog brand={brand} onClose={() => setAddNew(false)}/>
			}
			{
				!_.isEmpty(credentialObj) && (
					<CredentialDialog
						onClose={() => setCredentialObj({})}
						credential={credentialObj}
					/>
				)
			}
		</StyledBox>
	)

}