import PanelHead from "../../components/panel-head";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {styled, useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tab1 from "./tab1"
import Tab2 from "./tab2"


const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
`
export default function PaymentMethod() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const {me} = useSelector(state => state.profile)

    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const tab = urlParams.get('tab')
        if(!_.isEmpty(tab)){
            setTabIndex(parseInt(tab))
        }
    }, [])
    return (
        <StyleDiv>
            <PanelHead title={'Payment Methods'}>
                {
                    !_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 0) && (
                        <Link to={'/payment-methods/create'}>
                            <Button variant="outlined" fullWidth={isMobile}>New Method</Button>
                        </Link>
                    )
                }
                {
                    !_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 1) && (
                        <Link to={'/payment-methods/group/create'}>
                            <Button variant="outlined" fullWidth={isMobile}>New Group</Button>
                        </Link>
                    )
                }
            </PanelHead>
            <Box>
                <Paper square variant={'outlined'}>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, v) => setTabIndex(v)}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example">
                        <Tab label="Methods"/>
                        <Tab label="Groups"/>
                    </Tabs>
                </Paper>
                <Box role={'tabpanel'} hidden={tabIndex !== 0}>
                    { tabIndex === 0 && <Tab1/> }
                </Box>
                <Box role={'tabpanel'} hidden={tabIndex !== 1}>
                    { tabIndex === 1 && <Tab2/> }
                </Box>
            </Box>
        </StyleDiv>
    )
}
