import {useEffect} from 'react'
import Cookies from 'js-cookie'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {
	getProfile,
	getNetworks,
	getAllBrands
} from '../lib/request'
import {initialize} from '../store/reducers/profile'
import {setNetworks} from '../store/reducers/network'
import {setBrands} from '../store/reducers/brand'

const cookieName = '_t'

export default function Base() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {

		const {pathname, search} = window.location

		const qs = {}
		search.replace(/^\?/, '').split('&').forEach(q => {
			const qSplit = q.split('=')
			qs[qSplit[0]] = decodeURIComponent(qSplit[1])
		})

		const token = Cookies.get(cookieName)
		if (!_.isEmpty(token)) {
			getProfile(token)
				.then(response => {
					dispatch(initialize(response.data))
					return getNetworks()
				})
				.then(response => {
					dispatch(setNetworks(response.data))
					return getAllBrands()
				})
				.then(response => {
					dispatch(setBrands(response.data))
				})
				.then(() => {
					if (pathname === '/login') {
						if (!_.isEmpty(qs.to)) {
							navigate(qs.to)
						} else {
							navigate('/')
						}
					}
				})
				.catch(err => {
					if (err.response) {
						const {status} = err.response

						if (status === 401) {
							Cookies.remove(cookieName)

							if (pathname !== '/login') {
								navigate(`/login?to=${encodeURIComponent(pathname)}`)
							}
						}
					} else {
						console.log('Error ', err.toString())
					}
				})
		} else if (pathname !== '/login') {
			navigate(`/login?to=${encodeURIComponent(pathname)}`)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (<></>)
}
