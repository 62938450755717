import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import _ from "lodash"

import { styled , useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import PanelHead from '../../../components/panel-head'

import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import { checkCoupon } from '../../../lib/request'

import Tab1 from './tab1'
import Tab2 from './tab2'


const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function CouponDetail() {
	const dispatch = useDispatch()
	const { code } = useParams()
	const [inLoad, setInLoad] = useState(false)
	const [tabIndex, setTabIndex] = useState(0)
	const [coupon, setCoupon] = useState({})
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	
	useEffect(() => {
	
			const q = {
				code: _.clone(code),
			}
			dispatch(setLoading(true))
			checkCoupon(q)
				.then((res) => {
					setCoupon(res.data)
					setInLoad(true)
				})
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(`Sorry, ${code} is not in our system.`))
					}
				})
				.finally(() => {
					dispatch(setLoading(false))
				})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code])

	return (
		<StyledDiv>
			
				
			<PanelHead title={'Code Coupon Details'} subtitle={code}>
				<Link to={'/coupon'}>
								<Button
									fullWidth={isMobile}
									color={'inherit'}
									variant={isMobile? 'outlined' : 'text'}
									sx={{ mr: isMobile? 0 : 2 }}>
									Back
								</Button>
							</Link>
						</PanelHead>
						{
						inLoad && (
					
						<Box>
							<Paper square variant={'outlined'}>
								<Tabs
									value={tabIndex}
									onChange={(e, v) => setTabIndex(v)}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="scrollable auto tabs example"
								>
									<Tab label="Details"/>
									<Tab label="Used By"/>
								</Tabs>
							</Paper>
							<Box role={'tabpanel'} hidden={tabIndex !== 0}>
								{tabIndex === 0 && <Tab1 coupon={coupon} />}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 1}>
								{tabIndex === 1 && <Tab2 coupon={coupon} />}
							</Box>
						</Box>
					
				)}
			
		</StyledDiv>
	)
}
