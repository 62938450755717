import React from 'react'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Alert from '@mui/material/Alert'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    
    .tblHead th {
			font-weight: bolder;
		}
    
    .tablerow {
    	cursor: pointer;
    }    
  `}
`

export default function Tab6(props) {

	const {profile} = props
	const myProfile = profile.Profile[0]

	const navigate = useNavigate()

	const myCoupons = []

	myProfile.Usecoupons.forEach(c => {
		myCoupons.push({
			codeId: c.Couponcode.id, code: c.Couponcode.code, couponId: c.Couponcode.Coupons.id, couponName: c.Couponcode.Coupons.name, date: c.createdAt
		})
	})

	myProfile.Coupons.forEach(c => {
		const index = _.findIndex(myCoupons, {codeId: c.id})
		if (index === -1) {
			myCoupons.push({
				codeId: c.id, code: c.code, couponId: c.Coupons.id, couponName: c.Coupons.name, date: c.updatedAt
			})
		}
	})
	
	return (
		<StyledDiv>
			{
				_.isEmpty(myProfile.Usecoupons) ? (
					<Alert severity={'info'} sx={{ml: 2, mr: 2}}>No coupons records found for this user</Alert>
				) : (
					<TableContainer>
						<Table>
							<TableHead className="tblHead">
								<TableRow>
									<TableCell align="left" width={10}>#</TableCell>
									<TableCell align="left" width={120}>Code</TableCell>
									<TableCell align="left">Coupon group name</TableCell>
									<TableCell align={'right'} width={200}>Date claim</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									myCoupons.map((row, index) => {
										return (
											<TableRow
												hover
												className={'tablerow'}
												onClick={() => navigate(`/coupon/${row.couponId}`)}
												key={index}>
												<TableCell width={10}>
													{index + 1}
												</TableCell>
												<TableCell width={120}>
													{row.code}
												</TableCell>
												<TableCell align={'left'}>
													{row.couponName}
												</TableCell>
												<TableCell align={'right'} width={200}>
													{dayjs(row.date).format('DD/MM/YYYY h:mm A')}
												</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				)
			}
		</StyledDiv>
	)
}
