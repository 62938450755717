import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"

import {logout} from "../../lib/request"
import {clear} from "../../store/reducers/profile"

export default function Logout() {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        logout()
        dispatch(clear())
        navigate('/login')

    }, [dispatch, navigate])

    return (
        <div></div>
    )

}