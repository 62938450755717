import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput'

import PanelHead from '../../../components/panel-head'
import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import { addEticketEventMeta } from '../../../lib/request'

const StyledDiv = styled(Box)`
  .headerCard{
	    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tblHead th {
    font-weight: bolder;
  }
`

export default function CreateEvent() {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const eventId = useSelector((state) => state.event.events)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form, setForm] = useState({
        type: '',
        value: '',
        eticketEventsId: eventId,
        typeErr: '',
        valueErr: ''
    })



    function changeForm(e) {
        const { name, value } = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = value

        formClone.typeErr = ''
        formClone.valueErr = ''

        setForm(formClone)
    }


    function submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)
        let error = false

        if (_.isEmpty(_.trim(form.type))) {
            formClone.typeErr = 'Please choose type'
            error = true
        }

        if (_.isEmpty(_.trim(form.value))) {
            formClone.valueErr = 'Value is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        addEticketEventMeta(formClone)
            .then((res) => {
                dispatch(setSnackbarMsg('Event Meta created'))
                navigate(`/eticket/event/${eventId}`)
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }

    return (
        <StyledDiv>
            <PanelHead title="Add New Event Meta">
                <Link to={`/eticket/event/${eventId}`}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'} sx={{ mr: isMobile ? 0 : 2 }}>
                        Back
                    </Button>
                </Link>
                <Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{ mt: isMobile ? 1 : 0 }}>
                    Save
                </Button>
            </PanelHead>
            <Box sx={{ p: 2 }}>
                <Box component="form" noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12}>
                            <Card>
                                <CardContent>
                                    <FormControl
                                        fullWidth
                                        margin="normal"
                                        required
                                        error={!_.isEmpty(form.typeErr)}>
                                        <InputLabel id="type">Type</InputLabel>
                                        <Select
                                            required
                                            labelId="type"
                                            name="type"
                                            value={form.type}
                                            onChange={changeForm}
                                            input={< OutlinedInput label="Type" />}>
                                            <MenuItem value='poster'>Poster</MenuItem>
                                            <MenuItem value='thumbnail'>Thumbnail</MenuItem>
                                            <MenuItem value='detail'>Details</MenuItem>
                                            <MenuItem value='seat'>Seats</MenuItem>
                                            <MenuItem value='banner'>Banner</MenuItem>
                                            <MenuItem value='billboard'>Billboard</MenuItem>
                                            <MenuItem value='video'>Video</MenuItem>
                                            <MenuItem value='pdf'>Pdf</MenuItem>
                                            <MenuItem value='slider'>Slider</MenuItem>
                                            <MenuItem value='background'>Background</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Value"
                                        name="value"
                                        required
                                        fullWidth
                                        margin="normal"
                                        value={form.value}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={changeForm} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </StyledDiv >
    )
}
