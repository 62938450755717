import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const StyledDiv = styled(Box)``;

export default function Search(props) {
	const { search, setSearch, beginSearch } = props;

	function changeForm(e) {
		const { name, value } = e.target;
		const searchClone = _.clone(search);
		searchClone[name] = value;
		setSearch(searchClone);
	}


	return (
		<StyledDiv sx={{ pl: 2, pr: 2 }}>
			<form onSubmit={beginSearch}>
				<Grid container spacing={2}>
					<Grid xs={12} sm={6} md={6}>
						<TextField
							fullWidth
							variant={'outlined'}
							size={'small'}
							placeholder={'Email or phone number'}
							name={'text'}
							value={search.text}
							onChange={changeForm}
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4}>
						<TextField
							fullWidth
							select
							label={'Filter by verification status'}
							variant={'outlined'}
							size={'small'}
							name={'verify'}
							value={search.verify}
							onChange={changeForm}
							InputLabelProps={{ shrink: true }}
						>
							<MenuItem value='x'>All</MenuItem>
							<MenuItem value={true}>Verified</MenuItem>
							<MenuItem value={false}>Not Verified</MenuItem>
						</TextField>
					</Grid>
					<Grid xs={12} md={2}>
						<Button
							type={'submit'}
							onClick={beginSearch}
							fullWidth
							variant={'outlined'}
						>
							Search
						</Button>
					</Grid>
				</Grid>
			</form>
		</StyledDiv >
	);
}
