import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'

import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

import {queryREVID} from '../../../lib/request'

const StyledDiv = styled(Box)`
  ${({theme}) => `
		padding: ${theme.spacing(2)};    
  `}
`

export default function Tab3(props) {

	const {profile} = props

	const [revIds, setRevIds] = useState(null)

	useEffect(() => {
		if (profile.revid) {
			queryInformation()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function queryInformation (e) {
		queryREVID(profile.revid)
			.then(response => {
				const {results} = response.data
				if (_.isEmpty(results)) {
					return setRevIds([])
				}
				const revIdsClean = []
				results.forEach(r => {
					const data = []
					_.forOwn(r, (v, k) => {
						if (k === 'revid') {
							return null
						}
						if (k === 'ua') {
							k = 'User Agent'
						}

						if (k === 's' && _.isObject(v)) {
							v = `w=${v.w};w=${v.h};w=${v.d}`
						}

						data.push({k,v})
					})
					revIdsClean.push(data)
				})
				
				setRevIds(revIdsClean)
			})
			.catch(err => {

			})
	}

	return (
		<StyledDiv>
			{
				profile.revid ? (
					<>
						<TextField
							fullWidth
							margin={'normal'}
							label="User REV.ID Hash"
							defaultValue={profile.revid}
							InputProps={{
								readOnly: true,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={queryInformation}>
											<AutoStoriesOutlinedIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
							variant={'standard'}
						/>

						<Divider sx={{mt: 2, mb: 1}} />
						<Typography variant={'h5'} sx={{mb: 2}}>This user REV.ID activity</Typography>

						{
							_.isNull(revIds) && (
								<Alert severity={'info'}>
									Click on the book to fetch the REV.ID information
								</Alert>
							)
						}
						{
							_.isEmpty(revIds) && _.isArray(revIds) && (
								<Alert severity={'warning'}>
									User REV.ID activity is expired. Perhaps re-engage back with the user?
								</Alert>
							)
						}
						{
							!_.isEmpty(revIds) && _.isArray(revIds) && (
								<Grid container spacing={2}>
									{
										revIds.map((r, i) => {
											return (
												<Grid key={i} xs={12} sm={6} md={4} lg={3}>
													<Paper variant={'outlined'}>
														{
															r.map((r2, j) => {
																let origin = ''
																if (r2.k === 'pathname') {
																	r.forEach(rr => {
																		if(rr.k === 'origin') {
																			origin = rr.v
																		}
																	})
																}

																return (
																	<Box key={j}>
																		<Box sx={{pl: 2, pr: 2}}>
																			<Grid container>
																				<Grid xs={4}>
																					<Typography variant={'body1'}>{r2.k}</Typography>
																				</Grid>
																				<Divider orientation={'vertical'} flexItem />
																				<Grid xs>
																					<Typography variant={'body1'} noWrap={r2.k === 'ip'}>
																						{
																							r2.k === 'pathname' ? (
																								<Link to={`${origin}${r2.v}`}>{r2.v}</Link>
																							) : (
																								<span>{r2.v}</span>
																							)
																						}
																					</Typography>
																				</Grid>
																			</Grid>
																		</Box>
																		<Divider />
																	</Box>
																)
															})
														}
													</Paper>
												</Grid>
											)
										})
									}
								</Grid>
							)
						}
					</>
				) : (
					<Alert severity={'warning'}>
						No info about user REV.ID yet
					</Alert>
				)
			}
		</StyledDiv>
	)
}
