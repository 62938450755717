import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery";
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'

import {getAdmins} from '../../lib/request'
import {setLoading} from '../../store/reducers/misc'
import PanelHead from '../../components/panel-head'

const StyleDiv = styled(Box)`
  .tblHead th {
    font-weight: bolder;
  }
`

export default function Admin(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {me} = useSelector(state => state.profile)

	const [admins, setAdmins] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	useEffect(() => {
		getAdmin()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function handleChangePage(e, newPage) {
		setPage(newPage)
	}

	function handleChangeRowsPerPage(e) {
		setRowsPerPage(parseInt(e.target.value, 10))
		setPage(0)
	}

	function nextPage(id) {

		navigate(`/admin/${id}`)
	}

	function getAdmin() {
		dispatch(setLoading(true))
		getAdmins()
			.then(response => setAdmins(response.data))
			.catch(err => {
				if (err.response) {
					console.error(err.response.data.message)
				} else {
					console.error(err.toString())
				}
			})
			.finally(() => {
				dispatch(setLoading(false))
			})
	}
	return (
		<StyleDiv>
			<PanelHead title={'Administrator'}>
				{
					!_.isEmpty(me) && me.permission.includes("C") && (
						<Link to={'/admin/create'}>
							<Button fullWidth={isMobile} variant="outlined">
								Add New Admin
							</Button>
						</Link>
					)
				}
			</PanelHead>

			<TableContainer>
				<Table>
					<TableHead className="tblHead">
						<TableRow>
							<TableCell>#</TableCell>
							<TableCell align="left">Name</TableCell>
							<TableCell align="left">Status</TableCell>
							<TableCell align="left">Brands</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{admins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((admin, index) =>
						(
							<TableRow
								hover
								onClick={() => nextPage(admin.id)}
								sx={{ cursor: 'pointer' }}
								key={index}>
								<TableCell>{index + 1 + (rowsPerPage * page)}</TableCell>
								<TableCell>{admin.username}</TableCell>
								<TableCell>{admin.active ? 'Active' : 'Not Active'}</TableCell>
								<TableCell>
									{
										!_.isEmpty(admin.Brands) && (
											<span>
												{
													admin.Brands.map((brand) => (brand.name)).join(', ')
												}
											</span>
										)
									}
								</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				sx={{mt: 1}}
				component={'div'}
				rowsPerPageOptions={[10, 50, 100]}
				count={admins.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}/>

		</StyleDiv>

	)
}
