import React, {useState} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import dayjs from 'dayjs'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {editProfile} from '../../../lib/request'
import {useSearchParams} from "react-router-dom";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};    
  `}
`

export default function Tab2(props) {

	const {profile} = props
	const myProfile = profile.Profile[0]

	const dispatch = useDispatch()

	const {me} = useSelector(state => state.profile)
	const {brandInFocus} = useSelector(state => state.profile)

	const [form, setForm] = useState({
		id: profile.id,
		name: myProfile.name? _.clone(myProfile.name) : ''
	})

	const [brandId] = useSearchParams()

	const q = { bId: brandInFocus }

	function changeForm (e) {
		const {name, value} = e.target
		const formClone = _.clone(form)
		formClone[name] = value
		setForm(formClone)
	}

	function onSubmit(e) {
		e.preventDefault()
		e.stopPropagation()

		if (!/U/.test(me.permission)) {
			return null
		}

		const payload = _.pick(form, ['id', 'name'])

		dispatch(setLoading(true))
		editProfile(payload.id, payload, q)
			.then(() => dispatch(setSnackbarMsg('Profile Updated')))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))

	}

	return (
		<StyledDiv>
			<Grid container spacing={2}>
				<Grid xs={12} sm={12} md={4} lg={3}>
					<Paper sx={{p: 2}} variant={'outlined'}>
						<form onSubmit={onSubmit}>
							<Typography variant={'h6'} sx={{pb: 2}}>Changeable information</Typography>
							<TextField
								fullWidth
								margin={'normal'}
								name={'name'}
								value={form.name}
								onChange={changeForm}
								label={'Fullname'}
								InputLabelProps={{shrink: true}}
								InputProps={{readOnly: !/U/.test(me.permission)}}
							/>
							{
								profile.isOptOut === false && (
									<Button
										type={'submit'}
										fullWidth sx={{mt: 2}}
										variant={'outlined'}
										disabled={!/U/.test(me.permission)}>
										Save
									</Button>
								)
							}
						</form>
					</Paper>
				</Grid>
				<Grid xs={12} sm={12} md={8} lg={9}>
					<Paper sx={{p: 2}} variant={'outlined'}>
						<Typography variant={'h6'} sx={{pb: 2}}>Profile information</Typography>
						<TextField
							fullWidth
							margin={'normal'}
							label={'DOB'}
							defaultValue={myProfile.dob? dayjs(myProfile.dob).format('DD MMMM YYYY') : '-'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Gender'}
							defaultValue={myProfile.gender? myProfile.gender : '-'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						<TextField
							fullWidth
							margin={'normal'}
							label={'IC No'}
							defaultValue={myProfile.icNo? myProfile.icNo : '-'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Passport No'}
							defaultValue={myProfile.passport? myProfile.passport : '-'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						<TextField
							fullWidth
							margin={'normal'}
							label={'State'}
							defaultValue={myProfile.state? myProfile.state : '-'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
						<TextField
							fullWidth
							margin={'normal'}
							label={'Subscribe to newsletter'}
							defaultValue={myProfile.newsletter? 'Yes' : 'No'}
							InputLabelProps={{shrink: true}}
							InputProps={{readOnly: true}}
						/>
					</Paper>
				</Grid>
			</Grid>
		</StyledDiv>
	)

}
