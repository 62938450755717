import React, {useEffect, useState} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import PanelHead from '../../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {ssoInstance, getProfilebyID, getProfileByRealID} from '../../../lib/request'
import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
import Tab4 from './tab4'
import Tab5 from './tab5'
import Tab6 from './tab6'
import Tab7 from './tab7'
import DialogConfirmDeleteUser from './dialog-confirm-delete-user'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function Detail(props) {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {me, brandInFocus} = useSelector(state => state.profile)

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {id} = useParams()

	const [tabIndex, setTabIndex] = useState(0)
	const [profile, setProfile] = useState({})
	const [inLoad, setInLoad] = useState(true)
	const [showConfirmDelete, setShowConfirmDelete] = useState(false)

	useEffect(() => {
		if (!_.isUndefined(id)) {
			queryProfile(id)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function queryProfile(uId) {
		dispatch(setLoading(true))
		let promise = null
		const qs = {}
		window.location.search.replace(/^\?/,'').split('&').forEach(l => {
			const split2 = l.split('=')
			qs[split2[0]] = decodeURIComponent(split2[1])
		})

		if(_.isEmpty(_.trim(qs.isProfileId))) {
			promise = getProfilebyID(uId, {bId: brandInFocus})
		} else {
			promise = getProfileByRealID(uId, qs.profileId)
		}

		promise.then((res) => {
			setProfile(res.data)
			setInLoad(false)
		})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function deleteAccount () {

		if (!_.isUndefined(id)) {
			dispatch(setLoading(true))
			ssoInstance.delete(`/profile/${id}`)
				.then(() => {
					dispatch(setLoading(false))
					navigate('/profiles')
				})
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.message))
					}
				})
				.finally(() => dispatch(setLoading(false)))
		}
	}

	return (
		<StyledDiv>
			{
				inLoad ? (
					<Alert severity={'info'}>Please wait. Fetching plan information</Alert>
				) : (
					<>
						<PanelHead title={profile.email} subtitle={profile.phone}>
							<Link to={'/profiles'}>
								<Button
									fullWidth={isMobile}
									color={'inherit'}
									variant={isMobile ? 'outlined' : 'text'}
									sx={{mr: isMobile ? 0 : 2}}>
									Back
								</Button>
							</Link>
							{
								profile.isOptOut === false && /D/.test(me.permission) && (
									<Button
										fullWidth={isMobile}
										color={'error'}
										variant={'contained'}
										onClick={() => setShowConfirmDelete(true)}>
										Delete
									</Button>
								)
							}
						</PanelHead>
						<Box>
							<Paper square variant={'outlined'}>
								<Tabs
									value={tabIndex}
									onChange={(e, v) => setTabIndex(v)}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="scrollable auto tabs example">
									<Tab label="Account"/>
									<Tab label="Profile"/>
									<Tab label="REV.ID"/>
									<Tab label="Subscription"/>
									<Tab label="Payments"/>
									<Tab label="Coupons"/>
									<Tab label="Audit"/>
								</Tabs>
							</Paper>
							<Box role={'tabpanel'} hidden={tabIndex !== 0}>
								{tabIndex === 0 && <Tab1 profile={profile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 1}>
								{tabIndex === 1 && <Tab2 profile={profile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 2}>
								{tabIndex === 2 && <Tab3 profile={profile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 3}>
								{tabIndex === 3 && <Tab4 profile={profile} queryProfile={queryProfile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 4}>
								{tabIndex === 4 && <Tab5 profile={profile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 5}>
								{tabIndex === 5 && <Tab6 profile={profile}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 6}>
								{tabIndex === 6 && <Tab7 profile={profile}/>}
							</Box>
						</Box>
						{
							showConfirmDelete && <DialogConfirmDeleteUser onClose={() => setShowConfirmDelete(false)} onConfirm={deleteAccount} />
						}
					</>
				)
			}
		</StyledDiv>
	)
}
