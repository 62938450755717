import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import _ from 'lodash'

import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import {useTheme, styled} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

import acl from '../lib/acl'

const StyledDrawer = styled(Drawer)`
  a {
    text-decoration: none;
    color: inherit;
  }
`

export default function Sidebar(props) {
	const {width, open, setOpen} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {me} = useSelector(state => state.profile)
	const {pathname: p} = useLocation()

	const [navs, setNavs] = useState([{
		to: '/',
		active: new RegExp(/^\/$/),
		icon: <HomeOutlinedIcon/>,
		label: 'Homepage'
	}])
	
	useEffect(() => {

		const navsClone = _.cloneDeep(navs)

		if (!_.isEmpty(me)) {
			acl.urls.forEach(ua => {
				if (me.module.indexOf(ua.domain) !== -1) {
					navsClone.push(ua.menu)
				}
			})
		}

		setNavs(_.uniqBy(navsClone, 'label'))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])

	return (
		<StyledDrawer
			variant={isMobile ? 'temporary' : 'permanent'}
			open={isMobile ? open : true}
			sx={{[`& .MuiDrawer-paper`]: {width}}}
			onClose={() => setOpen(false)}
		>
			<Toolbar>
				<IconButton color="inherit" onClick={() => setOpen(false)} edge="start">
					<MenuIcon/>
				</IconButton>
				<Link to="/">
					<Typography color={'#000'} variant="h6" noWrap>
						SSO Dashboard
					</Typography>
				</Link>
			</Toolbar>
			<Box>
				<List>
					{
						navs.map((n, i) => {
							return (
								<Box key={i}>
									<Link to={n.to}>
										<ListItem disablePadding>
											<ListItemButton selected={n.active.test(p)}>
												<ListItemIcon>{n.icon}</ListItemIcon>
												<ListItemText primary={n.label}/>
											</ListItemButton>
										</ListItem>
									</Link>
									{
										navs.length - 1 !== i && <Divider/>
									}
								</Box>
							)
						})
					}
				</List>
			</Box>
		</StyledDrawer>
	)
}
