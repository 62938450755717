import React from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'

import { setSnackbarMsg } from '../store/reducers/misc'

export default function Snackbars(props) {

	const dispatch = useDispatch()
	const misc = useSelector((state) => state.misc)
	
	return (
		<Box>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				open={!_.isEmpty(_.trim(misc.snackbarmsg))}
				autoHideDuration={6000}
				onClose={() => dispatch(setSnackbarMsg(''))}
				message={misc.snackbarmsg}
			/>
		</Box>
	)
}
