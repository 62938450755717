import React, {useState} from 'react'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {Button, TablePagination} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import useMediaQuery from '@mui/material/useMediaQuery'

import PanelHead from '../../components/panel-head'
import AddBrandDialog from './add-brand-dialog'

const StyleDiv = styled(Box)`
  .tablehead th {
    font-weight: bolder;
  }
`

export default function Brand() {

	const navigate = useNavigate()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const brands = useSelector((state) => state.brand.brands)
	const {me} = useSelector(state => state.profile)

	const [openForm, setOpenForm] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	function handleChangePage(e, newPage) {
		setPage(newPage)
	}

	function handleChangeRowsPerPage(e) {
		setRowsPerPage(parseInt(e.target.value, 10))
		setPage(0)
	}

	return (
		<StyleDiv>

			<PanelHead title={'Brands'}>
				{
					!_.isEmpty(me) && me.permission.includes('C') && (
						<Button variant="outlined" fullWidth={isMobile} onClick={() => setOpenForm(true)}>
							Add brand
						</Button>
					)
				}
			</PanelHead>

			<TableContainer>
				<Table aria-label="simple table">
					<TableHead className={'tablehead'}>
						<TableRow>
							<TableCell align={'center'} width={30}>#</TableCell>
							<TableCell align="left">Name</TableCell>
							<TableCell align="right">Networks</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							brands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((brand, index) =>
								<TableRow
									hover
									onClick={() => navigate(`/brand/${brand.id}`)}
									sx={{cursor: 'pointer'}}
									key={index}>
									<TableCell align={'center'} width={30}>{index + 1}</TableCell>
									<TableCell align="left">{brand.name}</TableCell>
									<TableCell align={'right'}>
										{
											brand.Networks.map((network) => network.name).join(', ')
										}
									</TableCell>
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				sx={{mt: 1}}
				rowsPerPageOptions={[10, 50, 100]}
				component="div"
				count={brands.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}/>
			{
				openForm && <AddBrandDialog close={() => setOpenForm(false)}/>
			}
		</StyleDiv>
	)
}
