import React, {useState} from 'react'
import _ from 'lodash'
import {useDispatch} from 'react-redux'

import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'

import {addApplication} from '../../lib/request'
import {createApplication} from '../../store/reducers/application'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function Form(props) {
	const {onClose, brands} = props

	const dispatch = useDispatch()

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [form, setForm] = useState({
		label: '',
		labelErr: null,
		brandId: '',
		brandIdErr: null
	})

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value
		// Clear error state
		formClone.labelErr = null
		formClone.brandIdErr = null
		setForm(formClone)
	}

	function handleSubmit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)
		let error = false

		if (_.isEmpty(_.trim(form.label))) {
			formClone.labelErr = 'Label is required'
			error = true
		}
		if (_.isEmpty(_.trim(form.brandId))) {
			formClone.brandIdErr = 'Brand is required'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		dispatch(setLoading(true))
		addApplication(formClone)
			.then((res) => {
				dispatch(createApplication(res.data))
				dispatch(setSnackbarMsg('Application credentails created'))
				onClose()
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				} else {
					console.error(err.toString())
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<Dialog open onClose={onClose} maxWidth={'md'} fullWidth fullScreen={isMobile}>
			<DialogTitle>Create new application credentials</DialogTitle>
			<DialogContent>
				<Box component="form" noValidate onSubmit={handleSubmit}>
					<button type={'submit'} style={{display: 'none'}}></button>

					<TextField
						required
						fullWidth
						margin="normal"
						label="Label"
						name="label"
						type="text"
						value={form.label}
						onChange={changeForm}
						error={!_.isNull(form.labelErr)}
						helperText={form.labelErr}
						InputLabelProps={{ shrink: true }}
						autoComplete="label"/>

					<TextField
						required
						fullWidth
						select
						margin="normal"
						label="Brand"
						name="brandId"
						type="text"
						error={!_.isNull(form.brandIdErr)}
						helperText={form.brandIdErr}
						InputLabelProps={{ shrink: true }}
						value={form.brandId}
						onChange={changeForm}
						autoComplete="brands">
						{
							brands.map((brand) => (
								<MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
							))
						}
					</TextField>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button fullWidth={isMobile} variant={isMobile? 'outlined' : 'text'} color={'inherit'} onClick={onClose}>Cancel</Button>
				<Button fullWidth={isMobile} variant="outlined" onClick={handleSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>

	)
}
