import axios from 'axios'
import Cookies from 'js-cookie'
import _ from 'lodash'

const request = axios.create({baseURL: `${process.env.REACT_APP_API_URL}/admin`})
const requestRevId = axios.create({baseURL: `${process.env.REACT_APP_API_URL}/revid`})

const collections = {
	ssoInstance: request,
	login: (username, password) => {
		return request
			.post('/auth', {username, password})
			.then(response => {
				request.defaults.headers.common.authorization = response.data.token
				requestRevId.defaults.headers.common.authorization = response.data.token
				Cookies.set('_t', response.data.token)
			})
	},
	getProfile: (token) => {
		if (!_.isEmpty(token)) {
			request.defaults.headers.common.authorization = token
			requestRevId.defaults.headers.common.authorization = token
		}
		return request.get('/me')
	},
	logout: () => {
		request.defaults.headers.common.authorization = undefined
		requestRevId.defaults.headers.common.authorization = undefined
		Cookies.remove('_t')
	},
	getAdmins: (withBrands = true) => {
		const params = withBrands ? {brand: ''} : {}
		return request.get('/accounts', {params})
	},
	getAdminDetail: (id) => {
		return request.get(`/accounts/${id}`)
	},
	newAdmin: (form) => {
		return request.post(`/accounts`, form)
	},
	editAdmin: (id, form) => {
		return request.put(`/accounts/${id}`, form)
	},
	deleteAdmin: (form) => {
		return request.delete(`/accounts/${form.id}`)
	},
	getAllBrands: () => {
		return request.get('/brands')
	},
	viewBrand: id => {
		return request.get(`/brands/${id}`)
	},
	addBrand: (form) => {
		return request.post(`/brands`, form)
	},
	editBrand: (id, form) => {
		return request.put(`/brands/${id}`, form)
	},
	deleteBrand: (form) => {
		return request.delete(`/brands/${form.id}`)
	},
	getNetworks: () => {
		return request.get('/networks')
	},
	getProfiles: (params = {}) => {
		return request.get(`/profiles`, { params })
	},
	getProfilesByMonth: (params = {}) => {
		return request.get(`/profilesbymonth`, { params })
	},
	getProfilebyID: (id, params = {}) => {
		return request.get(`/profile/${id}`, { params })
	},
	getProfileByRealID: (id, pid) => {
		return request.get(`/profile/${id}?isProfileId=1&profileId=${pid}`)
	},
	getProfileByIDAudit: (id, params = {}) => {
		return request.get(`/profile/${id}/audit`, { params })
	},
	getProfileUserPlanbyID: (id, params = {}) => {
		return request.get(`/profile/${id}/userplan?isProfileId=1&profileId=${id}`, { params })
	},
	deleteProfileUserPlan: (id) => {
		return request.delete(`/profile/${id}/userplan`)
	},
	addProfileSubscriptionPlan: (form) => {
		return request.post(`/profiles/subscriptions`, form)
	},
	deleteProfileSubscriptionPlan: (id) => {
		return request.delete(`/profiles/${id}/subscriptions`)
	},
	editProfileSubscriptionPlan: (id, form) => {
		return request.put(`/profiles/${id}/subscriptions`, form)
	},
	editProfile: (id, form, params = {}) => {
		return request.put(`/profile/${id}`, form, {params})
	},
	getSubscriptions: (params = {}) => {
		return request.get('/subscription-plans', {params})
	},
	addSubscription: (form) => {
		return request.post(`/subscription-plans`, form)
	},
	viewSubscription: (id) => {
		return request.get(`/subscription-plans/${id}`)
	},
	editSubscription: (id, form) => {
		return request.put(`/subscription-plans/${id}`, form)
	},
	deleteSubscription: (form) => {
		return request.delete(`/subscription-plans/${form.id}`)
	},
	getAllApplications: () => {
		return request.get(`/applications`)
	},
	addApplication: (form) => {
		return request.post(`applications`, form)
	},
	deleteApplication: (form) => {
		return request.delete(`/applications/${form.id}`)
	},
	getCoupons: (params = {}) => {
		return request.get('/coupons', {params})
	},
	addCoupon: (form) => {
		return request.post(`/coupons`, form)
	},
	editCoupon: (id, form) => {
		return request.put(`/coupons/${id}`, form)
	},
	viewCoupon: (id) => {
		return request.get(`/coupons/${id}`)
	},
	deleteCoupon: (form) => {
		return request.delete(`/coupons/${form.id}`)
	},
	checkCoupon: (params) => {
		return request.post(`/coupons/check/code`, params)
	},
	getPayments: (params = {}) => {
		return request.get(`/payments`, {params})
	},
	viewPayment: (id) => {
		return request.get(`/payments/${id}`)
	},
	deletePaymentCoupon: (id) => {
		return request.delete(`/payments/coupons/${id}`)
	},
	revalidateWithRazer: id => {
		return request.get(`/payments/${id}/revalidate`)
	},
	refundPayment: id => {
		return request.delete(`/payments/${id}`)
	},
	getCredentials: () => {
		return request.get('/credentials')
	},
	addCredential: (form) => {
		return request.post('/credentials', form)
	},
	editCredential: (form) => {
		return request.put('/credentials', form)
	},
	queryREVID: revId => {
		return requestRevId.get(`/query`, { params: { revId } })
	},
	cancelPaymentRecurring: (id) => {
		return request.put(`payments/recurring/${id}`)
	},
	getEticketEvents: (params = {}) => {
		return request.get('/eticket/events', {params})
	},
	addEticketEvents: (form) => {
		return request.post(`/eticket/events`, form)
	},
	getEticketEventbyID: (id) => {
		return request.get(`/eticket/events/${id}`)
	},
	editEticketEvents: (id, form) => {
		return request.put(`/eticket/events/${id}`, form)
	},
	getEticketOrganizers: (params = {}) => {
		return request.get('/eticket/organizers')
	},
	addEticketOrganizers: (form) => {
		return request.post(`/eticket/organizers`, form)
	},
	getEticketOrganizerbyID: (id) => {
		return request.get(`/eticket/organizers/${id}`)
	},
	editEticketOrganizer: (id, form) => {
		return request.put(`/eticket/organizers/${id}`, form)
	},
	getEticketPackages: (params = {}) => {
		return request.get('/eticket/packages')
	},
	addEticketPackage: (form) => {
		return request.post(`/eticket/packages`, form)
	},
	getEticketPackagebyID: (id) => {
		return request.get(`/eticket/packages/${id}`)
	},
	editEticketPackage: (id, form) => {
		return request.put(`/eticket/packages/${id}`, form)
	},
	addEticketEventMeta: (form) => {
		return request.post(`/eticket/events/meta`, form)
	},
	checkingTicket: (data) => {
		return request.post(`/eticket/validate/entrance`, data)
	},
	getEticketReportTotalSold: (id, params = {}) => {
		return request.get(`/eticket/reports/total-sold/${id}`, {params})
	},
	getEticketReportTotalAmountSold: (id, params = {}) => {
		return request.get(`/eticket/reports/total-amount-sold/${id}`, {params})
	},
	getEticketReportSalesByType: (id) => {
		return request.get(`/eticket/reports/sold-by-type/${id}`)
	},
	downloadFile: (params = {}) => {
		return request.get(`/payments/download-file`, {params})
	},
	getUserRegisteredByMonth: (params = {}) => {
		return request.get(`/userregistered`, {params})
	},
	getPaymentStatusByMonth: (params = {}) => {
		return request.get(`/payments/statistic-status`, {params})
	},
	getPaymentToday: (params = {}) => {
		return request.get(`/payments/today-payment`, {params})
	},
	getPaymentMethods: (params = {}) => {
		return request.get(`/subscription-payment-methods`, {params})
	},
	viewPaymentMethod: (id) => {
		return request.get(`/subscription-payment-methods/${id}`)
	},
	editPaymentmethod: (id, form) => {
		return request.put(`/subscription-payment-methods/${id}`, form)
	},
	addPaymentmethod: (form) => {
		return request.post(`/subscription-payment-methods`, form)
	},
	getPaymentGroups: (params = {}) => {
		return request.get(`/subscription-payment-groups`, {params})
	},
	addPaymentGroup: (form) => {
		return request.post(`/subscription-payment-groups`, form)
	},
	viewPaymentGroup: (id) => {
		return request.get(`/subscription-payment-groups/${id}`)
	},
	editPaymentGroup: (id, form) => {
		return request.put(`/subscription-payment-groups/${id}`, form)
	},
	getAdditionalPackages: (params = {}) => {
		return request.get(`/additional-packages`, { params })
	},
	addAdditionalPackages: (form) => {
		return request.post(`/additional-packages`, form)
	},
	viewAdditionalPackages: (id) => {
		return request.get(`/additional-packages/${id}`)
	},
	editAdditionalPackages: (id, form) => {
		return request.put(`/additional-packages/${id}`, form)
	},
	deleteAdditionalPackages: (id) => {
		return request.delete(`/additional-packages/${id}`)
	},
	getCampaigns: (params = {}) => {
		return request.get(`/campaigns`, { params })
	},
	addCampaigns: (form) => {
		return request.post(`/campaigns`, form)
	},
	viewCampaigns: (id) => {
		return request.get(`/campaigns/${id}`)
	},
	editCampaigns: (id, form) => {
		return request.put(`/campaigns/${id}`, form)
	},
	deleteCampaigns: (id) => {
		return request.delete(`/campaigns/${id}`)
	},
}

export const {
	ssoInstance,
	login,
	getProfile,
	logout,
	getAdmins,
	getAdminDetail,
	newAdmin,
	editAdmin,
	deleteAdmin,
	getAllBrands,
	getNetworks,
	addBrand,
	viewBrand,
	editBrand,
	deleteBrand,
	getProfiles,
	getProfilesByMonth,
	getProfilebyID,
	getProfileByRealID,
	getProfileByIDAudit,
	getProfileUserPlanbyID,
	deleteProfileUserPlan,
	editProfile,
	addProfileSubscriptionPlan,
	deleteProfileSubscriptionPlan,
	editProfileSubscriptionPlan,
	getSubscriptions,
	viewSubscription,
	editSubscription,
	addSubscription,
	deleteSubscription,
	getAllApplications,
	addApplication,
	deleteApplication,
	getCoupons,
	addCoupon,
	editCoupon,
	viewCoupon,
	checkCoupon,
	deleteCoupon,
	getPayments,
	viewPayment,
	deletePaymentCoupon,
	getCredentials,
	addCredential,
	editCredential,
	queryREVID,
	cancelPaymentRecurring,
	revalidateWithRazer,
	refundPayment,
	getEticketEvents,
	addEticketEvents,
	getEticketEventbyID,
	editEticketEvents,
	getEticketOrganizers,
	addEticketOrganizers,
	getEticketOrganizerbyID,
	editEticketOrganizer,
	getEticketPackages,
	addEticketPackage,
	getEticketPackagebyID,
	editEticketPackage,
	addEticketEventMeta,
	checkingTicket,
	getEticketReportTotalSold,
	getEticketReportTotalAmountSold,
	getEticketReportSalesByType,
	downloadFile,
	getUserRegisteredByMonth,
	getPaymentStatusByMonth,
	getPaymentToday,
	getPaymentMethods,
	viewPaymentMethod,
	editPaymentmethod,
	addPaymentmethod,
	getPaymentGroups,
	addPaymentGroup,
	viewPaymentGroup,
	editPaymentGroup,
	getAdditionalPackages,
	addAdditionalPackages,
	viewAdditionalPackages,
	editAdditionalPackages,
	deleteAdditionalPackages,
	getCampaigns,
	addCampaigns,
	viewCampaigns,
	editCampaigns,
	deleteCampaigns
} = collections
