import React, {useState} from 'react'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import DialogConfirmRemoveRecurring from './dialog-confirm-remove-recurring'
import { ssoInstance } from '../../../lib/request'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    
    .tblHead th {
			font-weight: bolder;
		}
  `}
`

export default function Tab5(props) {

	const {profile} = props
	const myProfile = profile.Profile[0]

	const {brandInFocus} = useSelector(state => state.profile)

	const navigate = useNavigate()
	const theme = useTheme()
	const dispatch = useDispatch()

	const [confirmRecurring, setConfirmRecurring] = useState(false)

	function removeRecurring () {

		dispatch(setLoading(true))
		ssoInstance.delete(`/profile/${myProfile.id}/recurring`, {params: {brandId: brandInFocus}})
			.then(() => {
				dispatch(setSnackbarMsg('Recurring information is removed'))
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => {
				dispatch(setLoading(false))
			})

	}

	let haveRecurring = false
	_.forEach(myProfile.Payments, p => {
		if (p.Meta && !_.isEmpty(p.Meta.token) && p.Meta.channel !== 'In App Purchase') {
			haveRecurring = true
		}
	})

	return (
		<StyledDiv>
			{
				_.isEmpty(myProfile.Payments) ? (
					<Alert severity={'info'} sx={{ml: 2, mr: 2}}>No payments records found for this user</Alert>
				) : (
					<Box>
						<Box sx={{display: 'flex', justifyContent: 'flex-end', pr: 2}}>
							{
								haveRecurring && (
									<Button variant={'outlined'} color={'error'} onClick={() => setConfirmRecurring(true)}>
										Remove recurring
									</Button>
								)
							}
							{
								confirmRecurring && (
									<DialogConfirmRemoveRecurring onClose={() => setConfirmRecurring(false)} onConfirm={removeRecurring} />
								)
							}
						</Box>
						<TableContainer>
							<Table>
								<TableHead className="tblHead">
									<TableRow>
										<TableCell align="left" width={10}>#</TableCell>
										<TableCell align="left" width={80}>Order ID</TableCell>
										<TableCell align="left" width={80}>Txn ID</TableCell>
										<TableCell align="center" width={70}>Amount</TableCell>
										<TableCell align="center" width={100}>Channel</TableCell>
										<TableCell align="center" width={100}>Coupon</TableCell>
										<TableCell align="center">Plan</TableCell>
										<TableCell align={'right'} width={200}>Pay Date</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										_.orderBy(myProfile.Payments, ['id'], ['desc']).map((row, index) => {
											let backgroundColor = ''
											let paydate = ''
											if (row.status === '00') {
												backgroundColor = theme.palette.success.light
												if (!_.isNull(row.payDate)) {
													paydate = dayjs(row.payDate).format('DD/MM/YYYY h:mm A')
												}
												else {
													paydate = !_.isNull(row.Coupon) ? dayjs(row.Coupon.createdAt).format('DD/MM/YYYY h:mm A') : '-'
												}
											} else if (row.status === '11') {
												backgroundColor = theme.palette.error.light
											} else if (row.status === '22') {
												backgroundColor = theme.palette.warning.light
											} else if (_.isNull(row.status) && row.amount === 0 && !_.isEmpty(row.Coupon)) {
												backgroundColor = theme.palette.info.light
												paydate = dayjs(row.Coupon.createdAt).format('DD/MM/YYYY h:mm A')
											}

											return (
												<TableRow
													hover
													sx={{backgroundColor}}
													className={'tablerow'}
													onClick={() => navigate(`/payment/${row.id}`)}
													key={index}>
													<TableCell width={10}>
														{index + 1}
													</TableCell>
													<TableCell width={80}>
														{row.orderId}
													</TableCell>
													<TableCell width={80}>
														{row.transactionId}
													</TableCell>
													<TableCell width={70} align={'center'}>
														RM {row.amount.toFixed(2)}
													</TableCell>
													<TableCell width={100} align={'center'}>
														{row.Meta?.channel || ''}
													</TableCell>
													<TableCell width={100} align={'center'}>
														{row.Coupon?.Couponcode?.code || ''}
													</TableCell>
													<TableCell align={'center'}>
														{row.Plan.name}
													</TableCell>
													<TableCell align={'right'} width={200}>
														{paydate}
													</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)
			}
		</StyledDiv>
	)
}
