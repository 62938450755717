import {styled, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {getEticketReportTotalAmountSold, getEticketReportTotalSold, getEticketReportSalesByType} from "../../../lib/request";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PaidIcon from '@mui/icons-material/Paid';
import _ from "lodash";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import dayjs from "dayjs";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

export default function Tab6(props) {
    const { event } = props
    const theme = useTheme()
    const [totalSold, setTotalSold] = useState(0)
    const [soldToday, setSoldToday] = useState({})
    const [totalAmountSold, setTotalAmountSold] = useState(0)
    const [totalAmountSoldToday, setTotalAmountSoldToday] = useState(0)
    const [salesByType, setSalesByType] = useState([])

    useEffect(() => {
        if (!isNaN(event.id)) {
            getEticketReportTotalSold(event.id)
                .then((res) => {
                    setTotalSold(res.data)

                    const groupedByPayDate = res.data.rows.reduce((acc, row) => {
                        const payDate = dayjs(row.Eticket.EticketPayments.payDate).format('YYYY-MM-DD')
                        if (!acc[payDate]) {
                            acc[payDate] = []
                        }
                        acc[payDate].push(row)

                        return acc;
                    }, {})

                    setSoldToday(groupedByPayDate)
                })

            getEticketReportTotalAmountSold(event.id)
                .then((res) => {
                    const { sum, sumToday } = res.data.reduce((acc, item) => {
                        const totalAmount = parseFloat(item.totalAmount)
                        acc.sum += totalAmount
                        if (item.payDate === dayjs().format('YYYY-MM-DD')) {
                            acc.sumToday += totalAmount
                        }
                        return acc;
                    }, { sum: 0, sumToday: 0 })

                    setTotalAmountSold(sum)
                    setTotalAmountSoldToday(sumToday)
                })

            getEticketReportSalesByType(event.id)
                .then((res) => {
                    setSalesByType(res.data)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])


    return (
        <Box sx={{m: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{mb: 2}}>
                    <Card sx={{borderTop: '4px solid #f15b2d'}} variant="outlined">
                        <CardContent>
                            <Typography component="h1" variant="h6">
                                Total Ticket Sold
                            </Typography>
                        </CardContent>
                        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ConfirmationNumberIcon style={{fontSize: '1.2rem', marginRight: '1rem'}}/>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <Typography style={{fontSize: '1.5rem', color: '#43a047'}}>{totalSold.count}</Typography>
                                    <Typography style={{fontSize: '1rem',  marginLeft: '0.5rem'}}>(Total)</Typography>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ConfirmationNumberIcon style={{fontSize: '1.2rem', marginRight: '1rem'}}/>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <Typography
                                        style={{fontSize: '1.5rem', color: '#43a047'}}> {_.isEmpty(soldToday[dayjs().format('YYYY-MM-DD')]) ? 0 : soldToday[dayjs().format('YYYY-MM-DD')].length}</Typography>
                                    <Typography style={{fontSize: '1rem', marginLeft: '0.5rem'}}>(Today)</Typography>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{mb: 2}}>
                    <Card sx={{borderTop: '4px solid #f15b2d'}} variant="outlined">
                        <CardContent>
                            <Typography component="h1" variant="h6">
                                Ticket Sales
                            </Typography>
                        </CardContent>
                        <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ConfirmationNumberIcon style={{fontSize: '1.2rem', marginRight: '1rem'}}/>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <Typography style={{fontSize: '1.5rem', color: '#43a047'}}>RM{totalAmountSold.toFixed(2)}</Typography>
                                    <Typography style={{fontSize: '1rem', marginLeft: '0.5rem'}}>(Total)</Typography>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ConfirmationNumberIcon style={{fontSize: '1.2rem', marginRight: '1rem'}}/>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <Typography style={{fontSize: '1.5rem', color: '#43a047'}}>RM{totalAmountSoldToday.toFixed(2)}</Typography>
                                    <Typography style={{fontSize: '1rem', marginLeft: '0.5rem'}}>(Today)</Typography>
                                </div>
                            </div>
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xs={12} md={8} sx={{mb: 2}}>
                    <Card sx={{borderTop: '4px solid #f15b2d'}} variant="outlined">
                        <CardContent>
                            <Typography component="h1" variant="h6">
                                Sales by Ticket Type
                            </Typography>
                            <Typography component="h3" variant="body2">
                                (Sale price is exclusive of SST and processing fee)
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="sales table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Capacity</TableCell>
                                            <TableCell>Sale</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            salesByType.map((sale, index) => {
                                                return (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {sale.packageTitle}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {sale.count}/{sale.capacity}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ display: 'flex', alignItems: 'center', color:'#43a047'}}>
                                                            <PaidIcon style={{ marginRight: '0.5rem' }} />
                                                            <span>RM{sale.totalAmount}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
