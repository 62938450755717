import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState: {
        paymentMethods: [],
        dropdown: []
    },
    reducers: {
        updatePaymentMethodDropdown: (state, action) => {
            state.dropdown = [];
            action.payload.forEach((pm) => {
                state.dropdown.push(_.pick(pm, ['id', 'channel_name']));
            });
        }
    },
});

export const {
    setPaymentMethods,
    updatePaymentMethodDropdown,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
