import _ from 'lodash'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {useSelector} from "react-redux";

const StyledDiv = styled(Box)`
`

export default function Search(props) {
    const {search, setSearch, beginSearch} = props
    const {me} = useSelector(state => state.profile)

    function changeForm (e) {
        const {name, value} = e.target
        const searchClone = _.clone(search)
        searchClone[name] = value
        setSearch(searchClone)
    }

    return (
        <StyledDiv>
            <form onSubmit={beginSearch}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={7} md={7}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            size={'small'}
                            placeholder={'Search Channel Name'}
                            name={'text'}
                            value={search.text}
                            onChange={changeForm}
                        />
                    </Grid>
                    <Grid xs={12} sm={5} md={5}>
                        <Button
                            type={'submit'}
                            onClick={beginSearch}
                            fullWidth
                            variant={'outlined'}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </StyledDiv>
    )
}
