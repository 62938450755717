import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import cookies from 'js-cookie'

// Dashboard sso revid brand
const brandIdFocusInCookies = cookies.get('dssorevid-b')

export const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		me: {},
		profiles: [],
		page: 0,
		rowsPerPage: 10,
		selectedBrand: null,
		brandInFocus: 0
	},
	reducers: {
		initialize: (state, action) => {
			state.me = action.payload
			if (_.isUndefined(brandIdFocusInCookies)) {
				state.brandInFocus = action.payload.Brands[0].id
			} else {
				state.brandInFocus = parseInt(brandIdFocusInCookies)
			}
		},
		update: (state, action) => {
			state.me[action.payload.key] = action.payload.value
		},
		clear: (state) => {
			state.me = {}
		},
		setProfiles: (state, action) => {
			state.profiles = _.isArray(action.payload) ? _.orderBy(action.payload, ['id'], ['asc']) : []
		},
		setProfilePage: (state, action) => {
			state.page = action.payload
		},
		setProfileRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload
		},
		setBrandId: (state, action) => {
			state.selectedBrand = action.payload
		},
		setBrandInFocus: (state, action) => {
			state.brandInFocus = action.payload
			cookies.set('dssorevid-b', action.payload)
		}
	}
})

// Action creators are generated for each case reducer function
export const {
	initialize,
	update,
	clear,
	setProfiles,
	setProfilePage,
	setProfileRowsPerPage,
	setBrandId,
    setBrandInFocus
} = profileSlice.actions

export default profileSlice.reducer